import React from 'react';
import { Flex, Box } from 'reflexbox';
import { FormLabel, Tooltip, PublicIcon, PrivateIcon, SwitchButton, QuestionCircleIcon } from 'boss-ui';

export default function VisibilityToggle(props) {
  const { fieldValue, handleFieldChange } = props;
  return (
    <Box style={{ position: 'relative' }}>
      <FormLabel>Team Visibility</FormLabel>
      <SwitchButton
        checked={fieldValue}
        handleToggle={handleFieldChange}
        id="visibility"
        onIcon={<PublicIcon />}
        offIcon={<PrivateIcon />}
        onText="Public"
        offText="Private"
      />
      <Flex
        data-tip
        data-for="visibility_question"
        style={{
          position: 'absolute',
          right: '-30px',
          top: '26px',
        }}
      >
        <QuestionCircleIcon />
      </Flex>
      <Tooltip id="visibility_question" place="right">
        Public: the team members' names appear to others during the team formation process.
      </Tooltip>
    </Box>
  );
}
