import React from 'react';
import { useHistory } from 'react-router';
import { useAppContext } from '../../libs/context-lib';
import { Menu } from 'boss-ui';
import { filterMenuByRole } from '../../libs/user-lib';

export default function Home() {
  const { state } = useAppContext();
  const history = useHistory();
  history.push('/events');

  return (
    <>
      <Menu menuItems={filterMenuByRole(state.user)} />
    </>
  );
}
