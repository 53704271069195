import React, { useEffect, useState } from 'react';
import { Flex } from 'reflexbox';
import { intervalToDuration } from 'date-fns';
import { useRouteMatch } from 'react-router-dom';
import truncate from 'lodash/truncate';
import Api from '../../api';
import { useDynamoPaginator } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';
import { isTodayAfterThan } from '../../libs/dates-lib';
import {
  ConfirmationModal,
  ContextMenu,
  ContextMenuItem,
  CopyClipboardIcon,
  CopyToClipboard,
  Paginator,
  RefreshIcon,
  RegularText,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableItemSkeleton,
  TableRow,
  Tooltip,
  TrashCanIcon,
  UnregularButton,
} from 'boss-ui';
import CreateLinkInvite from './CreateLinkInvite';
import HovereableFlex from 'boss-ui/src/components/common/HovereableFlex';
import { DEFAULT_PAGE_STEP, NUMBERS } from '../../libs/constants';

const DEFAULT_PAGE_SIZE = 7;
const API = new Api();

const padZeroStart = (number, nroDigits = 2) => `0${number}`.slice(-nroDigits);

const getExpirationInTime = (date) => {
  const expiration = intervalToDuration({ start: new Date(), end: new Date(date) });
  return (
    <>
      {isTodayAfterThan(date) ? (
        <>Expired</>
      ) : (
        <>
          {padZeroStart(expiration.hours)}:{padZeroStart(expiration.minutes)}:
          {padZeroStart(expiration.seconds)}
        </>
      )}
    </>
  );
};

let interval;
const TableDataInterval = ({ date, intervalRefresh = 1000 }) => {
  const [intervalTime, setIntervalTime] = useState(null);

  useEffect(() => {
    interval = setInterval(() => {
      setIntervalTime(getExpirationInTime(date));
    }, intervalRefresh);
    return () => clearInterval(interval);
  }, []);

  return <TableData>{intervalTime}</TableData>;
};

const makeUri = (link, inviteCodeId) => {
  return `${truncate(link, { length: 20, omission: '...' })}${truncate(inviteCodeId, {
    length: 6,
    omission: '',
  })}`;
};

export default function InvitesLinkList() {
  const [invitesList, setInvitesList] = useState([]);
  const [createInviteVisible, setCreateInviteVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [creatingInvite, setCreatingInvite] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [currentInviteCodeId, setCurrentInviteCodeId] = useState('');
  const [rowHoverId, setRowHoverId] = useState(0);
  const [{ pages, currentPage }, paginate] = useDynamoPaginator();
  const route = useRouteMatch('/events/:eventId');
  const eventId = route ? route.params.eventId : '';

  const getInvites = async (page = 0) => {
    setLoading(true);
    let lastEvaluatedKey;
    if (page > NUMBERS.ZERO) {
      lastEvaluatedKey =
        page > currentPage
          ? pages[currentPage].nextPagePointer
          : pages[currentPage].lastPagePointer;
    }
    try {
      const rq = await API.get('events', `/admin/events/${eventId}/invite-code`, {
        queryStringParameters: {
          pageSize: DEFAULT_PAGE_SIZE,
          ...(lastEvaluatedKey && {
            ...lastEvaluatedKey,
          }),
        },
      });
      setInvitesList(rq.inviteCodes);
      paginate(rq.pagination, page);
      setLoading(false);
    } catch (e) {
      onError(e);
    }
  };

  const createInvite = async (invitation) => {
    setCreatingInvite(true);
    try {
      let expiresIn = '-1';
      if (invitation.expiresIn && invitation.expiresIn.value !== null) {
        const expireDate = new Date().setHours(new Date().getHours() + invitation.expiresIn.value);
        expiresIn = new Date(expireDate).toISOString();
      }
      const payload = {
        campaignName: invitation.campaignName,
        maxUsers: invitation.maxUsers === null ? -1 : Number(invitation.maxUsers),
        expiresIn,
      };
      await API.post('events', `/admin/events/${eventId}/invite-code`, {
        body: payload,
      });
      getInvites();
    } catch (e) {
      onError(e);
    } finally {
      setCreatingInvite(false);
      setCreateInviteVisible(false);
    }
  };

  const deleteInvite = async () => {
    setConfirmationModalVisible(false);
    setLoading(true);
    try {
      await API.del('events', `/admin/events/${eventId}/invite-code/${currentInviteCodeId}`);
      await getInvites();
      setCurrentInviteCodeId('');
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvites();
    return () => API.abortCurrentRequest();
  }, []);

  return (
    <>
      <Flex
        px="40px"
        py="30px"
        width="924px"
        height="700px"
        flexDirection="column"
        style={{ backgroundColor: '#1d1d1d' }}
      >
        <HovereableFlex mb="12px" data-tip data-for="refresh" onClick={() => getInvites()}>
          <RefreshIcon rotate={loading} color="#898989" hoverColor="#d0d0d0" />
          <Tooltip id="refresh">Refresh list</Tooltip>
        </HovereableFlex>
        <Flex flexDirection="column" justifyContent="space-between" height="100%">
          <Table>
            <TableHead>
              <TableRow height="41px">
                <TableHeader>Name</TableHeader>
                <TableHeader>Link</TableHeader>
                <TableHeader justify="center">Joined</TableHeader>
                <TableHeader>Expires in</TableHeader>
                <TableHeader noDataPadding justify="flex-end">
                  <UnregularButton active onClick={() => setCreateInviteVisible(true)}>
                    +
                  </UnregularButton>
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableItemSkeleton numberOfCells={5} />
              ) : invitesList.length > 0 ? (
                invitesList.map((i, idx) => {
                  return (
                    <TableRow 
                      key={i.inviteCodeId} 
                      height="41px"
                      onMouseOver={() => setRowHoverId(i.inviteCodeId)}
                      borderTransparent
                      mouseOver={rowHoverId === i.inviteCodeId}
                    >
                      <TableData>{i.campaignName}</TableData>
                      <TableData width="280px">
                        <Flex alignItems="center" width={1} justifyContent="space-between">
                          {makeUri(i.link, i.inviteCodeId)}
                          {(!isTodayAfterThan(i.expiresIn) || i.expiresIn === '-1') && (
                            <ContextMenu
                              width="20px"
                              mobile
                              button={<CopyClipboardIcon size="18px" />}
                            >
                              <ContextMenuItem idx={idx}>
                                <Flex justifyContent="space-between" width={1}>
                                  URL
                                  <CopyToClipboard value={i.link} />
                                </Flex>
                              </ContextMenuItem>
                              <ContextMenuItem>
                                <Flex justifyContent="space-between" width={1}>
                                  Code
                                  <CopyToClipboard value={i.inviteCodeId} />
                                </Flex>
                              </ContextMenuItem>
                            </ContextMenu>
                          )}
                        </Flex>
                      </TableData>
                      <TableData center>
                        {i.maxUsers === -1 ? 'no limit' : `${i.acceptedUsers} / ${i.maxUsers}`}
                      </TableData>
                      {i.expiresIn === '-1' ? (
                        <TableData>no limit</TableData>
                      ) : (
                        <TableDataInterval date={i.expiresIn} intervalRefresh={1000} />
                      )}
                      <TableData right style={{ paddingRight: '10px' }}>
                        <HovereableFlex
                          onClick={() => {
                            setCurrentInviteCodeId(i.inviteCodeId);
                            setConfirmationModalVisible(true);
                          }}
                          justifyContent="flex-end"
                        >
                          <TrashCanIcon />
                        </HovereableFlex>
                      </TableData>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableData colspan={5}>No invites found</TableData>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Flex width={1} justifyContent="center">
            <Paginator
              isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
              onNext={() => getInvites(currentPage + DEFAULT_PAGE_STEP)}
              isPrev={currentPage > 0}
              onPrev={() => getInvites(currentPage - DEFAULT_PAGE_STEP)}
              paginating={loading}
            />
          </Flex>
        </Flex>
      </Flex>
      {confirmationModalVisible && (
        <ConfirmationModal
          dangerDialog
          onCancel={() => setConfirmationModalVisible(false)}
          onAccept={deleteInvite}
          acceptLabel="DELETE"
        >
          <RegularText>Are you sure you want to delete this invite?</RegularText>
        </ConfirmationModal>
      )}
      {createInviteVisible && (
        <CreateLinkInvite
          onCancel={() => setCreateInviteVisible(false)}
          onAccept={(i) => createInvite(i)}
          loading={creatingInvite}
        />
      )}
    </>
  );
}
