import { useContext, createContext } from 'react';

export const AppContext = createContext(null);
export const ScenarioContext = createContext(null);
export const EventContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

export function useScenarioContext() {
  return useContext(ScenarioContext);
}

export function useEventContext() {
  return useContext(EventContext);
}