import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';
import debounce from 'lodash.debounce';
import Api from '../../api';
import { Flex, Box } from 'reflexbox';
import {
  Form,
  FormLeyend,
  TableBody,
  TableHeader,
  Table,
  TableHead,
  TableRow,
  Paginator,
  TableData,
  LinkedText,
  RegularText,
  TableItemSkeleton,
  TeamIcon,
  CloseIcon,
  OpenIcon,
  Tooltip,
  QuestionCircleIcon,
  TrashCanIcon,
  ConfirmationModal,
  RefreshIcon,
  HovereableFlex,
} from 'boss-ui';
import FormInput from '../FormInput';
import TeamMembersVisibility from './TeamMembersVisibility';
import { useDynamoPaginator } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';
import { useEventContext } from '../../libs/context-lib';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_STEP, NUMBERS, TEAM_ACCESSIBILITY } from '../../libs/constants';
import { defineTeamMembers } from '../../libs/team-lib';
import { EVENT_ACTION } from '../../libs/reducerAction-lib';

const API = new Api();

const getAccessibility = (team) => {
  switch (team.accessibility) {
    case TEAM_ACCESSIBILITY.CLOSED: {
      return <CloseIcon inverted />;
    }
    case TEAM_ACCESSIBILITY.OPEN: {
      return <OpenIcon inverted highlight />;
    }
    default:
      return null;
  }
};

const TeamAccessibility = (props) => {
  return <Flex alignItems="center">{getAccessibility(props.team)}</Flex>;
};

export default function SearchTeams() {
  const userRouteMatch = useRouteMatch('/events/:eventId/teams');
  const [{ pages, currentPage }, paginate] = useDynamoPaginator();
  const { state, dispatch } = useEventContext();
  const iconColor = useTheme().color.searchTable.logo.color;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [rowHoverId, setRowHoverId] = useState(0);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [confirmationDisbandText, setconfirmationDisbandText] = useState(null);

  const searchTeam = async (q = '', page = NUMBERS.ZERO) => {
    setLoading(true);
    let lastEvaluatedKey;
    if (page > NUMBERS.ZERO) {
      lastEvaluatedKey =
        page > currentPage
          ? pages[currentPage].nextPagePointer
          : pages[currentPage].lastPagePointer;
    }
    try {
      const result = await API.get('teams', `/events/${userRouteMatch.params.eventId}/teams`, {
        queryStringParameters: {
          pageSize: DEFAULT_PAGE_SIZE,
          q,
          ...(lastEvaluatedKey && {
            ...lastEvaluatedKey,
          }),
        },
      });
      setSearchList(
        result.teams.map((t) => defineTeamMembers(t, state.event.maxNumberOfParticipants))
      );
      paginate(result.pagination, page);
      setLoading(false);
    } catch (e) {
      onError(e);
    }
  };

  const cleanBox = () => {
    setSearchString('');
    searchTeam();
  };

  const delayedQuery = useCallback(
    debounce((q) => searchTeam(q), 500),
    []
  );

  useEffect(() => {
    cleanBox();
    return () => API.abortCurrentRequest();
  }, []);

  const onItemClicked = (item) => {
    dispatch({ type: EVENT_ACTION.SET_TEAM, data: item });
    history.push(`/events/${userRouteMatch.params.eventId}/teams/${item.teamId}`);
  };

  const onClickDisband = (item) => {
    setconfirmationDisbandText(`Are you sure on disbanding team ${item.name}?`);
    setSelectedTeam(item);
  };

  const onDisbandTeam = async () => {
    setconfirmationDisbandText(null);
    setLoading(true);
    try {
      await API.del('teams', `/admin/teams/${selectedTeam.teamId}`);
      cleanBox();
      setSelectedTeam({});
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form noBorder>
      <Flex px="40px" py="30px" width="924px" height="700px" flexDirection="column">
        <Flex justifyContent="space-between">
          <Box mb="10px" width={2 / 3}>
            <FormLeyend>Find a Team</FormLeyend>
            <FormInput
              search={searchString}
              remove={searchString.length > 0 && cleanBox}
              onChange={(e) => {
                setSearchString(e.target.value);
                delayedQuery(e.target.value);
              }}
              value={searchString}
              placeholder="Search by Team Name"
            />
          </Box>
          <TeamIcon size="52px" fillColor={iconColor} />
        </Flex>
        <HovereableFlex data-tip data-for="refresh" width="20px" ml="10px" mb="12px">
          <RefreshIcon onClick={cleanBox} rotate={loading} color="#898989" hoverColor="#d0d0d0" />
          <Tooltip id="refresh">Refresh list</Tooltip>          
        </HovereableFlex>
        <Flex flexDirection="column" justifyContent="space-between" height="100%">
          <Table onMouseOut={() => setRowHoverId(0)}>
            <TableHead>
              <TableRow>
                <TableHeader style={{ paddingLeft: '10px' }}>Team</TableHeader>
                <TableHeader>Members</TableHeader>
                <TableHeader justify="center">
                  <Flex>
                    <Flex alignItems="center">
                      Visibility
                    </Flex>
                    <QuestionCircleIcon data-tip data-for="visibility-info" />
                    <Tooltip id="visibility-info" place="right">
                      Public: the team members' names appear to others during the team formation
                      process.
                    </Tooltip>                      
                  </Flex>
                </TableHeader>
                <TableHeader justify="center">
                  <Flex>
                    <Flex alignItems="center">
                      Access
                    </Flex>
                    <QuestionCircleIcon data-tip data-for="access-info" />
                    <Tooltip id="access-info" place="right">
                      Open: Allow others to join the team without approval.
                      <br />
                      Closed: They must invite others to join their team.
                    </Tooltip>
                  </Flex>
                </TableHeader>
                <TableHeader />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableItemSkeleton numberOfCells={5} />
              ) : (
                searchList.map((item) => (
                  <TableRow
                    height="41px" 
                    key={item.teamId}
                    onMouseOver={() => setRowHoverId(item.teamId)}
                    borderTransparent
                    mouseOver={rowHoverId === item.teamId}
                  >
                    <TableData style={{ paddingLeft: '10px' }}>
                      <LinkedText onClick={() => onItemClicked(item)}>{item.name}</LinkedText>
                    </TableData>
                    <TableData>
                      <RegularText fontSize="14px">
                        {item.numberOfParticipants}
                        {item.full && <Flex ml="5px">(Full)</Flex>}
                      </RegularText>
                    </TableData>
                    <TableData>
                      <Flex width="100%" justifyContent="center">
                        <TeamMembersVisibility team={item} />
                      </Flex>
                    </TableData>
                    <TableData>
                      <Flex width="100%" justifyContent="center">
                        <TeamAccessibility team={item} />
                      </Flex>                                                
                    </TableData>
                    <TableData right style={{ paddingRight: '10px' }}>
                      <HovereableFlex
                        justifyContent="flex-end"                         
                        onClick={() => {
                          onClickDisband(item);
                        }}                      
                      >
                        <TrashCanIcon />
                      </HovereableFlex>
                    </TableData>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Flex width={1} justifyContent="center">
            <Paginator
              isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
              onNext={() => searchTeam(searchString, currentPage + DEFAULT_PAGE_STEP)}
              isPrev={currentPage > 0}
              onPrev={() => searchTeam(searchString, currentPage - DEFAULT_PAGE_STEP)}
              paginating={loading}
            />
          </Flex>
        </Flex>        
      </Flex>
      {confirmationDisbandText && (
        <ConfirmationModal
          onAccept={(e) => {
            onDisbandTeam();
          }}
          onCancel={() => setconfirmationDisbandText(null)}
          acceptLabel="DISBAND TEAM"
          dangerDialog
        >
          <RegularText>{confirmationDisbandText}</RegularText>
        </ConfirmationModal>
      )}      
    </Form>
  );
}
