import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Flex, Box } from 'reflexbox';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import {
  StyledText,
  RegularText,
  Tooltip as IconTooltip,
  Checkbox,
  TeamIcon,
  RefreshIcon,
  AddIcon, 
  MinusIcon,
} from 'boss-ui';
import format from 'date-fns/format';
import first from 'lodash/first';
import get from 'lodash.get';
import { isEmpty } from 'lodash';
import Api from '../../api';
import { fadeIn } from '../../libs/animations-lib';
import { onError } from '../../libs/error-lib';
import { useRefreshScore } from '../../libs/hooks-lib';
import { useAppContext } from '../../libs/context-lib';

const API = new Api();

const getTeamColors = (teams, eventId) => {
  const COLORS = [0, 0, 0, 0, 0, 'white'];
  if (teams.length > 0) {
    const defaultColors = ['#3A803D', '#E2754D', '#6A96BA', '#FFCC66', '#8E4C9A', 'white'];
    const keyStorage = `${eventId}teamColorsTimeLine`;
    const newTeamColors = {};
    let teamColors = localStorage.getItem(keyStorage);
    if (teamColors) {
      teamColors = JSON.parse(teamColors);
    } else {
      teamColors = {};
    }
    for (let step = 0; step <= 1; step++) {
      for (let i = 0; i < teams.length; i++) {
        const tmpTeamId = teams[i].team.teamId;

        // first step we use the storage team colors
        if (!step && teamColors[tmpTeamId]) {
          COLORS[i] = teamColors[tmpTeamId];
        }
        // second we assign the unused colors for new teams.
        if (step && !teamColors[tmpTeamId]) {
          COLORS[i] = defaultColors[0]; // first available
        }
        if (COLORS[i] && defaultColors.indexOf(COLORS[i]) >= 0) {
          newTeamColors[tmpTeamId] = COLORS[i];
          defaultColors.splice(defaultColors.indexOf(COLORS[i]), 1); // delete the used one from the stack.
        }
      }
    }
    if (JSON.stringify(newTeamColors) !== JSON.stringify(teamColors)) {
      localStorage.setItem(keyStorage, JSON.stringify(newTeamColors));
    }
  }
  return COLORS;
};

let COLORS = [];
const TOOLTIP_TOP_MARGIN = 220;
const TOOLTIP_LEFT_MARGIN = 70;

const Container = styled(Flex)`
  background: ${(props) => props.theme.color.form.background} 0% 0% no-repeat padding-box;
  width: 100%;
  padding: ${(props) => props.theme.margin.m};
  flex-direction: column;
`;
Container.displayName = 'TimelineBoard-Container';

const GraphTooltipBackground = styled(Box)`
  background: #121212;
  box-shadow: 0px 3px 3px #00000029;
  opacity: 0.9;
  animation: ${fadeIn} 0.3s ease;
  -moz-animation: ${fadeIn} 0.3s ease;
  -webkit-animation: ${fadeIn} 0.3s ease;
`;
GraphTooltipBackground.displayName = 'TimelineBoard-GraphTooltipBackground';

const CustomizedXAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        dx={20}
        textAnchor="end"
        fontFamily="Roboto Mono"
        fontSize="12px"
        fill="#6C6C6C"
      >
        {format(new Date(payload.value), 'HH:mm')}
      </text>
    </g>
  );
};

const CustomizedYAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={5}
        dx={-5}
        textAnchor="end"
        fontFamily="Roboto Mono"
        fontSize="12px"
        fill="#6C6C6C"
      >
        {payload.value}
      </text>
    </g>
  );
};

const MultiLineTooltip = (props) => (
  <CustomTooltip
    data={{ time: props.time, name: props.name, value: props[props.name] }}
    style={{
      position: 'absolute',
      top: `${props.position.y + TOOLTIP_TOP_MARGIN}px`,
      left: `${props.position.x + TOOLTIP_LEFT_MARGIN}px`,
    }}
  />
);

const SingleLineTooltip = ({ payload = [], active = false }) => {
  const data = first(payload);
  if (active && data) {
    return (
      <CustomTooltip
        data={{ time: data.payload.time, name: data.payload.name, value: data.value }}
      />
    );
  }
  return null;
};

const CustomTooltip = ({ data, style = {} }) => (
  <GraphTooltipBackground px="16px" py="4px" style={style}>
    <RegularText light mediumWeight>{`Time: ${format(
      new Date(data.time),
      'dd MMM - HH:mm:ss'
    )}`}</RegularText>
    <RegularText light mediumWeight>{`Team: ${data.name}`}</RegularText>
    <RegularText light mediumWeight>{`Points: ${data.value}`}</RegularText>
  </GraphTooltipBackground>
);

const GraphLegend = ({
  teams = [],
  onTeamHoverEnter,
  onTeamHoverLeave,
  onTeamClick,
  onAddMyTeam,
  onRemoveMyTeam,
  isMyTeamInTheGraph,
  loading,
}) => {
  const [teamHover, setTeamHover] = useState(null);
  const [teamActive, setTeamActive] = useState(null);

  const onMouseEnter = (team) => {
    if (teamActive) {
      return;
    }
    setTeamHover(team.slug);
    onTeamHoverEnter(team.slug);
  };

  const onMouseLeave = () => {
    setTeamHover(null);
    onTeamHoverLeave();
  };

  const onMouseClick = (team) => {
    if (loading) {
      return;
    }
    if (teamActive === team.slug) {
      setTeamActive(null);
      onTeamClick(null);
      return;
    }
    setTeamActive(team.slug);
    onTeamClick(team.slug);
  };

  const onMyTeamClick = () => {
    if (loading) {
      return;
    }
    if (isMyTeamInTheGraph) {
      onRemoveMyTeam();
      return;
    }
    onAddMyTeam();
  };

  return (
    <>
      <Flex width="80%" justifyContent="center" opacity={loading ? 0.6 : 1}>
        {teams.map((team, idx) => {
          const teamColor = COLORS[idx];
          return (
            <Flex
              key={team.team.slug}
              width={1 / 5}
              style={{
                cursor: loading ? 'not-allowed' : 'pointer',
                border: '#373737 0.5px solid',
                backgroundColor:
                  teamHover === team.team.slug || teamActive === team.team.slug
                    ? '#373737'
                    : 'transparent',
              }}
              p="8px"
              alignItems="center"
              onMouseEnter={() => onMouseEnter(team.team)}
              onMouseLeave={onMouseLeave}
              onClick={() => onMouseClick(team.team)}
            >
              <Box width="30px" mr="4px" minWidth="unset">
                <TeamIcon size={30} color={teamColor} />
              </Box>
              <RegularText
                color={teamColor}
                fontSize="12px"
                mediumWeight
                width="fit-content"
                style={{ textOverflow: 'ellipsis', display: 'block', overflow: 'hidden' }}
              >
                {team.team.name}
              </RegularText>
            </Flex>
          );
        })}
        <Flex
          style={{
            cursor: loading ? 'not-allowed' : 'pointer',
          }}
          py="4px"
          pl="4px"
          alignItems="center"
          width="max-content"
          onClick={onMyTeamClick}
          data-tip
          data-for="add-remove"
        >
          {isMyTeamInTheGraph ? (
            <MinusIcon size="28px" hover={teamHover === 'my-team'} />
          ) : (
            <AddIcon size="24px" hover={teamHover === 'my-team'} />
          )}
        </Flex>
      </Flex>
      <IconTooltip id="add-remove">
        {isMyTeamInTheGraph ? 'Remove my team from the graph' : 'Add my team to the graph'}
      </IconTooltip>
    </>
  );
};

const getLineOpacity = (hoverSlug, teamSlug) => {
  if (!hoverSlug) {
    return 1;
  }
  if (hoverSlug === teamSlug) {
    return 1;
  }
  return 0.2;
};

const getDotFillColor = (teamHover, teamSlug, teamColor) => {
  if (!teamHover) {
    return teamColor;
  }
  if (teamHover === teamSlug) {
    return teamColor;
  }
  return 'transparent';
};

export default function TimelineBoard({ eventId }) {
  const [loadingScore, setLoadingScore] = useState(true);
  const [loadingTeamsScore, setLoadingTeamsScore] = useState(true);
  const [boardTeams, setBoardTeams] = useState([]);
  const [boardData, setBoardData] = useState([]);
  const [teamHover, setTeamHover] = useState(null);
  const [teamClicked, setTeamClicked] = useState(null);
  const [isMultilineTooltipVisible, setMultilineTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [wasUserTeamAdded, setUserTeamAdded] = useState(false);
  const [addingUserTeam, setAddingUserTeam] = useState(false);
  const [autoRefreshGraph, setAutoRefreshGraph] = useState(false);
  const [refreshHover, setRefreshHover, getLastUpdatedLabel] = useRefreshScore(loadingScore);
  const { user } = useAppContext();
  const userTeam = get(user, 'userInTeam');
  let intervalRefresh;

  COLORS = getTeamColors(boardTeams, eventId);

  const SKELETON_STYLE = {
    COLOR: useTheme().color.skeleton.color,
    EFFECT: useTheme().color.skeleton.highlightColor,
  };

  const getDashboardTopTeams = async () => {
    try {
      setLoadingTeamsScore(true);
      setLoadingScore(true);
      const rq = await API.get('events', `/events/${eventId}/dashboard`, {
        queryStringParameters: {
          pageSize: 5,
        },
      });
      if (wasUserTeamAdded) {
        setBoardTeams(rq.teams.filter((score) => score.total > 0).concat([{ team: userTeam }]));
      } else {
        setBoardTeams(rq.teams.filter((score) => score.total > 0));
      }
      setLoadingTeamsScore(false);
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    getDashboardTopTeams();
    return () => API.abortCurrentRequest();
  }, []);

  useEffect(() => {
    const getTeamsLineScore = async (team) => {
      try {
        const rq = await API.get('teams', `/teams/${team.teamId}/dashboard`);
        const newPoints = rq.datapoints.map((dp) => {
          return {
            name: team.slug,
            [team.slug]: dp.total,
            time: new Date(dp.updatedAt).getTime(),
          };
        });
        setBoardData((prev) => [...prev, ...newPoints]);
        setLoadingScore(false);
      } catch (e) {
        onError(e);
      }
    };

    if (!loadingTeamsScore) {
      if (boardTeams.length) {
        setBoardData([]);
        boardTeams.map((b) => getTeamsLineScore(b.team));
      } else {
        setLoadingScore(false);
      }
    }
  }, [loadingTeamsScore]);

  const onAddMyTeamAction = async () => {
    try {
      if (isEmpty(userTeam)) {
        onError('You need a team first');
        return;
      }
      if (boardTeams.find((t) => t.team.slug === userTeam.slug)) {
        onError('Your team is already part of the graph');
        return;
      }
      setAddingUserTeam(true);
      const rq = await API.get('teams', `/teams/${userTeam.teamId}/dashboard`);
      const newPoints = rq.datapoints.map((dp) => {
        return {
          name: userTeam.slug,
          [userTeam.slug]: dp.total,
          time: new Date(dp.updatedAt).getTime(),
        };
      });
      setBoardData((prev) => [...prev, ...newPoints]);
      setBoardTeams((teams) => teams.concat([{ team: userTeam }]));
      setUserTeamAdded(true);
    } catch (e) {
      onError(e);
    } finally {
      setAddingUserTeam(false);
    }
  };

  const onRemoveMyTeamAction = () => {
    setBoardTeams((teams) => teams.filter((t) => t.team.slug !== userTeam.slug));
    setBoardData((data) => data.filter((t) => t.name !== userTeam.slug));
    setUserTeamAdded(false);
  };

  const hideTooltip = () => {
    setMultilineTooltipVisible(false);
    setTooltipData(null);
  };

  const showTooltip = (e) => {
    const x = Math.round(e.cx);
    const y = Math.round(e.cy);
    setTooltipData({ position: { x, y }, ...e.payload });
    setMultilineTooltipVisible(true);
  };

  useEffect(() => {
    if (autoRefreshGraph) {
      getDashboardTopTeams();
      clearInterval(intervalRefresh);
      intervalRefresh = setInterval(getDashboardTopTeams, 60000);
    }
    return () => clearInterval(intervalRefresh);
  }, [autoRefreshGraph]);

  return (
    <Flex justifyContent="center" my="50px" width={1}>
      <Container mx="10%">
        <Flex justifyContent="center" alignItems="center" width={1} mb="40px">
          <StyledText size="24px" light>
            Score Timeline - Top 5 Teams
          </StyledText>
        </Flex>
        {loadingScore ? (
          <>
            <Flex width={1} mt="60px" mb="15px" px="35px">
              <Flex
                alignItems="center"
                justifyContent="flex-start"
                onClick={() => {
                  setTeamClicked(null);
                  getDashboardTopTeams();
                }}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setRefreshHover(true)}
                onMouseLeave={() => setRefreshHover(false)}
              >
                <RefreshIcon rotate={loadingScore} hover={refreshHover} />
                <RegularText fontSize="12px" marginLeft="3px">
                  {refreshHover ? (
                    <RegularText fontSize="12px" color="#66bb6a">
                      Refresh score
                    </RegularText>
                  ) : (
                    getLastUpdatedLabel()
                  )}
                </RegularText>
              </Flex>
              <Flex ml="auto">
                <Flex mr="4px">
                  <label>
                    <Checkbox
                      checked={autoRefreshGraph}
                      onChange={(e) => setAutoRefreshGraph(e.target.checked)}
                      id="auto-refresh"
                    />
                  </label>
                </Flex>
                <RegularText fontSize="12px">Auto refresh score</RegularText>
              </Flex>
            </Flex>
            <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
              <Skeleton width="100%" height="30px" />
            </SkeletonTheme>
          </>
        ) : (
          <>
            {boardTeams.length ? (
              <>
                <Flex flexDirection="column" alignItems="center">
                  <GraphLegend
                    teams={boardTeams}
                    onTeamHoverLeave={() => setTeamHover(null)}
                    onTeamHoverEnter={(teamSlug) => setTeamHover(teamSlug)}
                    onTeamClick={(teamSlug) => setTeamClicked(teamSlug)}
                    onAddMyTeam={onAddMyTeamAction}
                    onRemoveMyTeam={onRemoveMyTeamAction}
                    isMyTeamInTheGraph={wasUserTeamAdded}
                    loading={addingUserTeam}
                  />
                  <Flex width={1} my="15px" px="35px">
                    <Flex
                      alignItems="center"
                      justifyContent="flex-start"
                      onClick={() => {
                        setTeamClicked(null);
                        getDashboardTopTeams();
                      }}
                      style={{ cursor: 'pointer' }}
                      onMouseEnter={() => setRefreshHover(true)}
                      onMouseLeave={() => setRefreshHover(false)}
                    >
                      <RefreshIcon rotate={loadingScore} hover={refreshHover} />
                      <RegularText fontSize="12px" marginLeft="3px">
                        {refreshHover ? (
                          <RegularText fontSize="12px" color="#66bb6a">
                            Refresh score
                          </RegularText>
                        ) : (
                          getLastUpdatedLabel()
                        )}
                      </RegularText>
                    </Flex>
                    <Flex ml="auto">
                      <Flex mr="4px">
                        <label>
                          <Checkbox
                            checked={autoRefreshGraph}
                            onChange={(e) => setAutoRefreshGraph(e.target.checked)}
                            id="auto-refresh"
                          />
                        </label>
                      </Flex>
                      <Flex
                        onClick={() => setAutoRefreshGraph((v) => !v)}
                        style={{ cursor: 'pointer' }}
                      >
                        <RegularText fontSize="12px">Auto refresh score</RegularText>
                      </Flex>
                    </Flex>
                  </Flex>
                  <ResponsiveContainer width="100%" height={550}>
                    <LineChart data={boardData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                      <XAxis
                        dataKey="time"
                        domain={['auto', 'auto']}
                        name="Time"
                        tick={<CustomizedXAxisTick />}
                        tickLine={{ stroke: '#373737', strokeWidth: '0.5px' }}
                        axisLine={{ stroke: '#2C2C2C' }}
                        tickCount={8}
                        type="number"
                        allowDuplicatedCategory={false}
                        padding={{ left: 30, right: 30 }}
                      />
                      <YAxis
                        tick={<CustomizedYAxisTick />}
                        tickCount={16}
                        axisLine={{ stroke: '#2C2C2C' }}
                        tickLine={{ stroke: '#373737', strokeWidth: '0.5px' }}
                      />
                      <CartesianGrid strokeDasharray="0.5 0.5" stroke="#373737" />
                      {teamClicked && <Tooltip content={<SingleLineTooltip />} />}
                      {boardTeams.map((team, idx) => {
                        const teamColor = COLORS[idx];
                        if (!teamClicked) {
                          return (
                            <Line
                              key={team.team.slug}
                              dataKey={team.team.slug}
                              stroke={teamColor}
                              dot={{
                                fill: getDotFillColor(teamHover, team.team.slug, teamColor),
                                r: teamHover === team.team.slug ? 3.5 : 2.5,
                                onMouseEnter: (e) => showTooltip(e),
                                onMouseLeave: () => hideTooltip(),
                              }}
                              strokeOpacity={getLineOpacity(teamHover, team.team.slug)}
                              strokeWidth={teamHover === team.team.slug ? 2 : 1.5}
                            />
                          );
                        }
                        if (teamClicked === team.team.slug) {
                          return (
                            <Line
                              key={team.team.slug}
                              dataKey={team.team.slug}
                              stroke={teamColor}
                              dot={{
                                fill: teamColor,
                                r: 3,
                              }}
                              activeDot={{
                                r: 4,
                                fill: teamColor,
                                stroke: teamColor,
                              }}
                              strokeOpacity={1}
                              strokeWidth={2}
                            />
                          );
                        }
                        return null;
                      })}
                    </LineChart>
                  </ResponsiveContainer>
                </Flex>
                {isMultilineTooltipVisible && <MultiLineTooltip {...tooltipData} />}
              </>
            ) : (
              <Flex
                width={1}
                justifyContent="center"
                p="12px"
                style={{ borderBottom: '1px solid #2C2C2C' }}
              >
                <RegularText>There is no data yet...</RegularText>
              </Flex>
            )}
          </>
        )}
      </Container>
    </Flex>
  );
}
