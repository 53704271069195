import React from 'react';
import { Flex } from 'reflexbox';
import Media from 'react-media';
import { QuestionInLine } from 'boss-ui';
import EditableQuestionBigCard from './EditableQuestionBigCard';
import { useScenarioContext } from '../../libs/context-lib';

export default function QuestionsList({
  onQuestionCardClick = () => {},
  onQuestionCardClosed = () => {},
  onQuestionRemoved = () => {},
  focusedQuestionId,
  removingQuestionId,
  isImportationView = false,
  eventId = null
}) {
  const { state } = useScenarioContext();

  return (
    <Media
      queries={{
        s: '(max-width: 1000px)',
        m: '(max-width: 1290px)',
        l: '(max-width: 1540px)',
        xl: '(min-width: 1880px)',
      }}
    >
      {(matches) => (
        <Flex width={1} flexDirection="column">
          {state.questionsList.map((q, idx) => {
            const isSelected = focusedQuestionId === q.question.question_id;
            const isQuestionBeingRemoving = removingQuestionId === q.question.question_id;
            const hasQuestionError = state.error && state.error.questionLine === idx;
            return (
              <React.Fragment key={q.question.question_id}>
                <QuestionInLine
                  isEditionView
                  selectedCard={isSelected}
                  question={q.question}
                  answer={q.answer}
                  points={q.total}
                  onSelectCard={() => onQuestionCardClick(q.question)}
                  onRemoveQuestion={() => onQuestionRemoved(q.question.question_id)}
                  removingQuestion={isQuestionBeingRemoving}
                  error={hasQuestionError && state.error}
                />
                {isSelected && (
                  <EditableQuestionBigCard
                    media={matches}
                    isImportationView={isImportationView}
                    onClose={onQuestionCardClosed}
                    error={hasQuestionError && state.error}
                    eventId={eventId}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Flex>
      )}
    </Media>
  );
}
