
import CheckMark from "../../icons/CheckMark";
import SpinnerIcon from "../../icons/SpinnerIcon";
import CenteredFlex from "../common/CenteredFlex";
import Space from "../common/Space";

export default function SaveButton({ loading }) {
  return (
    <CenteredFlex>
      {loading ? <SpinnerIcon size={18} /> : (
        <>
          <CheckMark size={18} /><Space />SAVE
        </>
      )}
    </CenteredFlex>
  )
}