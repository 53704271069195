import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'boss-ui';
import { Flex } from 'reflexbox';
import { SearchTeams } from '../../components';
import TeamDetails from '../TeamDetails';
import { useEventContext } from '../../libs/context-lib';
import { useBreadcrumb } from '../../libs/hooks-lib';

export default function Teams() {
  const { state } = useEventContext();
  const [breadcrumbs, setBreadcrumbs] = useBreadcrumb();
  const location = useLocation();
  const match = useRouteMatch(location.pathname);
  const routeTeam = useRouteMatch('/events/:eventId/teams/:teamId');
  const eventId = routeTeam ? routeTeam.params.eventId : '';

  useEffect(() => {
    if (routeTeam && routeTeam.params && routeTeam.params.teamId) {
      setBreadcrumbs(
        [
          {
            path: `/events/${eventId}/teams`,
            name: 'Teams',
          },
          {
            path: `/events/${eventId}/teams/${state.team.teamId}`,
            name: state.team.name,
          },
        ],
        match
      );
    }
  }, [location, state.team]);

  return (
    <Switch>
      <Route path="/events/:eventId/teams" exact>
        <Flex width={1} justifyContent="center">
          <Flex mb="5%" flexDirection="column" mt="60px">
            <SearchTeams />
          </Flex>
        </Flex>
      </Route>
      <Route path="/events/:eventId/teams/:teamId" exact>
        <Flex width={1} justifyContent="center">
          <Flex mb="5%" flexDirection="column" mt="20px">
            <Flex mb="20px">
              <Breadcrumb crumbs={breadcrumbs} />
            </Flex>
            <TeamDetails />
          </Flex>
        </Flex>
      </Route>
    </Switch>
  );
}
