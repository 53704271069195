import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Flex } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import { LoaderIcon } from 'boss-ui';
import { useAppContext } from '../../libs/context-lib';
import { deleteUserHeaders, sendWarning } from '../../libs/utils-lib';
import { onError, getMessageError } from '../../libs/error-lib';

export default function Logout() {
  const { dispatch, state, APP_ACTIONS } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await Auth.signOut();
        dispatch({ type: APP_ACTIONS.LOGOUT_USER });
        deleteUserHeaders();
      } catch (err) {
        onError(err);
        sendWarning(`logout Error ${getMessageError(err)}`);
      } finally {
        history.push('/login');
      }
    };
    handleLogout();
  }, [APP_ACTIONS, dispatch, history]);

  return (
    <Flex mt="15%" alignItems="center" justifyContent="center" flexDirection="column">
      <LoaderIcon appType={state.appType} />
    </Flex>
  );
}
