import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Flex, Box } from 'reflexbox';
import {
  Form,
  FormLeyend,
  LinkedText,
  TableItemSkeleton,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  HovereableFlex,
  RefreshIcon,
  Tooltip
} from 'boss-ui';
import Api from '../../api';
import FormInput from '../FormInput';
import { onError } from '../../libs/error-lib';
import { useAppContext } from '../../libs/context-lib';
import { APP_ACTIONS } from '../../libs/reducerAction-lib';

const API = new Api();

export default function EventScenarios({loading = false, scenarios = []}) {
  const appContext = useAppContext();
  const userRouteMatch = useRouteMatch('/events/:eventId/scenarios');
  const [filteredScenarios, setFilteredScenarios] = useState(scenarios);
  const [searchString, setSearchString] = useState('');
  const [eventLoading, setEventLoading] = useState(loading);
  const history = useHistory();

  const getEventById = async () => {
    setEventLoading(true);
    try {
      const rq = await API.get('events', `/admin/events/${userRouteMatch.params.eventId}`);
      if (rq.event && rq.event.scenarios) {
        setFilteredScenarios(rq.event.scenarios);
      }
    } catch (err) {
      onError(err);
    } finally {
      setEventLoading(false);
    }
  };

  const cleanBox = () => {
    setSearchString('');
  };

  const findScenarios = (text = '') => {
    const temp = [...scenarios];
    setFilteredScenarios(
      temp.filter(
        (s) => s.title && s.title.toUpperCase().indexOf(text.toUpperCase()) > -1
      )
    )
  };

  useEffect(() => {
    findScenarios(searchString);
  }, [searchString]);

  useEffect(() => {
    setEventLoading(loading);
  }, [loading]);
  
  useEffect(() => {
    setFilteredScenarios(scenarios);
  }, [scenarios.length]);  

  const onScenarioClick = (scenario) => {
    appContext.dispatch({
      type: APP_ACTIONS.SET_ROUTES,
      data: [
        {
          path: `/scenarios`,
          name: 'Scenarios',
        },
        {
          path: `/scenarios/${scenario.scenarioId}`,
          name: scenario.title,
        },
      ],
    });
    history.push(`/events/${userRouteMatch.params.eventId}/scenarios/${scenario.scenarioId}/questions`);
  };

  return (
    <Form noBorder>
      <Flex px="40px" py="30px" width="924px" height="700px" flexDirection="column">
        <Box mb="10px" width={2 / 3}>
          <FormLeyend>Find a Scenario</FormLeyend>
          <FormInput
            search
            remove={searchString.length > 0 && cleanBox}
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            placeholder="Search by scenario name"
          />
        </Box>
        <HovereableFlex data-tip data-for="refresh" width="20px" ml="10px" mb="12px">
          <RefreshIcon onClick={getEventById} color="#898989" hoverColor="#d0d0d0" />
          <Tooltip id="refresh">Refresh list</Tooltip>          
        </HovereableFlex>
        <Flex flexDirection="column" justifyContent="space-between" height="100%">
          <Table>
            <TableHead>
              <TableRow height="41px">
                <TableHeader>Scenario</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
            {eventLoading ? (
                <TableItemSkeleton numberOfCells={1} />
              ) : (
                filteredScenarios.map((scenario) => (
                    <TableRow height="41px" key={scenario.scenarioId}>
                      <TableData>
                        <LinkedText onClick={() => onScenarioClick(scenario)}>
                          {scenario.title}
                        </LinkedText>
                      </TableData>
                    </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </Flex>
      </Flex>
    </Form>
  );
}
