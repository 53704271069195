import { isNumeric, replaceAll } from '../../libs/utils-lib';
import { SEP_CHAR, exportFile, escapeField } from '../../libs/csv-lib';

const BASE_INT = 10;
const EXTERNAL_BOOLEAN_TRUE = 'TRUE';
const EXTERNAL_BOOLEAN_FALSE = 'FALSE';
const QUESTION_LINE_ERROR_INDEX = 1;
const QUESTION_PATH_ERROR_SEPARATOR = '/';
const CVS_HEADER_REPLACE_PAT = 'X';

export const PATH_ERROR_TYPE = {
  HINTS: 'hints',
  ANSWERS: 'answers',
  PHRASINGS: 'phrasings',
};
export const PHRASING_FORM_TYPE = { HINT: 'Hint', ANSWER: 'Answer' };
export const PHRASING_ACTION_TYPE = { ADD: 'Add', UPDATE: 'Update', REMOVE: 'Remove' };
export const COMBO_MATCH_TYPES = [
  { value: '', label: 'Select a match type' },
  { value: 'exact', label: 'Exact' },
  { value: 'regex', label: 'Regular expression' },
];
export const BASE_POINTS_NUMBER_REGEXP = /^[0-9]{1,}$/;
export const NUMBER_REGEXP = /^[0-9]{1,}$/;
export const CSV_SCENARIO_COLUMN = 'scenario_id';
export const CSV_HEADER_QUESTION_COLUMNS =
  'author,title,background,number,difficulty,base_points,external,learning_objective';
export const CSV_HEADER_PHRASING_COLUMNS =
  'product,phrasing_number,question,answer_guidance,how_to_solve';
const CSV_HINT_HEADER_COLUMNS = `hint${CVS_HEADER_REPLACE_PAT},hint${CVS_HEADER_REPLACE_PAT}_cost,hint${CVS_HEADER_REPLACE_PAT}_elapsed_time_before_visible`;
const CSV_ANSWER_HEADER_COLUMNS = `answer_${CVS_HEADER_REPLACE_PAT},match_type_${CVS_HEADER_REPLACE_PAT}`;

const setExternalValue = (value) => {
  if (value && value.toString().toUpperCase() === EXTERNAL_BOOLEAN_TRUE) {
    return true;
  }
  return false;
};

export const reduceQuestionFile = (csvQuestions = []) =>
  csvQuestions.reduce((result, csvQuestion) => {
    // we use the names of CSV_HEADER_QUESTION_COLUMNS, CSV_HEADER_PHRASING_COLUMNS, CSV_HINT_HEADER_COLUMNS, CSV_ANSWER_HEADER_COLUMNS to interact and process the file
    let question = result.find(
      (resultQuestion) => resultQuestion.question_id === csvQuestion.number
    );
    if (question) {
      const newPhrasing = {
        phrasing_id: csvQuestion.phrasing_number,
        question_phrasing: csvQuestion.question,
        answer_guidance: csvQuestion.answer_guidance,
        product: csvQuestion.product,
        how_to_solve: csvQuestion.how_to_solve,
        answers: [],
        hints: [],
      };
      question.phrasings.push(newPhrasing);
    } else {
      question = {
        scenario_id: csvQuestion.scenario_id,
        question_id: csvQuestion.number,
        difficulty: csvQuestion.difficulty,
        title: csvQuestion.title || csvQuestion.question,
        author: csvQuestion.author,
        description: csvQuestion.description,
        background: csvQuestion.background,
        external: setExternalValue(csvQuestion.external),
        base_point_value: parseInt(csvQuestion.base_points, BASE_INT),
        learning_objective: csvQuestion.learning_objective,
        required_resources: [],
        phrasings: [
          {
            phrasing_id: csvQuestion.phrasing_number,
            question_phrasing: csvQuestion.question,
            answer_guidance: csvQuestion.answer_guidance,
            product: csvQuestion.product,
            how_to_solve: csvQuestion.how_to_solve,
            answers: [],
            hints: [],
          },
        ],
      };
      result.push(question);
    }

    const lastAddedPhrasing = question.phrasings[question.phrasings.length - 1];

    // Adding Answers
    // BOTS format
    const answer = csvQuestion.answer;
    if (answer) {
      lastAddedPhrasing.answers.push({
        answer: csvQuestion.answer,
        match_type: csvQuestion.match_type,
      });
    }

    let asnwerIndex = 1;
    while (csvQuestion[`answer_${asnwerIndex}`]) {
      lastAddedPhrasing.answers.push({
        answer: csvQuestion[`answer_${asnwerIndex}`],
        match_type: csvQuestion[`match_type_${asnwerIndex}`],
      });
      asnwerIndex++;
    }

    // Adding Hints
    let hintIndex = 1;
    while (csvQuestion[`hint${hintIndex}`]) {
      lastAddedPhrasing.hints.push({
        hint_id: hintIndex,
        hint_cost: parseInt(csvQuestion[`hint${hintIndex}_cost`], BASE_INT),
        hint: csvQuestion[`hint${hintIndex}`],
        hint_sequence: hintIndex,
        elapsed_time_before_visible: parseInt(
          csvQuestion[`hint${hintIndex}_elapsed_time_before_visible`],
          BASE_INT
        ),
      });
      hintIndex++;
    }

    return result;
  }, []);

const formatPhrasingList = (phrasings) => {
  const arrPhrasings = [...phrasings];
  for (let p = 0; p < arrPhrasings.length; p++) {
    if (arrPhrasings[p].hints) {
      for (let i = 0; i < arrPhrasings[p].hints.length; i++) {
        const hintIndex = i + 1;
        arrPhrasings[p].hints[i] = {
          ...arrPhrasings[p].hints[i],
          hint_id: hintIndex.toString(),
          hint_sequence: hintIndex,
          hint_cost: Number(arrPhrasings[p].hints[i].hint_cost),
          elapsed_time_before_visible: Number(arrPhrasings[p].hints[i].elapsed_time_before_visible),
        };
      }
    }
  }
  return arrPhrasings;
};

export const makeQuestionPayload = (question) => {
  return {
    question_id: question.question_id,
    title: question.title,
    background: question.background,
    base_point_value: question.base_point_value,
    external: question.external,
    learning_objective: question.learning_objective,
    required_resources: question.required_resources,
    author: question.author,
    difficulty: question.difficulty,
    phrasings: formatPhrasingList(question.phrasings),
  };
};

export const getIndexError = (errorPath = '', questionItemKey) => {
  let idx = -1;
  const errorKey = `${QUESTION_PATH_ERROR_SEPARATOR}${questionItemKey}${QUESTION_PATH_ERROR_SEPARATOR}`;
  if (errorPath && errorPath.toString().indexOf(errorKey) > -1) {
    const arrError = errorPath.split(errorKey);
    if (arrError.length > 1) {
      const idxError = arrError[1].split(QUESTION_PATH_ERROR_SEPARATOR);
      if (isNumeric(idxError[0])) {
        idx = Number(idxError[0]);
      }
    }
  }
  return idx;
};

export const getErrorQuestionLine = (errorPath = '') => {
  const instancePathIndex = errorPath.split(QUESTION_PATH_ERROR_SEPARATOR);
  if (instancePathIndex.length > 1 && isNumeric(instancePathIndex[QUESTION_LINE_ERROR_INDEX])) {
    return Number(instancePathIndex[QUESTION_LINE_ERROR_INDEX]);
  }
  return null;
};

const removeItemPhrasing = (type, phrasing, itemIdx) => {
  const tmpPhrasing = { ...phrasing };
  if (type === PHRASING_FORM_TYPE.HINT) {
    tmpPhrasing.hints.splice(itemIdx, 1);
  } else {
    tmpPhrasing.answers.splice(itemIdx, 1);
  }
  return tmpPhrasing;
};

export const makePhrasingObj = (type, form) => {
  if (type === PHRASING_FORM_TYPE.HINT) {
    return {
      hint_cost: Number(form.hint_cost),
      elapsed_time_before_visible: Number(form.elapsed_time_before_visible),
      hint: form.hint,
    };
  }
  return {
    match_type: form.match_type,
    answer: form.answer,
  };
};

const updateItemPhrasing = (type, phrasing, itemIdx, form) => {
  const tmpPhrasing = { ...phrasing };
  if (type === PHRASING_FORM_TYPE.HINT) {
    tmpPhrasing.hints[itemIdx] = {
      ...tmpPhrasing.hints[itemIdx],
      ...makePhrasingObj(PHRASING_FORM_TYPE.HINT, form),
    };
  } else {
    tmpPhrasing.answers[itemIdx] = {
      ...tmpPhrasing.answers[itemIdx],
      ...makePhrasingObj(PHRASING_FORM_TYPE.ANSWER, form),
    };
  }
  return tmpPhrasing;
};

const addItemPhrasing = (type, phrasing, form) => {
  const tmpPhrasing = { ...phrasing };
  if (type === PHRASING_FORM_TYPE.HINT) {
    tmpPhrasing.hints.push(makePhrasingObj(PHRASING_FORM_TYPE.HINT, form));
  } else {
    tmpPhrasing.answers.push(makePhrasingObj(PHRASING_FORM_TYPE.ANSWER, form));
  }
  return tmpPhrasing;
};

export const phrasingItemCRUD = (operation, item, itemType, itemIdx, ...args) => {
  switch (operation) {
    case PHRASING_ACTION_TYPE.REMOVE:
      return removeItemPhrasing(itemType, item, itemIdx);
    case PHRASING_ACTION_TYPE.UPDATE:
      return updateItemPhrasing(itemType, item, itemIdx, ...args);
    default:
      return addItemPhrasing(itemType, item, ...args);
  }
};

// EXPORT FUNCTIONS
// get the max .length found for hints and answers array in the whole object.
export const getMaxPhrasingItemLength = (questions, type) => {
  let maxLen = 0;
  questions.map(({ question }) => {
    return question.phrasings.map((p) => {
      maxLen = p[type] && p[type].length > maxLen ? p[type].length : maxLen;
      return maxLen;
    });
  });
  return maxLen;
};

export const addCsvPhrasingHeader = (maxHintsLen = 0, maxAnswersLen = 0) => {
  let header = '';
  for (let h = 1; h <= maxHintsLen; h++) {
    header += `${replaceAll(CSV_HINT_HEADER_COLUMNS, CVS_HEADER_REPLACE_PAT, h)}${SEP_CHAR}`;
  }
  for (let a = 1; a <= maxAnswersLen; a++) {
    header += `${replaceAll(CSV_ANSWER_HEADER_COLUMNS, CVS_HEADER_REPLACE_PAT, a)}${SEP_CHAR}`;
  }
  return header.substring(0, header.length - SEP_CHAR.length);
};

const setExternalQuestionText = (value) => {
  return value ? EXTERNAL_BOOLEAN_TRUE : EXTERNAL_BOOLEAN_FALSE;
};

export const questionObj2Csv = (question) => {
  // used for unit test
  let text = `${escapeField(question.author)}${SEP_CHAR}`;
  text += `${escapeField(question.title)}${SEP_CHAR}`;
  text += `${escapeField(question.background)}${SEP_CHAR}`;
  text += `${escapeField(question.question_id)}${SEP_CHAR}`;
  text += `${escapeField(question.difficulty)}${SEP_CHAR}`;
  text += `${escapeField(question.base_point_value)}${SEP_CHAR}`;
  text += `${escapeField(setExternalQuestionText(question.external))}${SEP_CHAR}`;
  text += `${escapeField(question.learning_objective)}`;
  return text;
};

const questionObj2Arr = (question) => {
  return [
    question.author,
    question.title,
    question.background,
    question.question_id,
    question.difficulty,
    question.base_point_value,
    setExternalQuestionText(question.external),
    question.learning_objective,
  ];
};

const hintObj2Csv = (hints, maxHintsLen) => {
  let hintTxt = '';
  for (let h = 0; h < maxHintsLen; h++) {
    if (hints && h < hints.length) {
      hintTxt += `${escapeField(hints[h].hint)}${SEP_CHAR}`;
      hintTxt += `${escapeField(hints[h].hint_cost)}${SEP_CHAR}`;
      hintTxt += `${escapeField(hints[h].elapsed_time_before_visible)}${SEP_CHAR}`;
    } else {
      hintTxt += `${SEP_CHAR}${SEP_CHAR}${SEP_CHAR}`; // fill with extra blanks for the undefined items
    }
  }
  // used for unit test
  return hintTxt.substring(0, hintTxt.length - SEP_CHAR.length);
};

const hintObj2Arr = (hints, maxHintsLen) => {
  const arrHint = [];
  for (let h = 0; h < maxHintsLen; h++) {
    if (hints && h < hints.length) {
      arrHint.push(hints[h].hint);
      arrHint.push(hints[h].hint_cost);
      arrHint.push(hints[h].elapsed_time_before_visible);
    }
  }
  arrHint.length = maxHintsLen * 3;
  return arrHint;
};

const answerObj2Csv = (answer, maxAnswersLen) => {
  let answerTxt = '';
  for (let a = 0; a < maxAnswersLen; a++) {
    if (answer && a < answer.length) {
      answerTxt += `${escapeField(answer[a].answer)}${SEP_CHAR}`;
      answerTxt += `${escapeField(answer[a].match_type)}${SEP_CHAR}`;
    } else {
      answerTxt += `${SEP_CHAR}${SEP_CHAR}`; // extra blanks for the undefined items
    }
  }
  // used for unit test
  return answerTxt.substring(0, answerTxt.length - SEP_CHAR.length);
};

const answerObj2Arr = (answer, maxAnswersLen) => {
  const arrAnswer = [];
  for (let a = 0; a < maxAnswersLen; a++) {
    if (answer && a < answer.length) {
      arrAnswer.push(answer[a].answer);
      arrAnswer.push(answer[a].match_type);
    }
  }
  arrAnswer.length = maxAnswersLen * 2;
  return arrAnswer;
};

export const phrasingObj2Csv = (phrasing, maxHintsLen, maxAnswersLen) => {
  let phrasingText = `${escapeField(phrasing.product)}${SEP_CHAR}`;
  phrasingText += `${escapeField(phrasing.phrasing_id)}${SEP_CHAR}`;
  phrasingText += `${escapeField(phrasing.question_phrasing)}${SEP_CHAR}`;
  phrasingText += `${escapeField(phrasing.answer_guidance)}${SEP_CHAR}`;
  phrasingText += `${escapeField(phrasing.how_to_solve)}${SEP_CHAR}`;
  phrasingText += `${hintObj2Csv(phrasing.hints, maxHintsLen)}${SEP_CHAR}`;
  phrasingText += `${answerObj2Csv(phrasing.answers, maxAnswersLen)}`;
  return phrasingText;
};

const phrasingObj2Arr = (phrasing, maxHintsLen, maxAnswersLen) => {
  return [
    phrasing.product,
    phrasing.phrasing_id,
    phrasing.question_phrasing,
    phrasing.answer_guidance,
    phrasing.how_to_solve,
    ...hintObj2Arr(phrasing.hints, maxHintsLen),
    ...answerObj2Arr(phrasing.answers, maxAnswersLen),
  ];
};

export const questions2GenericArray = (questions, maxHintsLen = 1, maxAnswersLen = 1) => {
  const genericArray = [];
  questions.forEach(({ question }) => {
    question.phrasings.forEach((phrasing) => {
      genericArray.push({
        fields: [
          ...questionObj2Arr(question),
          ...phrasingObj2Arr(phrasing, maxHintsLen, maxAnswersLen),
        ],
      });
    });
  });
  return genericArray;
};

export const exportQuestionsFile = (data, scenarioTitle = '') => {
  const maxHintsLen = getMaxPhrasingItemLength(data, 'hints');
  const maxAnswersLen = getMaxPhrasingItemLength(data, 'answers');

  const header = [
    ...CSV_HEADER_QUESTION_COLUMNS.split(SEP_CHAR),
    ...CSV_HEADER_PHRASING_COLUMNS.split(SEP_CHAR),
    ...addCsvPhrasingHeader(maxHintsLen, maxAnswersLen).split(SEP_CHAR),
  ];

  exportFile(
    { header, data: questions2GenericArray(data, maxHintsLen, maxAnswersLen) },
    scenarioTitle
  );
};
