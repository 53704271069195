import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import { Button, RegularText } from 'boss-ui';
import FormInput from '../../FormInput';
import { useFormFields } from '../../../libs/hooks-lib';
import { INPUT_FORM_TYPE_TEXTAREA } from '../../../libs/constants';
import PhrasingItemModal from './PhrasingItemModal';
import {
  PHRASING_FORM_TYPE,
  PHRASING_ACTION_TYPE,
  makeQuestionPayload,
  phrasingItemCRUD,
} from '../utils';
import { onError } from '../../../libs/error-lib';
import Api from '../../../api';
import { useScenarioContext } from '../../../libs/context-lib';
import QuestionHintsTable from '../QuestionHintsTable';
import QuestionAnswersTable from '../QuestionAnswersTable';
import { SCENARIO_QUESTION_ACTIONS } from '../../../libs/reducerAction-lib';

const API = new Api();
const Container = styled(Flex)`
  background-color: #181818;
  box-shadow: 0px 12px 16px #0000004d;
  width: 100%;
  padding: ${(props) => props.theme.margin.s};
  border-color: ${(props) => props.theme.color.question_card.border.pending};
`;
Container.displayName = 'AdminQuestionPhrasingCreate-Container';
const DEFAULT_PHRASING_OBJ = { visible: false };

const formatError = (e) => {
  const errorPath = get(e, 'response.data.errors[0].details.schemaPath');
  return errorPath.split('/');
};

const RequiredFieldMark = () => (
  <RegularText
    color="#e06c75"
    mediumWeight
    style={{ position: 'relative', top: '-5px', left: '3px' }}
  >
    *
  </RegularText>
);

export default function QuestionPhrasingCreate({ isImportationView = false }) {
  const [newPhrasingForm, onNewPhrasingFormChange] = useFormFields({
    question_phrasing: '',
    answer_guidance: '',
    hints: [],
    answers: [],
  });
  const { state, dispatch } = useScenarioContext();
  const [phrasingModalObject, setPhrasingModalObject] = useState(DEFAULT_PHRASING_OBJ);
  const [creatingPhrasing, setCreatingPhrasing] = useState(false);
  const [creationError, setCreationError] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      newPhrasingForm.question_phrasing.length > 0 && newPhrasingForm.answers.length > 0
    );
  }, [newPhrasingForm]);

  const onUpsertItemPhrasing = (form, type, itemIdx, action) => {
    const tmpPhrasingForm = phrasingItemCRUD(action, newPhrasingForm, type, itemIdx, form);
    if (type === PHRASING_FORM_TYPE.ANSWER) {
      onNewPhrasingFormChange({
        target: {
          value: tmpPhrasingForm.answers,
          id: 'answers',
        },
      });
    } else {
      onNewPhrasingFormChange({
        target: {
          value: tmpPhrasingForm.hints,
          id: 'hints',
        },
      });
    }
    setPhrasingModalObject(DEFAULT_PHRASING_OBJ);
  };

  const onDeleteItemPhrasing = (type, itemIdx) => {
    const tmpPhrasingForm = phrasingItemCRUD(
      PHRASING_ACTION_TYPE.REMOVE,
      newPhrasingForm,
      type,
      itemIdx
    );
    if (type === PHRASING_FORM_TYPE.ANSWER) {
      onNewPhrasingFormChange({
        target: {
          value: tmpPhrasingForm.answers,
          id: 'answers',
        },
      });
    } else {
      onNewPhrasingFormChange({
        target: {
          value: tmpPhrasingForm.hints,
          id: 'hints',
        },
      });
    }
  };

  const onCreatePhrasing = async () => {
    setCreatingPhrasing(true);
    const tmpQuestion = cloneDeep(state.openedQuestion);
    try {
      tmpQuestion.phrasings.push({
        ...newPhrasingForm,
        phrasing_id: (tmpQuestion.phrasings.length + 1).toString(),
      });
      const updatedQuestion = await API.put(
        'events',
        `/admin/scenarios/${state.scenario.scenarioId}/questions/${state.openedQuestion.question_id}`,
        {
          body: makeQuestionPayload(tmpQuestion),
        }
      );
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.SET_OPENED_QUESTION,
        data: tmpQuestion,
      });
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.UPDATE_QUESTIONS_LIST,
        data: updatedQuestion,
      });
    } catch (e) {
      onError(e);
      setCreationError(formatError(e));
    } finally {
      setCreatingPhrasing(false);
    }
  };

  const onAddPhrasingToImportData = () => {
    const tmpQuestion = cloneDeep(state.openedQuestion);
    tmpQuestion.phrasings.push({
      ...newPhrasingForm,
      phrasing_id: (tmpQuestion.phrasings.length + 1).toString(),
    });
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.SET_OPENED_QUESTION,
      data: tmpQuestion,
    });
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.UPDATE_QUESTIONS_LIST,
      data: { question: tmpQuestion, questionId: tmpQuestion.question_id },
    });
  };

  const onAddPhrasing = async () => {
    if (isImportationView) {
      onAddPhrasingToImportData();
    } else {
      await onCreatePhrasing();
    }
  };

  return (
    <Container>
      <Flex mt="10px" flexDirection="column" width="100%">
        <Flex>
          <RegularText fontSize="18px" mediumWeight>
            Question
          </RegularText>
          <RequiredFieldMark />
        </Flex>
        <FormInput
          id="question_phrasing"
          value={newPhrasingForm.question_phrasing}
          onChange={onNewPhrasingFormChange}
          inputType={INPUT_FORM_TYPE_TEXTAREA}
          placeholder="Question text"
          style={{ height: '100px' }}
        />
        <Flex mt="6px" />
        <RegularText color="#81A2BE" align="left">
          Answer guidance:
        </RegularText>
        <FormInput
          id="answer_guidance"
          value={newPhrasingForm.answer_guidance}
          onChange={onNewPhrasingFormChange}
          placeholder="Question guidance text"
        />
        <Flex mt="40px" flexDirection="column">
          <Flex mb="4px">
            <RegularText mediumWeight fontSize="14px">
              Hints
            </RegularText>
          </Flex>
          <Flex width={1}>
            <QuestionHintsTable
              hints={newPhrasingForm.hints}
              error={creationError}
              onAddItem={() =>
                setPhrasingModalObject({
                  visible: true,
                  type: PHRASING_FORM_TYPE.HINT,
                  action: PHRASING_ACTION_TYPE.ADD,
                })
              }
              onEditItem={(data, idx) =>
                setPhrasingModalObject({
                  visible: true,
                  type: PHRASING_FORM_TYPE.HINT,
                  action: PHRASING_ACTION_TYPE.UPDATE,
                  data,
                  idx,
                })
              }
              onRemoveItem={(idx) => onDeleteItemPhrasing(PHRASING_FORM_TYPE.HINT, idx)}
            />
          </Flex>
        </Flex>
        <Flex mt="15px" flexDirection="column">
          <Flex mb="4px">
            <RegularText mediumWeight fontSize="14px">
              Answers
            </RegularText>
            <RequiredFieldMark />
          </Flex>
          <Flex width={1}>
            <QuestionAnswersTable
              answers={newPhrasingForm.answers}
              error={creationError}
              onAddItem={() =>
                setPhrasingModalObject({
                  visible: true,
                  type: PHRASING_FORM_TYPE.ANSWER,
                  action: PHRASING_ACTION_TYPE.ADD,
                })
              }
              onEditItem={(data, idx) =>
                setPhrasingModalObject({
                  visible: true,
                  type: PHRASING_FORM_TYPE.ANSWER,
                  action: PHRASING_ACTION_TYPE.UPDATE,
                  data,
                  idx,
                })
              }
              onRemoveItem={(idx) => onDeleteItemPhrasing(PHRASING_FORM_TYPE.ANSWER, idx)}
            />
          </Flex>
        </Flex>
        <Flex ml="auto" mt="20px">
          <Box width="70px">
            <Button onClick={onAddPhrasing} disabled={creatingPhrasing || !isFormValid}>
              ADD
            </Button>
          </Box>
        </Flex>
      </Flex>
      {phrasingModalObject.visible && (
        <PhrasingItemModal
          type={phrasingModalObject.type}
          action={phrasingModalObject.action}
          data={phrasingModalObject.data}
          itemIdx={phrasingModalObject.idx}
          onAccept={onUpsertItemPhrasing}
          onCancel={() => setPhrasingModalObject(DEFAULT_PHRASING_OBJ)}
        />
      )}
    </Container>
  );
}
