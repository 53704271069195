import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import isEmpty from 'lodash.isempty';
import trim from 'lodash.trim';
import find from 'lodash.find';
import { Button, Modal, FormLabel, Select, SpinnerIcon } from 'boss-ui';
import { useFormFields } from '../../../libs/hooks-lib';
import {
  PHRASING_FORM_TYPE,
  PHRASING_ACTION_TYPE,
  NUMBER_REGEXP,
  COMBO_MATCH_TYPES,
} from '../utils';
import FormInput from '../../FormInput';
import { isValidPattern } from '../../../libs/utils-lib';
import { INPUT_FORM_TYPE_TEXTAREA } from '../../../libs/constants';

const Background = styled(Flex)`
  background-color: ${(props) => props.theme.color.form.background};
  padding: ${(props) => props.theme.margin.m};
`;

const isValueEmpty = (value) => {
  return !value || isEmpty(value.toString()) || trim(value) === '';
};

const HintForm = (props) => {
  const { form, handleFormChange } = props;
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="flex-start">
      <Flex flexDirection="row" justifyContent="space-around" alignItems="center" width={1}>
        <Box width={1 / 2} px="10px">
          <FormLabel>Points</FormLabel>
          <FormInput
            autoFocus
            id="hint_cost"
            value={form.hint_cost}
            onChange={handleFormChange}
            placeholder="Enter hints points"
            pattern={NUMBER_REGEXP}
            invalidPatternMessage="Must be a number"
            validate
            available
          />
        </Box>
        <Box width={1 / 2} px="10px">
          <FormLabel>Elapsed time</FormLabel>
          <FormInput
            id="elapsed_time_before_visible"
            value={form.elapsed_time_before_visible}
            onChange={handleFormChange}
            placeholder="Enter elapsed time"
            pattern={NUMBER_REGEXP}
            invalidPatternMessage="Must be a number"
            validate
            available
          />
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        width={1}
        mt="10px"
      >
        <Box width={1} px="10px">
          <FormLabel>Hint</FormLabel>
          <FormInput
            id="hint"
            value={form.hint}
            onChange={handleFormChange}
            available
            placeholder="Enter Hint"
            inputType={INPUT_FORM_TYPE_TEXTAREA}
            style={{ height: '80px' }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

const AnswerForm = (props) => {
  const { form, handleFormChange } = props;
  let selectedDefault = COMBO_MATCH_TYPES[0];
  const editedMatch = find(COMBO_MATCH_TYPES, (c) => c.value === form.match_type);
  if (editedMatch) {
    selectedDefault = editedMatch;
  }
  const [selectedMatch, setSelectedMatch] = useState(selectedDefault);

  const onFilterChange = (selected) => {
    setSelectedMatch(selected);
    handleFormChange({ target: { value: selected.value, id: 'match_type' } });
  };

  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="flex-start">
      <Flex flexDirection="row" justifyContent="space-around" alignItems="center" width={1}>
        <Box width={1} px="10px">
          <FormLabel>Match type</FormLabel>
          <Select
            autoFocus
            value={selectedMatch}
            onChange={onFilterChange}
            options={COMBO_MATCH_TYPES}
          />
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        width={1}
        mt="10px"
      >
        <Box width={1} px="10px">
          <FormLabel>Answer</FormLabel>
          <FormInput
            id="answer"
            value={form.answer}
            onChange={handleFormChange}
            available
            placeholder="Enter answer"
            inputType={INPUT_FORM_TYPE_TEXTAREA}
            style={{ height: '80px' }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
const validateHint = (form) => {
  return (
    isValidPattern(form.hint_cost, NUMBER_REGEXP) &&
    isValidPattern(form.elapsed_time_before_visible, NUMBER_REGEXP) &&
    !isValueEmpty(form.hint)
  );
};
const getFormStructure = (resourceType, data) => {
  switch (resourceType) {
    case PHRASING_FORM_TYPE.HINT:
      return {
        hint_cost: '',
        hint: '',
        elapsed_time_before_visible: '',
        ...data,
        validate: (form) => validateHint(form),
      };
    case PHRASING_FORM_TYPE.ANSWER:
      return {
        match_type: '',
        answer: '',
        ...data,
        validate: (form) => !isValueEmpty(form.match_type) && !isValueEmpty(form.answer),
      };
    default:
      return {};
  }
};
const ADD_LABEL = 'ADD';
const UPDATE_LABEL = 'UPDATE';

export default function PhrasingItemModal(props) {
  const { type, action, data = {}, itemIdx, onAccept, onCancel, updating } = props;
  const [form, handleFormChange] = useFormFields(getFormStructure(type, data));

  const getAcceptButtonLabel = () => {
    if (updating) {
      return <SpinnerIcon size="28px" />;
    }
    if (action === PHRASING_ACTION_TYPE.ADD) {
      return ADD_LABEL;
    }
    return UPDATE_LABEL;
  };

  return (
    <Modal style={{ overflow: 'hidden' }} active>
      <Background justifyContent="center" flexDirection="column">
        <Box width="600px">
          {type === PHRASING_FORM_TYPE.HINT ? (
            <HintForm form={form} handleFormChange={handleFormChange} />
          ) : (
            <AnswerForm form={form} handleFormChange={handleFormChange} />
          )}
        </Box>
        <Flex justifyContent="right">
          <Flex ml="auto" width={1 / 2} mt="20px" justifyContent="space-between">
            <Box width={3 / 7}>
              <Button secondary onClick={onCancel}>
                CANCEL
              </Button>
            </Box>
            <Box width={3 / 7}>
              <Button
                onClick={() => onAccept(form, type, itemIdx, action)}
                disabled={!form.validate(form)}
              >
                {getAcceptButtonLabel()}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Background>
    </Modal>
  );
}
