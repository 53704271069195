import React from 'react';

export default function RankIcon(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 512 512">
      <g fill="#d0d0d0">
        <path d="m480.11 482v-135.421h-128.777v135.421h-30v-270.843h-130.666v270.843h-30v-201.489h-131.025v201.489h-29.642v30h512v-30zm-251.076-212.781h49.104v95.144h-30v-65.144h-19.104z" />
        <path d="m241 107.801v41.605h-27.5v30h85v-30h-27.5v-41.605c21.28-6.049 37.346-24.615 39.661-47.207h29.889v-30h-29.6v-30.594h-109.9v30.594h-30.15v30h30.438c2.316 22.592 18.382 41.158 39.662 47.207z" />
      </g>
    </svg>
  );
}
