import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Flex } from 'reflexbox';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Api from '../../api';
import { onError } from '../../libs/error-lib';
import {
  Checkbox,
  RegularText,
  StyledText,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  Paginator,
  TeamIcon,
  RefreshIcon
} from 'boss-ui';
import { useDynamoPaginator, useRefreshScore } from '../../libs/hooks-lib';
import { useAppContext } from '../../libs/context-lib';
import { DEFAULT_PAGE_STEP, NUMBERS } from '../../libs/constants';

const DEFAULT_PAGE_SIZE = 15;
const API = new Api();

const Container = styled(Flex)`
  background: ${(props) => props.theme.color.form.background} 0% 0% no-repeat padding-box;
  width: 948px;
  padding: ${(props) => props.theme.margin.m};
  flex-direction: column;
`;

const ScoreItem = ({ score = {}, idx, teamName }) => {
  const borderStyle = teamName === score.team.name ? '1px solid #66bb6a' : '';
  return (
    <TableRow border={borderStyle}>
      <TableData width="32px">
        <Flex px="6px" justifyContent="space-between">
          <RegularText light fontSize="14px">
            #
          </RegularText>
          <RegularText light fontSize="14px">
            {idx + 1}
          </RegularText>
        </Flex>
      </TableData>
      <TableData>
        <RegularText noWrap light fontSize="14px">
          {score.team.name}
        </RegularText>
      </TableData>
      <TableData>
        <Flex justifyContent="flex-end" mr="6px">
          <RegularText light fontSize="14px">
            {Math.round(score.total)}
          </RegularText>
        </Flex>
      </TableData>
    </TableRow>
  );
};

export default function ScoreBoard({ eventId }) {
  const [loadingScore, setLoadingScore] = useState(true);
  const [autoRefreshGraph, setAutoRefreshGraph] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [refreshHover, setRefreshHover, getLastUpdatedLabel] = useRefreshScore(loadingScore);
  const [{ pages, currentPage }, paginate] = useDynamoPaginator();
  const { user } = useAppContext();
  const teamName = user && user.userInTeam ? user.userInTeam.name : '';
  let intervalRefresh;

  const SKELETON_STYLE = {
    COLOR: useTheme().color.skeleton.color,
    EFFECT: useTheme().color.skeleton.highlightColor,
  };

  const getDashboard = async (page = NUMBERS.ZERO) => {
    let lastEvaluatedKey;
    if (page > NUMBERS.ZERO) {
      lastEvaluatedKey =
        page > currentPage
          ? pages[currentPage].nextPagePointer
          : pages[currentPage].lastPagePointer;
    }
    try {
      setLoadingScore(true);
      const rq = await API.get('events', `/events/${eventId}/dashboard`, {
        queryStringParameters: {
          pageSize: DEFAULT_PAGE_SIZE,
          ...(lastEvaluatedKey && {
            ...lastEvaluatedKey,
          }),
        },
      });
      setDashboardData(rq.teams);
      paginate(rq.pagination, page);
      setLoadingScore(false);
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    getDashboard();
    return () => API.abortCurrentRequest();
  }, []);

  useEffect(() => {
    if (autoRefreshGraph) {
      getDashboard();
      clearInterval(intervalRefresh);
      intervalRefresh = setInterval(getDashboard, 60000);
    }
    return () => clearInterval(intervalRefresh);
  }, [autoRefreshGraph]);

  return (
    <Flex justifyContent="center" mt="50px">
      <Container>
        <Flex justifyContent="center" alignItems="center" width={1} mb="40px">
          <Flex mr="4px">
            <TeamIcon size={40} color="#2c4b2d" />
          </Flex>
          <StyledText size="24px" light>
            All teams score
          </StyledText>
        </Flex>
        <Flex width={1} mb="6px">
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            onClick={() => getDashboard(currentPage)}
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setRefreshHover(true)}
            onMouseLeave={() => setRefreshHover(false)}
          >
            <RefreshIcon rotate={loadingScore} hover={refreshHover} />
            <RegularText fontSize="12px" marginLeft="3px">
              {refreshHover ? (
                <RegularText fontSize="12px" color="#66bb6a">
                  Refresh score
                </RegularText>
              ) : (
                getLastUpdatedLabel()
              )}
            </RegularText>
          </Flex>
          <Flex ml="auto">
            <Flex mr="4px">
              <label>
                <Checkbox
                  checked={autoRefreshGraph}
                  onChange={(e) => setAutoRefreshGraph(e.target.checked)}
                  id="auto-refresh"
                />
              </label>
            </Flex>
            <Flex onClick={() => setAutoRefreshGraph((v) => !v)} style={{ cursor: 'pointer' }}>
              <RegularText fontSize="12px">Auto refresh score</RegularText>
            </Flex>
          </Flex>
        </Flex>
        {loadingScore ? (
          <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
            <Skeleton width="100%" height="30px" />
          </SkeletonTheme>
        ) : (
          <>
            <Table>
              <TableHead backgroundColor="#373737">
                <TableRow>
                  <TableHeader xpadding="6px" height="30px">
                    <RegularText light mediumWeight fontSize="14px">
                      Rank
                    </RegularText>
                  </TableHeader>
                  <TableHeader>
                    <RegularText light mediumWeight fontSize="14px">
                      Team
                    </RegularText>
                  </TableHeader>
                  <TableHeader justify="flex-end">
                    <RegularText light mediumWeight fontSize="14px" textAlign="right">
                      Score
                    </RegularText>
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardData.length ? (
                  dashboardData.map((score, idx) => (
                    <ScoreItem
                      score={score}
                      key={score.team.name}
                      idx={idx + currentPage * DEFAULT_PAGE_SIZE}
                      teamName={teamName}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableData colspan={3}>
                      <Flex width={1} justifyContent="center" p="12px">
                        <RegularText>There is no data yet...</RegularText>
                      </Flex>
                    </TableData>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Flex width={1} justifyContent="center" mt="16px">
              <Paginator
                isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
                onNext={() => getDashboard(currentPage + DEFAULT_PAGE_STEP)}
                isPrev={currentPage > 0}
                onPrev={() => getDashboard(currentPage - DEFAULT_PAGE_STEP)}
                paginating={loadingScore}
              />
            </Flex>
          </>
        )}
      </Container>
    </Flex>
  );
}
