import styled from 'styled-components';

export const Heading2 = styled.h2`
    color: ${props => props.theme.color.text.light.color};
`;
export const Heading3 = styled.h3`
    color: ${props => props.theme.color.text.light.color};
    text-decoration: underline;
`;
export const Heading4 = styled.h4`
    color: ${props => props.theme.color.text.light.color};
`;