import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { Button, Modal, LinkedText, FormLeyend, DragDrop, SpinnerIcon } from 'boss-ui';
import { MAX_BULK_RESOURCES_LINES } from '../../libs/constants';

const Background = styled(Flex)`
  background-color: ${(props) => props.theme.color.form.background};
  padding-top: ${(props) => props.theme.margin.m};
  padding-bottom: ${(props) => props.theme.margin.s};
  padding-left: ${(props) => props.theme.margin.s};
  padding-right: ${(props) => props.theme.margin.s};
  border: 1px solid #81c784;
  flex-direction: column;
`;
Background.displayName = 'ResourcesImportModal-Background';

export default function ResourcesImportModal({
  onImport,
  onCancel,
  onDownloadTemplate,
  loading = false,
}) {
  const [file, setFile] = useState(null);

  const onClickCancel = () => {
    if (loading) {
      return;
    }
    setFile(null);
    onCancel();
  };

  return (
    <Modal style={{ overflow: 'hidden' }} onBackgroundClick={onClickCancel}>
      <Background>
        <FormLeyend>Resource Spec</FormLeyend>
        <Flex mt="20px" mb="10px" flexDirection="column">
          <DragDrop onFileChange={setFile} />
        </Flex>
        <Flex>
          <LinkedText color="#898989" fontSize="14px" onClick={onDownloadTemplate}>
            Use a template as base for your csv file
          </LinkedText>
          <Flex
            style={{ fontWeight: 'normal', fontSize: '14px' }}
            ml="5px"
            color="#E06C75"
            fontSize="14px"
          >
            * max {MAX_BULK_RESOURCES_LINES} lines
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end" mt="40px">
          <Flex width="150px">
            <Button onClick={onClickCancel} secondary disabled={loading}>
              CANCEL
            </Button>
          </Flex>
          <Flex width="150px" ml="20px">
            <Button disabled={!file} onClick={() => onImport(file)}>
              {loading ? <SpinnerIcon size="26px" /> : 'IMPORT FILE'}
            </Button>
          </Flex>
        </Flex>
      </Background>
    </Modal>
  );
}
