import endsWith from 'lodash.endswith';
import get from 'lodash.get';
import { Auth, Signer } from 'aws-amplify';
import { BOTS_TYPE, NUMBERS } from './constants';
import config from './../config';

const DOMAINS = [
  { domain: 'bots', type: BOTS_TYPE.SOC },
  { domain: 'boo', type: BOTS_TYPE.NOC },
  { domain: 'botn', type: BOTS_TYPE.NOC },
  { domain: 'playbots', type: BOTS_TYPE.SOC },
  { domain: 'playbotn', type: BOTS_TYPE.NOC },
  { domain: 'localhost', type: BOTS_TYPE.SOC },
];

export const getAppType = () => {
  let type = BOTS_TYPE.SOC;
  DOMAINS.forEach((d) => {
    if (config.appType.includes(d.domain)) {
      type = d.type;
    }
  });
  return type;
};

export const setUserHeaders = (loggedUser = {}) => {
  try {
    window.user = {
      email: get(loggedUser, 'dynamoUser.email'),
      displayName: get(loggedUser, 'dynamoUser.displayName'),
      userName: loggedUser.username,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Unable to set user headers', e);
  }
};

export const deleteUserHeaders = () => delete window.user;

export const getImageURLSized = (url, size) => {
  switch (size) {
    case 'small': {
      return `https:${url}?fit=fill&w=330&h=186`;
    }
    case 'medium': {
      return `https:${url}?fit=fill&w=550&h=310`;
    }
    case 'large': {
      return `https:${url}?fit=fill&w=766&h=432`;
    }
    default:
      return `https:${url}?fit=fill&w=330`;
  }
};

export const getFaviconEl = () => document.getElementById('favicon');

export function removePlural(string) {
  if (endsWith(string, 's')) {
    return string.substr(NUMBERS.ZERO, string.length - NUMBERS.ONE);
  }
  return string;
}

export function reloadPage() {
  window.location.reload();
}

export function sendWarning(text = '', reloadpage = false) {
  console.warn(text);
  if (reloadpage) {
    setTimeout(() => {
      reloadPage();
    }, 600);
  }
}

export const isValidPattern = (text, pattern) => {
  return pattern.test(text);
};

export function isNumeric(value) {
  return /^\d+$/.test(value);
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export function getHashObjectInURL(location) {
  const lochash = location.hash.substr(1);
  const paramMap = {};
  lochash.split('&').reduce((o, current) => {
    const split = current.split('=');
    o[split[0]] = split[1];
    return o;
  }, paramMap);
  return paramMap;
}

export function scrollToHash(location) {
  const hash = location.hash;
  if (hash) {
    const element = document.getElementById(hash.split('#').join(''));
    if (element) {
      window.scroll({ top: element.offsetTop - 115 });
      return;
    }
  }
  // If element not present, scroll to the top
  window.scrollTo(0, 0);
}

// sign a request using Amplify Auth and Signer
export async function signRequest(params) {
  const essentialCredentials = Auth.essentialCredentials(await Auth.currentCredentials());

  const credentials = {
    secret_key: essentialCredentials.secretAccessKey,
    access_key: essentialCredentials.accessKeyId,
    session_token: essentialCredentials.sessionToken,
  };
  // Signer.signUrl returns a Signature V4 signed URL
  return Signer.signUrl(params, credentials);
}

export function formDataBody(json) {
  const props = Object.keys(json);
  return props
    .map((prop) => `${encodeURIComponent(prop)}=${encodeURIComponent(json[prop])}`)
    .join('&');
}
/**
 * Use this function in order to be able to mock the Date() constructor it for testing
 * @param  {...any} args
 * @returns Date: new Date(args)
 */
export function getNewDate(...args) {
  return new Date(...args);
}

export const promiseToObject = (promise) => {
  return promise
    .then((result) => ({ success: true, result }))
    .catch((error) => ({ success: false, error }));
};

export const setSessionStorageItem = (eventId, data) => {
  sessionStorage.setItem(`data2Import${eventId}`, JSON.stringify(data));
};

export const removeSessionStorageItem = (eventId) => {
  sessionStorage.removeItem(`data2Import${eventId}`);
};

export const getSessionStorageItem = (eventId) => {
  return sessionStorage.getItem(`data2Import${eventId}`);
};

export const replaceAll = (originalText, findText = '', replaceText = '') => {
  return originalText.replace(new RegExp(findText, 'g'), replaceText);
};
