import React from 'react';
import { Flex, Box } from 'reflexbox';
import { FormLabel, Tooltip, OpenIcon, CloseIcon, SwitchButton, QuestionCircleIcon } from 'boss-ui';

export default function AccessibilityToggle(props) {
  const { fieldValue, handleFieldChange } = props;
  return (
    <Box style={{ position: 'relative' }}>
      <FormLabel>Team Access</FormLabel>
      <SwitchButton
        checked={fieldValue}
        handleToggle={handleFieldChange}
        id="accessibility"
        onIcon={<OpenIcon />}
        offIcon={<CloseIcon />}
        onText="Open"
        offText="Closed"
      />
      <Flex
        data-tip
        data-for="open_question"
        style={{
          position: 'absolute',
          right: '-30px',
          top: '26px',
        }}
      >
        <QuestionCircleIcon />
      </Flex>
      <Tooltip id="open_question" place="right">
        Open: Allow others to join the team without approval.
        <br />Closed: They must invite others to join their team.
      </Tooltip>
    </Box>
  );
}
