import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import cloneDeep from 'lodash.clonedeep';
import { RegularText, SpinnerIcon, TrashCanIcon } from 'boss-ui';
import EditableComponent from '../../EditableComponent';
import PhrasingItemModal from './PhrasingItemModal';
import {
  PHRASING_FORM_TYPE,
  PHRASING_ACTION_TYPE,
  makeQuestionPayload,
  phrasingItemCRUD,
} from '../utils';
import Api from '../../../api';
import { INPUT_FORM_TYPE_TEXTAREA } from '../../../libs/constants';
import { useScenarioContext } from '../../../libs/context-lib';
import { onError } from '../../../libs/error-lib';
import QuestionHintsTable from '../QuestionHintsTable';
import QuestionAnswersTable from '../QuestionAnswersTable';
import { SCENARIO_QUESTION_ACTIONS } from '../../../libs/reducerAction-lib';

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.color.questionPhrasing.background};
  box-shadow: 0px 12px 16px #0000004d;
  width: 100%;
  padding: ${(props) => props.theme.margin.s};
  border-color: ${(props) => props.theme.color.questionPhrasing.border};
`;
Container.displayName = 'AdminQuestionPhrasing-Container';

const DEFAULT_PHRASING_OBJ = { visible: false };
const API = new Api();

export default function QuestionPhrasing({
  phrasing,
  phrasingIdx,
  isImportationView = false,
  error = {},
}) {
  const [phrasingModalObject, setPhrasingModalObject] = useState(DEFAULT_PHRASING_OBJ);
  const [updatingQuestion, setUpdatingQuestion] = useState(false);
  const { state, dispatch } = useScenarioContext();

  const onFormPhrasingActive = (type, action, data, idx) => {
    setPhrasingModalObject({ visible: true, type, action, data, idx });
  };

  const submitQuestionChanges = async (tmpQuestion) => {
    setUpdatingQuestion(true);
    try {
      const updatedQuestion = await API.put(
        'events',
        `/admin/scenarios/${state.scenario.scenarioId}/questions/${state.openedQuestion.question_id}`,
        {
          body: tmpQuestion,
        }
      );
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.UPDATE_OPENED_QUESTION,
        data: tmpQuestion,
      });
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.UPDATE_QUESTIONS_LIST,
        data: updatedQuestion,
      });
    } catch (e) {
      onError(e);
    } finally {
      setUpdatingQuestion(false);
    }
  };

  const updatePhrasingOnImport = (key, value) => {
    const tmpQuestion = cloneDeep(state.openedQuestion);
    tmpQuestion.phrasings[phrasingIdx][key] = value;
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.UPDATE_OPENED_QUESTION,
      data: tmpQuestion,
    });
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.UPDATE_QUESTIONS_LIST,
      data: { question: tmpQuestion, questionId: tmpQuestion.question_id },
    });
    if (error && error.questionLine === state.openedQuestion.line) {
      dispatch({ type: SCENARIO_QUESTION_ACTIONS.CLEAR_QUESTION_IMPORTATION_ERROR });
    }
  };

  const updatePhrasingOnEdition = async (key, value) => {
    const tmpQuestion = cloneDeep(state.openedQuestion);
    tmpQuestion.phrasings[phrasingIdx][key] = value;
    await submitQuestionChanges(tmpQuestion);
  };

  const onUpdatePhrasing = async (...args) => {
    if (isImportationView) {
      updatePhrasingOnImport(...args);
    } else {
      await updatePhrasingOnEdition(...args);
    }
  };

  const changePhrasingItemsOnImport = (form, type, itemIdx, action) => {
    const tmpQuestion = cloneDeep(state.openedQuestion);
    tmpQuestion.phrasings[phrasingIdx] = phrasingItemCRUD(
      action,
      tmpQuestion.phrasings[phrasingIdx],
      type,
      itemIdx,
      form
    );
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.UPDATE_OPENED_QUESTION,
      data: tmpQuestion,
    });
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.UPDATE_QUESTIONS_LIST,
      data: { question: tmpQuestion, questionId: tmpQuestion.question_id },
    });
    if (error && error.questionLine === state.openedQuestion.line) {
      dispatch({ type: SCENARIO_QUESTION_ACTIONS.CLEAR_QUESTION_IMPORTATION_ERROR });
    }
    setPhrasingModalObject(DEFAULT_PHRASING_OBJ);
  };

  const changePhrasingItemsOnEdition = async (form, type, itemIdx, action) => {
    const tmpQuestion = cloneDeep(state.openedQuestion);
    tmpQuestion.phrasings[phrasingIdx] = phrasingItemCRUD(
      action,
      tmpQuestion.phrasings[phrasingIdx],
      type,
      itemIdx,
      form
    );
    await submitQuestionChanges(makeQuestionPayload(tmpQuestion));
  };

  const onChangePhrasingItems = async (...args) => {
    if (isImportationView) {
      changePhrasingItemsOnImport(...args);
    } else {
      await changePhrasingItemsOnEdition(...args);
    }
  };

  const onRemovePhrasingItem = async (type, idx) => {
    if (isImportationView) {
      changePhrasingItemsOnImport(null, type, idx, PHRASING_ACTION_TYPE.REMOVE);
    } else {
      await changePhrasingItemsOnEdition(null, type, idx, PHRASING_ACTION_TYPE.REMOVE);
    }
  };

  const onRemovePhrasingOnEdition = async () => {
    const tmpQuestion = cloneDeep(state.openedQuestion);
    try {
      tmpQuestion.phrasings = tmpQuestion.phrasings.filter(
        (p) => p.phrasing_id.toString() !== phrasing.phrasing_id.toString()
      );
    } catch (e) {
      onError(e);
    }
    await submitQuestionChanges(tmpQuestion);
  };

  const onRemovePhrasingOnImport = () => {
    const tmpQuestion = cloneDeep(state.openedQuestion);
    tmpQuestion.phrasings = tmpQuestion.phrasings.filter(
      (p) => p.phrasing_id.toString() !== phrasing.phrasing_id.toString()
    );
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.UPDATE_QUESTIONS_LIST,
      data: { question: tmpQuestion, questionId: tmpQuestion.question_id },
    });
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.UPDATE_OPENED_QUESTION,
      data: tmpQuestion,
    });
    if (error && error.questionLine === state.openedQuestion.line) {
      dispatch({ type: SCENARIO_QUESTION_ACTIONS.CLEAR_QUESTION_IMPORTATION_ERROR });
    }
  };

  const onRemovePhrasing = async () => {
    if (isImportationView) {
      onRemovePhrasingOnImport();
    } else {
      await onRemovePhrasingOnEdition();
    }
  };
  return (
    <Container>
      <Flex flexDirection="column" width="100%">
        <Box key={phrasing.phrasing_id} mt="10px">
          <Flex>
            <RegularText fontSize="18px" mediumWeight>
              Question:
            </RegularText>
            <Flex
              ml="auto"
              className="QuestionPhrasing-RemoveButton"
              onClick={onRemovePhrasing}
              style={{ cursor: 'pointer' }}
            >
              {updatingQuestion ? <SpinnerIcon size="20px" /> : <TrashCanIcon size="28px" />}
            </Flex>
          </Flex>
          <EditableComponent
            initialValue={phrasing.question_phrasing}
            onSaveChanges={(value) => onUpdatePhrasing('question_phrasing', value)}
            editInputStyle={{ height: '100px' }}
            editableType={INPUT_FORM_TYPE_TEXTAREA}
            placeholder="Enter question here"
          >
            <RegularText fontSize="18px" mediumWeight align="left">
              {phrasing.question_phrasing}
            </RegularText>
          </EditableComponent>
          <Flex mt="6px">
            <RegularText color="#81A2BE" align="left">
              Answer guidance:
            </RegularText>
          </Flex>
          <EditableComponent
            initialValue={phrasing.answer_guidance}
            onSaveChanges={(value) => onUpdatePhrasing('answer_guidance', value)}
            placeholder="Enter answer guidance here"
          >
            <RegularText color="#81A2BE" align="left" style={{ wordBreak: 'break-all' }}>
              {phrasing.answer_guidance}
            </RegularText>
          </EditableComponent>
        </Box>
        <Flex mt="40px" flexDirection="column">
          <Flex mb="4px">
            <RegularText mediumWeight fontSize="14px">
              Hints
            </RegularText>
          </Flex>
          <Flex width={1}>
            <QuestionHintsTable
              hints={phrasing.hints}
              errorPath={error.path}
              onAddItem={() =>
                onFormPhrasingActive(PHRASING_FORM_TYPE.HINT, PHRASING_ACTION_TYPE.ADD)
              }
              onEditItem={(data, idx) =>
                onFormPhrasingActive(
                  PHRASING_FORM_TYPE.HINT,
                  PHRASING_ACTION_TYPE.UPDATE,
                  data,
                  idx
                )
              }
              onRemoveItem={(idx) => onRemovePhrasingItem(PHRASING_FORM_TYPE.HINT, idx)}
            />
          </Flex>
          <Flex mt="15px" flexDirection="column">
            <Flex mb="4px">
              <RegularText mediumWeight fontSize="14px">
                Answers
              </RegularText>
            </Flex>
            <Flex width={1}>
              <QuestionAnswersTable
                answers={phrasing.answers}
                errorPath={error.path}
                onAddItem={() =>
                  onFormPhrasingActive(PHRASING_FORM_TYPE.ANSWER, PHRASING_ACTION_TYPE.ADD)
                }
                onEditItem={(data, idx) =>
                  onFormPhrasingActive(
                    PHRASING_FORM_TYPE.ANSWER,
                    PHRASING_ACTION_TYPE.UPDATE,
                    data,
                    idx
                  )
                }
                onRemoveItem={(idx) => onRemovePhrasingItem(PHRASING_FORM_TYPE.ANSWER, idx)}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {phrasingModalObject.visible && (
        <PhrasingItemModal
          type={phrasingModalObject.type}
          action={phrasingModalObject.action}
          data={phrasingModalObject.data}
          itemIdx={phrasingModalObject.idx}
          onAccept={onChangePhrasingItems}
          onCancel={() => setPhrasingModalObject(DEFAULT_PHRASING_OBJ)}
          updating={updatingQuestion}
        />
      )}
    </Container>
  );
}
