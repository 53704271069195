import React, { useState, useMemo } from 'react';
import { Flex, Box } from 'reflexbox';
import { LinkedText, SpinnerIcon, FloatingPanel } from 'boss-ui';
import Api from '../../api';
import { onError } from '../../libs/error-lib';
import { useEventContext } from '../../libs/context-lib';
import { EVENT_DELIVERY } from '../../libs/constants';
import { RankIcon } from '../icons';

const API = new Api();

export default function RankingCalculator() {
  const { state } = useEventContext();
  const [calculatingRank, setCalculatingRank] = useState(false);
  const isOnDemandEvent = useMemo(
    () => state.event.delivery === EVENT_DELIVERY.ON_DEMAND,
    [state.event.delivery]
  );

  const onCalculateRankClick = async () => {
    if (calculatingRank) {
      return;
    }
    setCalculatingRank(true);
    try {
      await API.post('events', `/admin/events/${state.event.eventId}/calculate-ranking`);
    } catch (e) {
      onError(e);
    } finally {
      setCalculatingRank(false);
    }
  };

  if ((!state.event.isEventStarted && !isOnDemandEvent) || state.event.isEventRunning) {
    return null;
  }

  return (
    <FloatingPanel>
      <Box style={{ position: 'fixed', top: '203px', zIndex: '100' }}>
        <Flex
          width="210px"
          p="8px"
          alignItems="center"
          style={{
            border: '1px solid #1d1d1d',
            boxShadow: '-3px 3px 3px #00000029',
            backgroundColor: '#121212',
          }}
        >
          <LinkedText
            fontSize="14px"
            font="Roboto Mono"
            color="#A0A0A0"
            hoverColor="#9edba1"
            onClick={onCalculateRankClick}
            disabled={calculatingRank}
          >
            Calculate ranks
          </LinkedText>
          {calculatingRank ? (
            <Flex ml="8px">
              <SpinnerIcon size="24" />
            </Flex>
          ) : (
            <Flex ml="8px">
              <RankIcon />
            </Flex>
          )}
        </Flex>
      </Box>
    </FloatingPanel>
  );
}
