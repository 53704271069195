import React from 'react';
import styled from 'styled-components';
import svgBase from './StyledSVGBase';

const SIZE = '24px';

const AddSVG = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: #a0a0a0;
  &:hover {
    fill: #d0d0d0;
  }
`;
AddSVG.displayName = 'AddSquareIcon-AddSVG';

export default function AddSquareIcon(props) {
  return (
    <AddSVG viewBox="0 0 16 16" {...props}>
      <path d="M12 7h-3v-3h-2v3h-3v2h3v3h2v-3h3z" />
      <path d="M15 1h-14v14h14v-14zM14 14h-12v-12h12v12z" />
    </AddSVG>
  );
}
