import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import { Flex } from 'reflexbox';
import { AuthenticatedRoute, Menu } from 'boss-ui';
import Api from '../../api';
import Teams from '../Teams';
import Participants from '../Participants';
import Resources from '../Resources';
import Invites from '../Invites';
import Scoring from '../Scoring';
import Exports from '../Exports';
import EventScenarios from './EventScenarios';
import { useAppContext, useEventContext } from '../../libs/context-lib';
import { APP_ACTIONS, EVENT_ACTION } from '../../libs/reducerAction-lib';
import { onError } from '../../libs/error-lib';
import { EVENT_ACCESS_TYPE, EVENT_DELIVERY, NUMBERS, USER_ROLES } from '../../libs/constants';

const API = new Api();

const getMenuByAccessType = (event, user) => {
  const menuScenarios = {
    path: `/events/${event.eventId}/scenarios`,
    id: 'menu-scenarios',
    label: 'scenarios',
  };
  const menuTeams = { path: `/events/${event.eventId}/teams`, id: 'menu-teams', label: 'teams' };
  const menuParticipants = {
    path: `/events/${event.eventId}/participants`,
    id: 'menu-participants',
    label: 'participants',
  };
  const menuResources = {
    path: `/events/${event.eventId}/resources`,
    id: 'menu-resources',
    label: 'resources',
  };
  const menuInvites = {
    path: `/events/${event.eventId}/invites`,
    id: 'menu-invites',
    label: 'invites',
  };
  const menuScoring = {
    path: `/events/${event.eventId}/scoring`,
    id: 'menu-scoring',
    label: 'scoring',
  };
  const menuExports = {
    path: `/events/${event.eventId}/exports`,
    id: 'menu-exports',
    label: 'exports',
  };

  let menus = [menuTeams, menuParticipants, menuScoring, menuExports];

  if (event.accessType === EVENT_ACCESS_TYPE.RESTRICTED) {
    menus = [
      menuTeams,
      menuParticipants,
      menuInvites,
      menuScoring,
      menuExports,
    ];
  }

  if (event.delivery === EVENT_DELIVERY.ON_DEMAND) {
    menus = [menuExports];
  }

  if (user?.role === USER_ROLES.SUPERADMIN) {
    menus.splice(NUMBERS.ZERO, NUMBERS.ZERO, menuResources);
    menus.splice(NUMBERS.ZERO, NUMBERS.ZERO, menuScenarios);
  }

  return menus;
};

const getRedirectUrl = (event, user) => {
  let url = `/events/${event.eventId}/teams`;

  if (user === USER_ROLES.SUPERADMIN) {
    url = `/events/${event.eventId}/scenarios`;
  }

  return url;
};

export default function Event() {
  const appDispatch = useAppContext().dispatch;
  const appState = useAppContext().state;
  const { state, dispatch } = useEventContext();
  const history = useHistory();
  const route = useRouteMatch('/events/:eventId');
  const routeScenario = useRouteMatch('/events/:eventId/scenarios/:scenarioId');
  const [eventLoading, setEventLoading] = useState(false);
  const eventId = route ? route.params.eventId : '';
  const scenarioId =
    routeScenario && routeScenario.params.scenarioId ? routeScenario.params.scenarioId : null;

  useEffect(() => {
    const getEventById = async (paramId) => {
      setEventLoading(true);
      try {
        const rq = await API.get('events', `/admin/events/${paramId}`);
        if (rq.event) {
          dispatch({ type: EVENT_ACTION.SET_EVENT, data: rq.event });
        }
      } catch (err) {
        onError(err);
        history.push('/events');
      } finally {
        setEventLoading(false);
      }
    };
    getEventById(eventId);
  }, [eventId, dispatch]);

  useEffect(() => {
    const routes = [
      { path: `/events`, name: 'Events' },
      { path: `/events/${eventId}`, name: state.event.name },
    ];
    if (scenarioId) {
      const scenario = state.event.scenarios
        ? state.event.scenarios.find((s) => s.scenarioId === scenarioId)
        : null;
      routes.push({ path: `/events/${eventId}/scenarios`, name: 'Scenarios' });
      if (scenario) {
        routes.push({ path: `/events/${eventId}/scenarios/${scenarioId}`, name: scenario.title });
      }
    }
    if (state.event.delivery === EVENT_DELIVERY.ON_DEMAND) {
      routes.push({ path: `/events/${eventId}/exports`, name: 'Exports' });
    }
    appDispatch({
      type: APP_ACTIONS.SET_ROUTES,
      data: routes,
    });
  }, [scenarioId, state.event, eventId, appDispatch]);

  const filteredEventMenuItems = getMenuByAccessType(state.event, appState.user);

  const invitesMenuItem = [
    { path: `/events/${eventId}/invites/direct`, id: 'menu-invites-direct', label: 'Direct' },
    { path: `/events/${eventId}/invites/link`, id: 'menu-invites-link', label: 'Link' },
  ];

  const scoringMenuItem = [
    { path: `/events/${eventId}/scoring/top10`, id: 'menu-scoring-top10', label: 'Top 10 teams' },
    {
      path: `/events/${eventId}/scoring/allTeam`,
      id: 'menu-scoring-allTeam',
      label: 'All teams score',
    },
    {
      path: `/events/${eventId}/scoring/timeLine`,
      id: 'menu-scoring-timeLine',
      label: 'Score timeline',
    },
  ];

  let scenariosMenuItem = [];

  if (routeScenario) {
    const scenarioId = routeScenario.params.scenarioId;
    scenariosMenuItem = [
      {
        path: `/events/${eventId}/scenarios/${scenarioId}/questions`,
        id: 'menu-scenario-questions',
        label: 'questions',
      },
      {
        path: `/events/${eventId}/scenarios/${scenarioId}/import`,
        id: 'menu-event-scenario-import',
        label: 'import',
      },
    ];
  } else {
    scenariosMenuItem = filteredEventMenuItems;
  }

  const validateSuperadmin = () => appState.user?.role === USER_ROLES.SUPERADMIN;

  return (
    <Switch>
      <Route path="/events/:eventId" exact>
        <Menu menuItems={filteredEventMenuItems} />
        <Redirect to={getRedirectUrl(state.event, appState.user?.role)} />
      </Route>
      <Route path="/events/:eventId/teams">
        <Menu menuItems={filteredEventMenuItems} />
        <Teams />
      </Route>
      <AuthenticatedRoute
        path="/events/:eventId/scenarios"
        validateSession={validateSuperadmin}
        fallbackRoute={`/events/${eventId}/teams`}
        skipRedirect
      >
        <Menu menuItems={scenariosMenuItem} />
        <EventScenarios loading={eventLoading} />
      </AuthenticatedRoute>
      <Route path="/events/:eventId/participants">
        <Menu menuItems={filteredEventMenuItems} />
        <Participants />
      </Route>
      <AuthenticatedRoute
        path="/events/:eventId/resources"
        exact
        validateSession={validateSuperadmin}
        fallbackRoute={`/events/${eventId}/teams`}
        skipRedirect
      >
        <Menu menuItems={filteredEventMenuItems} />
        <Flex width={1} justifyContent="center">
          <Flex mb="5%" flexDirection="column" mt="60px">
            <Resources />
          </Flex>
        </Flex>
      </AuthenticatedRoute>
      <Route path="/events/:eventId/invites">
        <Menu menuItems={invitesMenuItem} />
        <Invites />
      </Route>
      <Route path="/events/:eventId/scoring">
        <Menu menuItems={scoringMenuItem} />
        <Scoring />
      </Route>
      <Route path="/events/:eventId/exports">
        <Menu menuItems={filteredEventMenuItems} />
        <Exports />
      </Route>
    </Switch>
  );
}