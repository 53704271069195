import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableData, TableHead, TableHeader, TableItemSkeleton, TableRow } from 'boss-ui';
import styled from 'styled-components';

import { toast } from 'react-toastify';
import { SimpleBinPackFeatureFlagForm } from './SimpleBinPackFeatureFlagForm';
import { Heading2 } from './headings';
import { submitFeatureFlagChanges, useGetSimpleBinPackFeatureFlag, useListAllEvents } from './ApiClient';

const Container = styled.div`
    display: flex;
    min-width: 800px;
`;

const nMonthsAgo = (n) => {
  const date = new Date();
  date.setMonth(date.getMonth() - n);
  return date;
};

const FeatureFlagsForm = () => {
  const { loading: loadingFeatureFlag, flagConfig } = useGetSimpleBinPackFeatureFlag();
  const [formInitialized, setFormInitialized] = useState(false);
  const [globallyEnabled, setGloballyEnabled] = useState(false);
  const [enabledEventIds, setEnabledEventIds] = useState([]);
  const { loading: loadingEvent, events: eventsFromApi } = useListAllEvents();
  const loading = loadingFeatureFlag || loadingEvent;
  const enabledEvents = useMemo(() => eventsFromApi.filter(event => enabledEventIds.includes(event.eventId)), [enabledEventIds, eventsFromApi]);

  const threeMonthsAgo = nMonthsAgo(3);
  const filterRecentOrFutureEvents = (event) => new Date(event.endDate) >= threeMonthsAgo;

  const eventsToChooseFrom = useMemo(
    () => eventsFromApi.filter((event) => !enabledEventIds.includes(event.eventId) && filterRecentOrFutureEvents(event)),
    [enabledEventIds, eventsFromApi]
  );
  const removeEvent = (eventId) => {
    setEnabledEventIds(enabledEventIds.filter((id) => id !== eventId));
  };
  const addEvent = (eventId) => {
    setEnabledEventIds([...enabledEventIds, eventId]);
  };

  useEffect(() => {
    if (!formInitialized && flagConfig) {
      console.log('Initializing form with flag config', JSON.stringify(flagConfig));
      setGloballyEnabled(flagConfig?.isGloballyEnabled?.enabled || false);
      setEnabledEventIds(flagConfig?.enabledGroupIds || []);
      setFormInitialized(true);
    }
  }, [formInitialized, flagConfig]);

  useEffect(() => {
    console.log('Form state - enabled events:', enabledEventIds);
    console.log('Form state - globally enabled:', globallyEnabled);
  }, [enabledEventIds, globallyEnabled]);

  const [savingChanges, setSavingChanges] = useState(false);
  const saveChanges = async () => {
    setSavingChanges(true);
    const payload = {
      isGloballyEnabled: {
        enabled: globallyEnabled
      },
      enabledGroupIds: enabledEventIds
    };
    console.log('Saving changes', JSON.stringify(payload));
    await submitFeatureFlagChanges(payload).then((resp) => {
      console.log('Changes saved. Response:', resp);
      setSavingChanges(false);
      toast('Changes saved successfully');
    });
  };

  return (
    <Container>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>
              <Heading2>Feature Flag - Resource Allocation v2</Heading2>
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && <TableItemSkeleton numberOfCells={1} height={'300px'} />}
          {!loading && flagConfig &&
            <TableRow>
              <TableData>
                <SimpleBinPackFeatureFlagForm eventsToChooseFrom={eventsToChooseFrom}
                                              globallyEnabled={globallyEnabled}
                                              setGloballyEnabled={setGloballyEnabled}
                                              enabledEvents={enabledEvents}
                                              addEvent={addEvent}
                                              removeEvent={removeEvent}
                                              savingChanges={savingChanges}
                                              submitForm={saveChanges} />
              </TableData>
            </TableRow>
          }
        </TableBody>
      </Table>
    </Container>
  );
};

export default FeatureFlagsForm;