import React from 'react';
import { Flex } from 'reflexbox';
import { useTheme } from 'styled-components';
import {
  RegularText,
  UnregularButton,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableData,
  PencilIcon,
  TrashCanIcon,
} from 'boss-ui';
import { getIndexError, PATH_ERROR_TYPE } from './utils';

export default function AdminAnswersTable({
  answers = [],
  onAddItem = () => {},
  onRemoveItem = () => {},
  onEditItem = () => {},
  errorPath = null,
}) {
  const errorIdx = getIndexError(errorPath, PATH_ERROR_TYPE.ANSWERS);
  const theme = useTheme();

  return (
    <Table backgroundColor={theme.color.questionAnswerTable.background}>
      <TableHead>
        <TableRow border={`1px solid ${theme.color.box.border.color}`}>
          <TableHeader width="5%" justify="center">
            #
          </TableHeader>
          <TableHeader width="30%">Match type</TableHeader>
          <TableHeader>Answer</TableHeader>
          <TableHeader width="5%" justify="center">
            <UnregularButton active onClick={onAddItem}>
              +
            </UnregularButton>
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {answers.map((answer, idx) => {
          return (
            <TableRow
              key={answer.answer}
              border={`1px solid ${theme.color.box.border.color}`}
              borderIncorrect={idx === errorIdx}
            >
              <TableData>
                <RegularText color="#E06C75" fontSize="14px" justifyContent="center">
                  {idx}
                </RegularText>
              </TableData>
              <TableData>
                <RegularText color="#E06C75" fontSize="14px">
                  {answer.match_type}
                </RegularText>
              </TableData>
              <TableData>
                <RegularText fontSize="14px" color="#E5C07B" style={{ wordBreak: 'break-all' }}>
                  {answer.answer}
                </RegularText>
              </TableData>
              <TableData>
                <Flex alignItems="center" justifyContent="center" py="10px">
                  <PencilIcon size="16px" onClick={() => onEditItem(answer, idx)} />
                  <Flex ml="10px" onClick={() => onRemoveItem(idx)} style={{ cursor: 'pointer' }}>
                    <TrashCanIcon size="22px" />
                  </Flex>
                </Flex>
              </TableData>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
