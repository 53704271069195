import React, { useState, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Flex, Box } from 'reflexbox';
import isempty from 'lodash.isempty';
import debounce from 'lodash.debounce';
import { useTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button, Form, FormLeyend, Checkbox, RegularText } from 'boss-ui';
import Api from '../../api';
import { useFormFields } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';
import { getUserFieldsChanges } from '../../libs/user-lib';
import { USER_ROLES } from '../../libs/constants';
import { useAppContext } from '../../libs/context-lib';
import UserNameBadge from './UserNameBadge';

const ButtonSet = (props) => {
  const { onSaveChanges, disableSaveChanges } = props;
  return (
    <Flex mt="100px" flexDirection="row" justifyContent="flex-end" width={1}>
      <Flex>
        <Button
          style={{ marginLeft: '10px' }}
          onClick={onSaveChanges}
          disabled={disableSaveChanges}
        >
          SAVE CHANGES
        </Button>
      </Flex>
    </Flex>
  );
};

const API = new Api();

export default function User({ user }) {
  const routeMatch = useRouteMatch('/users/:userId');
  const userId = routeMatch.params.userId;
  const { dispatch, APP_ACTIONS } = useAppContext();
  const [baseUser, setBaseUser] = useState({ ...user, name: user.displayName });
  const [email, setEmail] = useState(user.email);
  const [userDisplayName, setUserDisplayName] = useState(user.displayName);
  const [searchLoading, setSearchLoading] = useState(false);
  const [dirtyForm, setDirtyForm] = useState(false);
  const [validDisplayName, setValidDisplayName] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    name: user.displayName,
    role: user.role,
  });
  const emailColor = useTheme().color.user.container.email.color;
  const activeUser = useAppContext().state.user;
  const isSuperAdmin = activeUser.dynamoUser?.role === USER_ROLES.SUPERADMIN;
  const isActiveUser = activeUser.dynamoUser?.userId === userId;

  const SKELETON_STYLE = {
    COLOR: useTheme().color.skeleton.color,
    EFFECT: useTheme().color.skeleton.highlightColor,
  };

  const handleNameReset = () => {
    setValidDisplayName(true);
    handleFieldChange({ target: { value: userDisplayName, id: 'name' } });
  };

  const checkDisplayName = async (name) => {
    try {
      const data = await API.get('users', '/users/check-displayname', {
        queryStringParameters: {
          q: name,
        },
      });
      setValidDisplayName(data.user.available);
    } catch (e) {
      onError(e);
      setValidDisplayName(false);
    } finally {
      setSearchLoading(false);
    }
  };

  const delayedSearch = useCallback(
    debounce((q) => checkDisplayName(q), 300),
    []
  );

  const onNameChange = (e) => {
    handleFieldChange(e);
    if (e.target.value.length > 2) {
      setSearchLoading(true);
      setDirtyForm(true);
      delayedSearch(e.target.value);
    }
  };

  const onSaveChanges = async (e) => {
    e.preventDefault();
    const changes = getUserFieldsChanges(baseUser, fields);
    if (!isempty(changes)) {
      setLoading(true);
      try {
        await API.patch('events', `/admin/users/${userId}`, {
          body: {
            ...changes,
          },
        });
        setBaseUser({ ...baseUser, role: fields.role, name: fields.name });
      } catch (err) {
        onError(err);
      } finally {
        setLoading(false);
      }
    } else {
      setDirtyForm(false);
    }
  };

  const onCheckBoxChange = (e) => {
    setDirtyForm(true);
    handleFieldChange({
      target: {
        value: e.target.checked ? USER_ROLES.SUPERADMIN : USER_ROLES.MEMBER,
        id: e.target.id,
      },
    });
  };

  useEffect(() => {
    const getUserById = async (userId) => {
      setLoading(true);
      try {
        const rq = await API.get('events', `/admin/users/${userId}`);
        if (rq.user) {
          setEmail(rq.user.email);
          setUserDisplayName(rq.user.displayName);
          setBaseUser({ ...rq.user, name: rq.user.displayName });
          // update preloaded user
          handleFieldChange({
            target: { value: rq.user.displayName, id: 'name' },
          });
          handleFieldChange({
            target: {
              value: rq.user.role,
              id: 'role',
            },
          });
        }
      } catch (err) {
        onError(err);
      } finally {
        setLoading(false);
      }
    };
    // we might want some other way to do this
    if (isempty(user.userId)) {
      getUserById(userId);
    } else {
      setLoading();
    }
  }, []);

  useEffect(() => {
    dispatch({ type: APP_ACTIONS.SET_ROUTES, data: [] });
  }, [baseUser]);

  const disableSaveChanges = !dirtyForm || searchLoading || loading || !validDisplayName;

  return (
    <Flex justifyContent="center" align="center">
      <Form noBorder refreshing={loading}>
        <Box px="60px" py="40px" width="924px" className="User-Container">
          <UserNameBadge
            editionAllowed
            name={fields.name}
            validating={searchLoading}
            available={validDisplayName}
            onNameChange={onNameChange}
            onNameReset={handleNameReset}
            onNameChanged={() => setUserDisplayName(fields.name)}
          />
          <RegularText fontSize="16px" color={emailColor} ml="136px" mt="-5px">
            {loading ? (
              <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
                <Skeleton width={350} />
              </SkeletonTheme>
            ) : (
              <Flex>{email}</Flex>
            )}
          </RegularText>
          <Flex mt="22px" width={1}>
            <Flex mr="4px">
              {loading ? (
                <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
                  <Skeleton circle width="15px" height="15px" />
                </SkeletonTheme>
              ) : (
                <label>
                  <Checkbox
                    checked={fields.role === USER_ROLES.SUPERADMIN}
                    onChange={onCheckBoxChange}
                    id="role"
                    disabled={!isSuperAdmin || isActiveUser}
                  />
                </label>
              )}
            </Flex>
            <FormLeyend size="12px">Super admin privileges</FormLeyend>
          </Flex>
          <ButtonSet onSaveChanges={onSaveChanges} disableSaveChanges={disableSaveChanges} />
        </Box>
      </Form>
    </Flex>
  );
}
