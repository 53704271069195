import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import { Button, Modal, FormLabel, SpinnerIcon } from 'boss-ui';
import { useFormFields } from '../../libs/hooks-lib';
import FormInput from '../FormInput';
import {
  RESOURCE_URL_REGEXP,
  RESOURCE_TYPE,
  RESOURCE_TEXT_REGEXP,
  RESOURCE_PASSWORD_REGEXP,
} from '../../libs/constants';

const Background = styled(Flex)`
  background-color: ${(props) => props.theme.color.form.background};
  padding: ${(props) => props.theme.margin.m};
`;

const getCreateLabel = (type) => {
  switch (type) {
    case RESOURCE_TYPE.CREDENTIALS: {
      return 'User & password';
    }
    case RESOURCE_TYPE.SERVER: {
      return 'Server url';
    }
    case RESOURCE_TYPE.URL: {
      return 'Resource url';
    }
    default:
      return 'Server url';
  }
};

const CredentialForm = (props) => {
  const { form, handleFormChange } = props;
  return (
    <Flex>
      <Box width={1 / 2}>
        <FormLabel>Username</FormLabel>
        <FormInput
          autoFocus
          id="username"
          value={form.username}
          onChange={handleFormChange}
          placeholder="Enter username"
          pattern={RESOURCE_TEXT_REGEXP}
          invalidPatternMessage="At least 3 alphanumeric characters, no spaces"
          validate
          available
        />
      </Box>
      <Box ml="15px" width={1 / 2}>
        <FormLabel>Password</FormLabel>
        <FormInput
          id="password"
          value={form.password}
          onChange={handleFormChange}
          pattern={RESOURCE_PASSWORD_REGEXP}
          invalidPatternMessage="At least 3 alphanumeric characters, no spaces, symbols allowed: -!@#$%^&.*"
          validate
          available
          placeholder="Enter password"
        />
      </Box>
    </Flex>
  );
};

const RegularResourceForm = (props) => {
  const { form, handleFormChange, formLabel } = props;
  return (
    <Flex>
      <Box width={1}>
        <FormLabel>{formLabel}</FormLabel>
        <FormInput
          autoFocus
          id="url"
          value={form.url}
          onChange={handleFormChange}
          placeholder="Enter server url"
          pattern={RESOURCE_URL_REGEXP}
          invalidPatternMessage="Enter a url format"
          validate
          available
        />
      </Box>
    </Flex>
  );
};

const getFormStructure = (resourceType) => {
  switch (resourceType) {
    case RESOURCE_TYPE.CREDENTIALS:
      return {
        username: '',
        password: '',
        validate: (form) =>
          form.username.toString().match(RESOURCE_TEXT_REGEXP) &&
          form.password.toString().match(RESOURCE_PASSWORD_REGEXP),
      };
    case RESOURCE_TYPE.SERVER:
      return {
        url: '',
        validate: (form) => form.url.match(RESOURCE_URL_REGEXP),
      };
    case RESOURCE_TYPE.URL:
      return {
        url: '',
        validate: (form) => form.url.match(RESOURCE_URL_REGEXP),
      };
    default:
      return {};
  }
};

export default function CreateResource({ type = '', onAccept, onCancel, loading = false }) {
  const resourceType = type.toLowerCase();
  const [form, handleFormChange] = useFormFields(getFormStructure(resourceType));
  const formLabel = getCreateLabel(resourceType);

  return (
    <Modal active>
      <Background justifyContent="center" flexDirection="column">
        <Box width="600px">
          {resourceType === RESOURCE_TYPE.CREDENTIALS ? (
            <CredentialForm form={form} handleFormChange={handleFormChange} />
          ) : (
            <RegularResourceForm
              form={form}
              handleFormChange={handleFormChange}
              formLabel={formLabel}
            />
          )}
        </Box>
        <Flex justifyContent="right">
          <Flex ml="auto" mt="20px">
            <Box width="100px">
              <Button secondary onClick={onCancel}>
                CANCEL
              </Button>
            </Box>
            <Box ml="20px" width="100px">
              <Button onClick={() => onAccept(form)} disabled={!form.validate(form)}>
                {loading ? <SpinnerIcon size="30px" /> : 'ADD'}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Background>
    </Modal>
  );
}
