import React, { useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'reflexbox';
import {
  Checkbox,
  ConfirmationModal,
  RegularText,
  UnregularButton,
  StyledText,
  TrashCanIcon,
  SpinnerIcon,
} from 'boss-ui';
import Api from '../../api';
import { useScenarioContext } from '../../libs/context-lib';
import PhrasingList from './QuestionPhrasing/PhrasingList';
import EditableComponent from '../EditableComponent';
import { BASE_POINTS_NUMBER_REGEXP } from './utils';
import {
  INPUT_FORM_TYPE_TEXTAREA,
  MENU_BAR_HEIGHT,
  REMOVE_QUESTION_CONFIRMATION_DIALOG,
} from '../../libs/constants';
import { onError } from '../../libs/error-lib';
import { SCENARIO_QUESTION_ACTIONS } from '../../libs/reducerAction-lib';

const REMOVE_LABEL = 'REMOVE';
const API = new Api();

const Container = styled(Flex)`
  width: 100%;
  min-height: 30px;
  padding: 40px 60px 40px 60px;
  background-color: ${(props) => props.theme.color.question_card.background.inactive};
  padding: ${(props) => props.theme.margin.s};
  border: 2px solid;
  margin: ${(props) => props.theme.margin.xs} 0 ${(props) => props.theme.margin.xs} 0;
  border-color: ${(props) => props.theme.color.question_card.border.pending};

  ${(props) =>
    props.error &&
    css`
      border-color: ${(props) => props.theme.color.question_card.border.incorrect};
    `}
`;
Container.displayName = 'EditableQuestionBigCard-Container';

const RemoveButton = styled(Flex)`
  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.8;
    `}
`;
RemoveButton.displayName = 'EditableQuestionBigCard-RemoveButton';

const getGridColumnsOnExpanded = (media = {}) => {
  if (media.s) {
    return { gridColumnStart: 1, gridColumnEnd: 2 };
  }
  if (media.xl) {
    return { gridColumnStart: 1, gridColumnEnd: 4 };
  }
  return { gridColumnStart: 1, gridColumnEnd: 3 };
};

export default function EditableQuestionBigCard({
  media,
  isImportationView = false,
  onClose = () => {},
  error = null,
  eventId
}) {
  const { state, dispatch } = useScenarioContext();
  const [updatingQuestion, setUpdatingQuestion] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const questionRef = useRef(null);
  // eslint-disable-next-line camelcase
  const { phrasings, external, base_point_value, question_id, background, title } =
    state.openedQuestion;

  const updateQuestionInImport = (key, value) => {
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.UPDATE_OPENED_QUESTION,
      data: { [key]: value },
    });
    const tmpQuestion = { ...state.openedQuestion };
    tmpQuestion[key] = value;
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.UPDATE_QUESTIONS_LIST,
      data: { question: tmpQuestion, questionId: tmpQuestion.question_id },
    });
    if (error && error.questionLine === state.openedQuestion.line) {
      dispatch({ type: SCENARIO_QUESTION_ACTIONS.CLEAR_QUESTION_IMPORTATION_ERROR });
    }
  };

  const updateQuestionInEdition = async (key, value) => {
    const tmpQuestion = { ...state.openedQuestion };
    tmpQuestion[key] = value;
    setUpdatingQuestion(true);
    try {
      let url = `/admin/scenarios/${state.scenario.scenarioId}/questions/${question_id}`;
      if (eventId) {
        url = `/admin/events/${eventId}/scenarios/${state.scenario.scenarioId}/questions/${question_id}`;
      }
      const updatedQuestion = await API.put('events', url,
        {
          body: tmpQuestion,
        }
      );
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.UPDATE_OPENED_QUESTION,
        data: {
          [key]: value,
        },
      });
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.UPDATE_QUESTIONS_LIST,
        data: updatedQuestion,
      });
    } catch (e) {
      onError(e);
    } finally {
      setUpdatingQuestion(false);
    }
  };

  const onQuestionUpdate = async (...args) => {
    if (isImportationView) {
      updateQuestionInImport(...args);
    } else {
      await updateQuestionInEdition(...args);
    }
  };

  const removeQuestion = async () => {
    setUpdatingQuestion(true);
    try {
      let url = `/admin/scenarios/${state.scenario.scenarioId}/questions/${question_id}/`;
      if (eventId) {
        url = `/admin/events/${eventId}/scenarios/${state.scenario.scenarioId}/questions/${question_id}/`;
      }      
      await API.del('events', url);
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.REMOVE_QUESTION_QUESTIONS_LIST,
        data: {
          questionId: question_id,
        },
      });
    } catch (e) {
      onError(e);
    } finally {
      setUpdatingQuestion(false);
    }
  };

  const onAcceptRemoveClick = (e) => {
    e.preventDefault();
    setShowRemoveConfirmation(null);
    removeQuestion();
  };

  useLayoutEffect(() => {
    const questionBoxPosition = questionRef.current.getBoundingClientRect();
    window.scrollTo(0, questionBoxPosition.top + window.pageYOffset - MENU_BAR_HEIGHT);
  }, []);

  return (
    <Container
      flexDirection="column"
      justifyContent="center"
      style={getGridColumnsOnExpanded(media)}
      ref={questionRef}
      error={error}
    >
      <Flex alignItems="center">
        <>
          {!isImportationView && (
            <RemoveButton
              width="50px"
              disabled={updatingQuestion}
              onClick={() => !updatingQuestion && setShowRemoveConfirmation(true)}
            >
              {updatingQuestion ? <SpinnerIcon size="30px" /> : <TrashCanIcon />}
            </RemoveButton>
          )}
          <Flex ml="auto">
            <UnregularButton onClick={onClose} noPadding>
              Close
            </UnregularButton>
          </Flex>
        </>
      </Flex>
      <Box textAlign="left">
        <Flex mb="20px" mt="10px">
          <EditableComponent
            initialValue={title}
            onSaveChanges={(value) => onQuestionUpdate('title', value)}
            placeholder="Enter title here"
          >
            <StyledText light size="28px">
              #{question_id} {title}
            </StyledText>
          </EditableComponent>
        </Flex>
        <EditableComponent
          initialValue={background}
          onSaveChanges={(value) => onQuestionUpdate('background', value)}
          editInputStyle={{ height: '100px' }}
          editableType={INPUT_FORM_TYPE_TEXTAREA}
          placeholder="Enter background here"
        >
          <RegularText light>{background}</RegularText>
        </EditableComponent>
        <EditableComponent
          initialValue={base_point_value}
          onSaveChanges={(value) => onQuestionUpdate('base_point_value', Number(value))}
          placeholder="Enter base points here"
          validate
          editInputStyle={{ maxWidth: '200px' }}
          pattern={BASE_POINTS_NUMBER_REGEXP}
          invalidPatternMessage="Must be numeric"
          validMessage="Valid base points"
        >
          <Flex my="5px" justifyContent="space-between" alignItems="center">
            <RegularText fontSize="20px">Base Points: {base_point_value}</RegularText>
          </Flex>
        </EditableComponent>
        <Flex my="5px" pb="10px" alignItems="center">
          <RegularText fontSize="20px">Is external?:</RegularText>
          <Flex ml="10px" pt="5px">
            <label>
              <Checkbox
                checked={external}
                onChange={(e) => onQuestionUpdate('external', e.target.checked)}
              />
            </label>
          </Flex>
        </Flex>
        <Flex mt="20px" flexDirection="column" width="100%">
          <PhrasingList phrasings={phrasings} error={error} isImportationView={isImportationView} />
        </Flex>
      </Box>
      {showRemoveConfirmation && (
        <ConfirmationModal
          onAccept={onAcceptRemoveClick}
          onCancel={() => setShowRemoveConfirmation(null)}
          acceptLabel={REMOVE_LABEL}
          dangerDialog
        >
          <RegularText>{REMOVE_QUESTION_CONFIRMATION_DIALOG}</RegularText>
        </ConfirmationModal>
      )}
    </Container>
  );
}
