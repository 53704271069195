import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';
import truncate from 'lodash.truncate';
import { StyledText, FormLabel, Tooltip, CancelIcon, PencilIcon, InfoIcon, CheckIcon, TeamIcon } from 'boss-ui';
import FormInput from '../FormInput';
import { TEAM_NAME_REGEXP } from '../../libs/constants';

export default function TeamNameBadge(props) {
  const [editionActive, activateEdition] = useState(false);
  const [saveHover, mouseHoverSave] = useState(false);
  const [cancelHover, mouseHoverCancel] = useState(false);
  const {
    name,
    editionAllowed,
    onNameChange,
    available,
    validating,
    onNameReset,
    onNameChanged,
    mobile,
  } = props;

  const validName = available && name.match(TEAM_NAME_REGEXP);
  
  if (mobile) {
    return (
      <Flex alignItems="flex-start" flexDirection="column">
        {editionActive ? (
          <Box style={{ position: 'relative' }} width="90%">
            <FormLabel>Team Name</FormLabel>
            <FormInput
              id="name"
              autoFocus
              placeholder="Enter a name for your team"
              value={name}
              onChange={onNameChange}
              validate={name}
              validating={validating}
              available={available}
              pattern={TEAM_NAME_REGEXP}
              unavailableMessage="This name was already taken!"
              invalidPatternMessage="From 3 to 30 length, spaces, a-z, 0-9 and _ allowed"
              validMessage="Team name is available"
            />
            <Flex
              data-tip
              data-for="name_info"
              style={{
                position: 'absolute',
                right: '-30px',
                top: '33px',
              }}
            >
              <InfoIcon />
            </Flex>
            <Tooltip id="name_info" place="right">
              The team name you choose will be visible to all other BOTS participants. You may wish to
              avoid identifying yourself or your organization by name! (3 characters at least,
              alphanumerics, spaces and underscores allowed)
            </Tooltip>
          </Box>
        ) : (
          <Flex ml="16px">
            <StyledText size="40px" light>
              {truncate(name, { length: 22, omission: '...' })}
            </StyledText>
          </Flex>
        )}
        {editionAllowed && (
          <Flex style={{ width: '100%', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '10%', marginTop: '-30px', zIndex: '1' }} >
            {editionActive ? (
              <Flex>
                <Flex
                  onMouseLeave={() => mouseHoverSave(false)}
                  onMouseEnter={() => mouseHoverSave(true)}
                  onClick={() => {
                    if (validName && !validating) {
                      onNameChanged();
                      activateEdition(false);
                    }
                  }}
                >
                  <CheckIcon hover={saveHover} disabled={!validName} />
                </Flex>
                <Flex
                  ml="10px"
                  onMouseLeave={() => mouseHoverCancel(false)}
                  onMouseEnter={() => mouseHoverCancel(true)}
                  onClick={() => {
                    onNameReset();
                    activateEdition(false);
                  }}
                >
                  <CancelIcon hover={cancelHover} />
                </Flex>
              </Flex>
            ) : (
              <Flex onClick={() => activateEdition(true)} ml="10px" mt="-5px">
                <PencilIcon />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    );
  }

  return (
    <Flex alignItems="center" flexDirection="row">
      <TeamIcon size="100px" backgroundColor="#535353" />
      {editionActive ? (
        <Box style={{ position: 'relative' }} width="60%">
          <FormLabel>Team Name</FormLabel>
          <FormInput
            id="name"
            autoFocus
            placeholder="Enter a name for your team"
            value={name}
            onChange={onNameChange}
            validate={name}
            validating={validating}
            available={available}
            pattern={TEAM_NAME_REGEXP}
            unavailableMessage="This name was already taken!"
            invalidPatternMessage="From 3 to 30 length, spaces, a-z, 0-9 and _ allowed"
            validMessage="Team name is available"
          />
          <Flex
            data-tip
            data-for="name_info"
            style={{
              position: 'absolute',
              right: '-30px',
              top: '33px',
            }}
          >
            <InfoIcon />
          </Flex>
          <Tooltip id="name_info" place="right">
            The team name you choose will be visible to all other BOTS participants. You may wish to
            avoid identifying yourself or your organization by name! (3 characters at least,
            alphanumerics, spaces and underscores allowed)
          </Tooltip>
        </Box>
      ) : (
        <Flex ml="16px">
          <StyledText size="40px" light>
            {truncate(name, { length: 22, omission: '...' })}
          </StyledText>
        </Flex>
      )}
      {editionAllowed && (
        <Flex style={{ marginLeft: 'auto' }}>
          {editionActive ? (
            <Flex>
              <Flex
                onMouseLeave={() => mouseHoverSave(false)}
                onMouseEnter={() => mouseHoverSave(true)}
                onClick={() => {
                  if (validName && !validating) {
                    onNameChanged();
                    activateEdition(false);
                  }
                }}
              >
                <CheckIcon hover={saveHover} disabled={!validName} />
              </Flex>
              <Flex
                ml="10px"
                onMouseLeave={() => mouseHoverCancel(false)}
                onMouseEnter={() => mouseHoverCancel(true)}
                onClick={() => {
                  onNameReset();
                  activateEdition(false);
                }}
              >
                <CancelIcon hover={cancelHover} />
              </Flex>
            </Flex>
          ) : (
            <Flex onClick={() => activateEdition(true)}>
              <PencilIcon />
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
}
