/**
 * THEMING SCHEMA:
 * For the new items the object pattern is:
 * [Component Name]: {
 *    [css attribute / component sub part]: {
 *      [characteristic]: value
 *    }
 * }
 *
 * example 1:
 *  checkbox: {
 *   color: '#4caf50',
 *  border: '#4caf50',
 *  background: '#4caf50',
 * }
 *
 * example 2:
 *  table: {
 *  border: '#2C2C2C',
 *  header: {
 *    background: { inactive: '#2C2C2C', active: '#eeeeee' },
 *    color: '#898989',
 *    border: '#2C2C2C',
 *  },
 *  row: {
 *    background: { inactive: '#212121', active: '#eeeeee' },
 *    oddBackground: '#1D1D1D',
 *    color: '#D0D0D0',
 *    correct: '#81C784',
 *    incorrect: '#E06C75',
 *    captain: '#de935f',
 *  },
 *},
 */
const color = {
  allocationMonitor: {
    background: '#2C2C2C',
    danger: '#E06C75',
    warning: '#E5C07B',
    optimal: '#81C784',
  },
  toastNotification: {
    toast: {
      background: '#373737',
      boxShadow: '#00000040',
    },
    progressBar: {
      background: '#121212',
    },
  },
  background: '#121212',
  nav: {
    background: '#1D1D1D',
  },
  dragDrop: {
    background: '#2C2C2C',
    border: { draggedOff: '#535353', draggedOn: '#E06C75' },
    color: '#A5D6A7',
  },
  skeleton: {
    color: '#202020',
    highlightColor: '#444',
  },
  notificationList: {
    container: {
      boxShadow: '#00000059',
      background: '#2C2C2C',
      border: '#81C784',
    },
  },
  navTitle: {
    color: '#d0d0d0',
  },
  navigationMenu: {
    background: { inactive: '#1d1d1d', active: '#2c2c2c' },
    color: {
      inactive: '#898989',
      active: '#66bb6a',
      danger: '#E06C75',
    },
    border: {
      inactive: '#898989',
      active: '#66bb6a',
    },
    hover: {
      inactive: '#9edba1',
      danger: '#E06C75',
    },
  },
  select: {
    control: {
      background: { inactive: '#2C2C2C', active: '#373737' },
      border: '#535353',
      color: '#A0A0A0',
    },
    option: {
      background: {
        inactive: '#2C2C2C',
        active: '#373737',
      },
    },
    menu: {
      background: '#2C2C2C',
      border: '#535353',
      color: '#A0A0A0',
    },
    singleValue: {
      color: '#A0A0A0',
    },
  },
  profile: {
    background: '#2C2C2C',
    border: '#81C784',
  },
  form: {
    background: '#1D1D1D',
    header: '#2C2C2C',
    border: {
      main: '#898989',
      modal: '#81C784',
    },
    legend: {
      color: '#898989',
    },
  },
  checkbox: {
    color: '#4caf50',
    border: '#4caf50',
    background: '#4caf50',
  },
  button: {
    regular: {
      accept: {
        disabled: {
          background: '#1D1D1D',
          color: '#535353',
          border: '#2C2C2C',
        },
        default: {
          border: '#66BB6A',
          color: '#66BB6A',
          background: '#2C2C2C',
        },
        hover: {
          background: '#373737',
          color: '#66BB6A',
          border: '#66BB6A',
        },
      },
      reject: {
        default: {
          border: '#A0A0A0',
          color: '#A0A0A0',
          background: '#2C2C2C',
        },
        hover: {
          background: '#373737',
          border: '#A0A0A0',
          color: '#A0A0A0',
        },
      },
      danger: {
        default: {
          border: '#E06C75',
          color: '#E06C75',
          background: '#2C2C2C',
        },
        hover: {
          background: '#373737',
          border: '#E06C75',
          color: '#E06C75',
        },
      },
    },
    unregular: {
      active: {
        default: {
          border: '#66BB6A',
          color: '#66BB6A',
          background: 'transparent',
        },
        hover: {
          background: '#373737',
          border: '#66BB6A',
          color: '#66BB6A',
        },
      },
      inactive: {
        default: {
          border: '#A0A0A0',
          color: '#A0A0A0',
          background: 'transparent',
        },
        hover: {
          background: '#373737',
          border: '#A0A0A0',
          color: '#A0A0A0',
        },
      },
    },
    small: {
      border: '#373737',
      background: 'transparent',
    },
  },
  input: {
    default: {
      border: '#535353',
      color: '#d0d0d0',
      background: '#2C2C2C',
      placeholderColor: '#898989',
    },
    focused: {
      border: '#81C784',
      background: '#2C2C2C',
    },
    hover: {
      border: '#535353',
      background: '#373737',
    },
    error: {
      border: '#E06C75',
      background: '#2C2C2C',
    },
    success: {
      border: '#66BB6A',
      background: '#2C2C2C',
    },
  },
  table: {
    border: '#2C2C2C',
    header: {
      background: { inactive: '#2C2C2C', active: '#eeeeee' },
      color: '#898989',
      border: '#2C2C2C',
    },
    row: {
      background: { inactive: '#212121', active: '#eeeeee' },
      oddBackground: '#1D1D1D',
      color: '#D0D0D0',
      correct: '#81C784',
      incorrect: '#E06C75',
      captain: '#de935f',
      hover: '#535353',
    },
  },
  border: {
    active: '#81C784',
    danger: '#E06C75',
  },
  text: {
    light: {
      color: '#D0D0D0',
    },
    regular: {
      color: '#A0A0A0',
    },
    medium: {
      color: '#898989',
    },
    dark: {
      color: '#535353',
    },
    active: {
      color: '#81C784',
    },
    danger: {
      color: '#E06C75',
    },
    floatingMessage: {
      active: '#A5D6A7',
      inactive: '#535353',
    },
    captain: { color: '#de935f' },
    default: { color: '#6C6C6C' },
  },
  tooltip: {
    textColor: '#d0d0d0',
    backgroundColor: '#2c2c2c',
    borderColor: '#81a2be',
    arrowColor: '#2c2c2c',
  },
  breadcrumb: {
    active: '#81c784',
    inactive: '#898989',
  },
  messageCard: { border: '#2C2C2C' },
  card: {
    classic: {
      border: {
        active: '#81C784',
        inactive: '#E5C07B',
        progress: '#45a647',
        inprogress: '#4CAF50',
        completed: '#a088a4',
        history: '#a088a4',
      },
      title: {
        active: '#81C784',
        inprogress: '#4CAF50',
        inactive: '#E5C07B',
        completed: '#a088a4',
        history: '#a088a4',
      },
      background: {
        inactive: '#1D1D1D',
        hover: '#2C2C2C',
      },
      description: '#D0D0D0',
    },
    restricted: {
      border: '#FFFFFF',
      title: '#FFFFFF',
      background: { inactive: '#1D1D1D', hover: '#2C2C2C' },
      description: '#898989',
      active: '#81C784',
      inactive: '#E5C07B',
    },
  },
  question_card: {
    background: {
      inactive: '#1D1D1D',
      active: '#2C2C2C',
    },
    border: {
      pending: '#A0A0A0',
      correct: '#81C784',
      incorrect: '#E06C75',
    },
    color: {
      pending: '#A0A0A0',
      correct: '#81C784',
      incorrect: '#E06C75',
      guidance: '#81A2BE',
      hint: '#A088A4',
    },
  },
  questionAnswerTable: {
    background: '#181818',
  },
  questionPhrasing: {
    background: '#181818',
    border: '#A0A0A0',
  },
  boards: {
    top: {
      activeLight: '#A5D6A7',
      progressBar: '#81c784',
    },
  },
  user: {
    container: {
      email: {
        color: '#6C6C6C',
      },
    },
  },
  searchTable: {
    logo: {
      color: '#535353',
    },
  },
  box: {
    border: {
      color: '#2C2C2C',
    },
  },
  team: {
    visibility: {
      public: '#A5D6A7',
    },
    access: {
      open: '#A5D6A7',
    },
    memberShip: {
      hasTeam: '#A088A4',
      hasNoTeam: '#A5D6A7',
    },
  },
};

const font = {
  family: 'Roboto Mono',
};

const margin = {
  xs: '6px',
  s: '20px',
  m: '40px',
  l: '60px',
};

const Theme = { color, font, margin };
export default Theme;
