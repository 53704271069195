import React from 'react';
import { Flex } from 'reflexbox';
import { useTheme } from 'styled-components';
import {
  PencilIcon,
  RegularText,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  TrashCanIcon,
  UnregularButton,
} from 'boss-ui';
import { getIndexError, PATH_ERROR_TYPE } from './utils';

export default function QuestionHintsTable({
  hints = [],
  onAddItem = () => {},
  onRemoveItem = () => {},
  onEditItem = () => {},
  errorPath = null,
}) {
  const errorIdx = getIndexError(errorPath, PATH_ERROR_TYPE.HINTS);
  const theme = useTheme();

  return (
    <Table backgroundColor="#181818">
      <TableHead>
        <TableRow border={`1px solid ${theme.color.box.border.color}`}>
          <TableHeader width="5%" justify="center">
            #
          </TableHeader>
          <TableHeader width="15%">Points</TableHeader>
          <TableHeader>Hint</TableHeader>
          <TableHeader width="15%" justify="center">
            E/time
          </TableHeader>
          <TableHeader justify="center" width="5%">
            <UnregularButton active onClick={onAddItem}>
              +
            </UnregularButton>
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {hints.map((hint, idx) => {
          return (
            <TableRow
              key={`${hint.hint}-${hint.hint_cost}`}
              border={`1px solid ${theme.color.box.border.color}`}
              borderIncorrect={idx === errorIdx}
            >
              <TableData>
                <RegularText color="#E06C75" fontSize="14px" justifyContent="center">
                  {idx}
                </RegularText>
              </TableData>
              <TableData>
                <RegularText color="#E06C75" fontSize="14px">
                  - {hint.hint_cost} points
                </RegularText>
              </TableData>
              <TableData>
                <RegularText fontSize="14px" color="#E5C07B" style={{ wordBreak: 'break-all' }}>
                  {hint.hint}
                </RegularText>
              </TableData>
              <TableData>
                <RegularText fontSize="14px" color="#E5C07B" justifyContent="center">
                  {hint.elapsed_time_before_visible}
                </RegularText>
              </TableData>
              <TableData>
                <Flex alignItems="center" justifyContent="center" py="10px">
                  <PencilIcon size="16px" onClick={() => onEditItem(hint, idx)} />
                  <Flex ml="10px" onClick={() => onRemoveItem(idx)} style={{ cursor: 'pointer' }}>
                    <TrashCanIcon size="22px" />
                  </Flex>
                </Flex>
              </TableData>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
