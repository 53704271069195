import React from 'react';
import { Menu } from 'boss-ui';
import { Flex } from 'reflexbox';
import FeatureFlagsForm from './FeatureFlagsForm';
import { useAppContext } from '../../libs/context-lib';
import { filterMenuByRole } from '../../libs/user-lib';

export default function FeatureFlagsContainer() {
    const { state } = useAppContext();

    return (
        <Flex flexDirection="column">
            <Menu menuItems={filterMenuByRole(state.user)} />
            <Flex width={1} justifyContent="center">
                <Flex mt="60px" mb="5%" flexDirection="column">
                    <FeatureFlagsForm />
                </Flex>
            </Flex>
        </Flex>
    );
}