import { USER_ROLES, defaultMenuItems } from './constants';

export function getUserFieldsChanges(baseUser, newUser) {
  const changes = {};
  if (newUser.name !== baseUser.name) {
    changes.displayName = newUser.name;
  }
  if (newUser.role !== baseUser.role) {
    changes.role = newUser.role;
  }
  return changes;
}
export function getParticipantChanges(baseUser, newUser) {
  const changes = {};
  if (newUser.name !== baseUser.name) {
    changes.displayName = newUser.name;
  }
  if (newUser.isAdmin !== baseUser.isAdmin) {
    changes.role = newUser.isAdmin ? USER_ROLES.ADMIN : USER_ROLES.PLAYER;
  }
  return changes;
}

export function validateCognitoUserExists(user) {
  if (!user) {
    return false;
  }
  return true;
}

export function validateDynamoUserExists(user) {
  if (!user || !user.dynamoUser) {
    return false;
  }
  return true;
}

export function validateUserSession(user) {
  return (
    validateCognitoUserExists(user) && validateDynamoUserExists(user) && userHasPermission(user)
  );
}

export function userHasPermission(user) {
  if (validateDynamoUserExists(user)) {
    return (
      user.dynamoUser.role === USER_ROLES.SUPERADMIN || user.dynamoUser.role === USER_ROLES.ADMIN
    );
  }
  return false;
}

export function filterMenuByRole(user, menus = defaultMenuItems) {
  if (user?.dynamoUser?.role !== USER_ROLES.SUPERADMIN) {
    return menus.filter((menu) => menu.path !== '/users' && menu.path !== '/scenarios' && menu.path !== '/featureflags');
  }
  return menus;
}
