import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import Api from '../api';
import { Flex, Box } from 'reflexbox';
import {
  Form,
  FormLeyend,
  LinkedText,
  Paginator,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableItemSkeleton,
  TableRow,
  HovereableFlex,
  RefreshIcon,
  Tooltip  
} from 'boss-ui';
import FormInput from './FormInput';
import { onError } from '../libs/error-lib';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../libs/context-lib';
import { APP_ACTIONS } from '../libs/reducerAction-lib';

const PAGE_SIZE = 10;
const PAGE_STEP = 1;
const API = new Api();

export default function SearchScenarios() {
  const appContext = useAppContext();
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [page, setPage] = useState(0);
  const [scenarios, setScenarios] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const history = useHistory();

  const searchScenarios = async (q = '', page = 0) => {
    setLoading(true);
    try {
      const req = await API.get('events', '/admin/scenarios', {
        queryStringParameters: {
          title: q,
          limit: PAGE_SIZE,
          skip: page * PAGE_SIZE,
        },
      });
      setScenarios(req.scenarios);
      setPaginationData(req.pagination);
      setLoading(false);
    } catch (e) {
      onError(e);
    }
  };

  const cleanBox = () => {
    setSearchString('');
  };

  const delayedQuery = useCallback(
    debounce((q) => searchScenarios(q), 500),
    []
  );

  useEffect(() => {
    searchScenarios();
    return () => API.abortCurrentRequest();
  }, []);

  const onNextPage = () => {
    searchScenarios(searchString, page + PAGE_STEP);
    setPage((p) => p + 1);
  };

  const onPrevPage = () => {
    searchScenarios(searchString, page - PAGE_STEP);
    setPage((p) => p - 1);
  };

  const onScenarioClick = (scenario) => {
    appContext.dispatch({
      type: APP_ACTIONS.SET_ROUTES,
      data: [
        {
          path: `/scenarios`,
          name: 'Scenarios',
        },
        {
          path: `/scenarios/${scenario.scenarioId}`,
          name: scenario.title,
        },
      ],
    });
    history.push(`/scenarios/${scenario.scenarioId}/questions`);
  };

  return (
    <Form noBorder>
      <Flex px="40px" py="30px" width="924px" height="700px" flexDirection="column">
        <Box mb="10px" width={2 / 3}>
          <FormLeyend>Find a Scenario</FormLeyend>
          <FormInput
            search
            remove={searchString.length > 0 && cleanBox}
            onChange={(e) => {
              setSearchString(e.target.value);
              delayedQuery(e.target.value);
            }}
            value={searchString}
            placeholder="Search by scenario name"
          />
        </Box>
        <HovereableFlex data-tip data-for="refresh" width="20px" ml="10px" mb="12px">
          <RefreshIcon onClick={searchScenarios} rotate={loading} color="#898989" hoverColor="#d0d0d0" />
          <Tooltip id="refresh">Refresh list</Tooltip>          
        </HovereableFlex>
        <Flex flexDirection="column" justifyContent="space-between" height="100%">
          <Table>
            <TableHead>
              <TableRow height="41px">
                <TableHeader>Scenario</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableItemSkeleton numberOfCells={3} />
              ) : (
                scenarios.map((scenario) => (
                  <TableRow height="41px" key={scenario.scenarioId}>
                    <TableData>
                      <LinkedText onClick={() => onScenarioClick(scenario)}>
                        {scenario.title}
                      </LinkedText>
                    </TableData>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Flex width={1} justifyContent="center">
            <Paginator
              isNext={paginationData.skip + PAGE_SIZE < paginationData.total}
              onNext={onNextPage}
              isPrev={page > 0}
              onPrev={onPrevPage}
              paginating={loading}
            />
          </Flex>
        </Flex>
      </Flex>
    </Form>
  );
}
