import React from 'react';
import styled from 'styled-components';
import svgBase from './StyledSVGBase';

const SIZE = '24px';

const SearchIcon = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: ${(props) => props.color || '#898989'};
  &:hover {
    fill: #d0d0d0;
    cursor: pointer;
  }
`;
SearchIcon.displayName = 'SplunkSearchIcon-SearchIcon';

export default function SplunkSearchIcon(props) {
  return (
    <SearchIcon viewBox="0 0 55 65" {...props}>
      <path d="M0 11.93V0l53.207 26.698V37.5L0 64V52.26L41.279 32z" stroke="none" fill="#989898" />
    </SearchIcon>
  );
}
