import { Flex } from "reflexbox";
import styled from "styled-components";

const MainContainer = styled(Flex)`
  flex-direction: column;
`;

const SecondaryContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
`;

const ThirdContainer = styled(Flex)`
  margin-bottom: 5%;
  flex-direction: column;
  margin-top: 60px;
`;

export {
  MainContainer,
  SecondaryContainer,
  ThirdContainer
}