import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Menu } from 'boss-ui';
import { User } from '../../components';
import SearchUsers from '../../components/Users/SearchUsers';
import { useAppContext } from '../../libs/context-lib';
import { filterMenuByRole } from '../../libs/user-lib';
import { MainContainer, SecondaryContainer, ThirdContainer } from './styles';

export default function Users() {
  const [user, setUser] = useState({});
  const { state } = useAppContext();

  return (
    <MainContainer>
      <Menu menuItems={filterMenuByRole(state.user)} />
      <SecondaryContainer>
        <ThirdContainer>
          <Switch>
            <Route path="/users" exact>
              <SearchUsers
                onClickedUser={
                  (objUser) => {
                    setUser(objUser);
                  }
                }
              />
            </Route>
            <Route path="/users/:userId" exact>
              <User user={user} />
            </Route>
          </Switch>
        </ThirdContainer>
      </SecondaryContainer>
    </MainContainer>
  );
}
