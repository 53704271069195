import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);
  return [
    fields,
    function (event) {
      const value = event.target.value;
      const id = event.target.id;
      setValues((fields) => ({
        ...fields,
        [id]: value,
      }));
    },
  ];
}

export function useDynamoPaginator() {
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  return [
    { pages, currentPage },
    function (paginationResponse, targetPage) {
      const newPage = {};
      const pagesCopy = [...pages];
      if (paginationResponse.more) {
        newPage.nextPagePointer = paginationResponse.LastEvaluatedKey;
        newPage.lastPagePointer = targetPage < 2 ? null : pages[targetPage - 2].nextPagePointer;
        pagesCopy[targetPage] = newPage;
        setPages(pagesCopy);
      } else if (targetPage !== 0) {
        newPage.lastPagePointer = targetPage < 2 ? null : pages[targetPage - 2].nextPagePointer;
        pagesCopy[targetPage] = newPage;
        setPages(pagesCopy);
      } else {
        setPages([]);
      }
      setCurrentPage(targetPage);
    },
    function (nextPage) {
      if (nextPage > 0) {
        if (nextPage === currentPage) {
          return pages[currentPage - 1].nextPagePointer;
        }
        if (nextPage > currentPage) {
          return pages[currentPage].nextPagePointer;
        }
        return pages[currentPage].lastPagePointer;
      }
      return null;
    },
  ];
}

export function useBreadcrumb() {
  const [breadcrumbs, setBc] = useState([]);
  const history = useHistory();
  const setBreadcrumbs = (routes, match) => {
    const crumbs = routes
      .filter(({ path, force }) => match.path.includes(path) || force)
      .map(({ path, hash, ...rest }) => ({
        path: Object.keys(match.params).length
          ? Object.keys(match.params).reduce(
              (path, param) => path.replace(`:${param}`, match.params[param]),
              path
            )
          : path,
        action: () => history.push(hash ? `${path}${hash}` : path),
        ...rest,
      }));
    setBc(crumbs);
  };

  return [breadcrumbs, setBreadcrumbs];
}

export function useRefreshScore(loading = false) {
  const [refreshHover, setRefreshHover] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(0);
  const resetLastUpdate = () => setLastUpdate(0);

  const getLastUpdatedLabel = () => {
    if (lastUpdate < 1) {
      return 'seconds ago';
    }
    if (lastUpdate === 1) {
      return 'a minute ago';
    }
    return `${lastUpdate} minutes ago`;
  };

  useEffect(() => {
    setLastUpdate(0);
    const update = setInterval(() => {
      setLastUpdate((p) => p + 1);
    }, 60000);
    return () => clearInterval(update);
  }, [loading]);

  return [refreshHover, setRefreshHover, getLastUpdatedLabel, resetLastUpdate];
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
