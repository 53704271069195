import Papa from 'papaparse';
import { replaceAll } from './utils-lib';

const DEFAULT_PARSE_CONFIG = {
  download: false,
  header: true,
  worker: false,
  delimiter: ',',
  skipEmptyLines: true,
};
export const EOL = '\n'; // some OS use '\n\r';
export const SEP_CHAR = ',';
export const ESCAPE_CHAR = '""';

export const escapeField = (value, hasSpecialChars = true) => {
  let text = value === undefined || value === null ? '' : value.toString();

  // if the field has some special char, may be is going to cause some issues with the parser, we prevent and escape it
  if (
    hasSpecialChars ||
    text.indexOf(SEP_CHAR) > -1 ||
    text.indexOf(EOL) > -1 ||
    text.indexOf('"') > -1
  ) {
    text = replaceAll(text, '"', ESCAPE_CHAR);
    text = `"${text}"`; // add double quotes between the text
  }
  return text;
};

export function parseCSV({ file, config = DEFAULT_PARSE_CONFIG }) {
  if (!file) {
    throw new Error('file to parse is mandatory');
  }
  return new Promise((resolve, reject) => {
    try {
      Papa.parse(file, {
        complete: (results) => resolve(results.data),
        ...config,
      });
    } catch (e) {
      reject(e);
    }
  });
}

// get the max .length in all fields in the generic array
export const getMaxFieldsLength = (data = []) => {
  let maxLen = 0;
  for (let i = 0; data && i < data.length; i++) {
    maxLen = data[i].fields && data[i].fields.length > maxLen ? data[i].fields.length : maxLen;
  }
  return maxLen;
};

export const jsonToCsvText = (
  jsonObj = { header: [], data: [] },
  addHeaderLine = true,
  separator = SEP_CHAR
) => {
  let line = '';
  let maxFieldLen = 0;
  if (addHeaderLine && jsonObj.header.length > 0) {
    line = `${jsonObj.header.join(separator)}${EOL}`; // first header line
    maxFieldLen = jsonObj.header.length; // if the header has 10 column, then all the lines must have 10 columns
  } else {
    maxFieldLen = getMaxFieldsLength(jsonObj.data); // otherwise we search for the max field length
  }
  for (let i = 0; i < jsonObj.data.length; i++) {
    for (let r = 0; r < maxFieldLen; r++) {
      line += `${escapeField(jsonObj.data[i].fields[r])}${separator}`;
    }
    line = `${line.substring(0, line.length - separator.length)}${EOL}`;
  }
  return line.substring(0, line.length - EOL.length);
};

export const exportFile = (
  data,
  fileName = 'exporteFile.csv',
  addHeaderLine = true,
  fileExtension = '.csv',
  separator = SEP_CHAR
) => {
  const element = document.createElement('a');
  const file = new Blob([jsonToCsvText(data, addHeaderLine, separator)], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);

  if (fileName.toUpperCase().indexOf(fileExtension.toUpperCase()) > -1) {
    element.download = fileName;
  } else {
    element.download = fileName + fileExtension;
  }

  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  setTimeout(() => document.body.removeChild(element), 2000); // dont needed anymore
};
