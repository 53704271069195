import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import { Button } from 'boss-ui';

const MainContainer = styled(Flex)`
  padding: 30px 40px;
  width: 924px;
  height: 700px;
  flex-direction: column;
`;
MainContainer.displayName = 'SearchEvents-MainContainer';

const FilterContainer = styled(Flex)`
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
`;
FilterContainer.displayName = 'SearchEvents-FilterContainer';

const NameFilterContainer = styled(Box)`
  width: 40%;
  margin-bottom: -6px;
`;
NameFilterContainer.displayName = 'SearchEvents-NameFilterContainer';

const PartialFilterContainer = styled(Box)`
  width: 20%;
`;
PartialFilterContainer.displayName = 'SearchEvents-PartialFilterContainer';

const ActionsContainer = styled(Flex)`
  justify-content: end;
  align-items: center;
`;
ActionsContainer.displayName = 'SearchEvents-ActionsContainer';

const NewEventButton = styled(Button).attrs({ height: '36px' })``;
NewEventButton.displayName = 'SearchEvents-NewEventButton';

export {
  MainContainer,
  FilterContainer,
  NameFilterContainer,
  PartialFilterContainer,
  NewEventButton,
  ActionsContainer,
}