import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';
import isEmpty from 'lodash.isempty';
import trim from 'lodash.trim';
import FormInput from './FormInput';
import { PencilIcon, CheckIcon, CancelIcon } from 'boss-ui';
import { DEFAULT_FORM_INPUT_TYPE } from '../libs/constants';
import { isValidPattern } from '../libs/utils-lib';

const isDisable = (value, checkEmpty, pattern) => {
  return (
    (pattern !== null && !isValidPattern(value, pattern)) ||
    (checkEmpty && (isEmpty(value.toString()) || trim(value) === ''))
  );
};

/**
 * @param  {component} children the children comp to show when is not on EditMode
 * @param  {Function}  onSaveChanges when click the check Icon to save
 * @param  {String}    initialValue default value for the input on edit mode
 * @param  {Object}    style css obj for the main container
 * @param  {Object}    editInputStyle css obj for FormInput comp
 * @param  {Boolean}   checkEmpty enable check if the input value is empty
 * @param  {String}    placeholder for the input when is empty
 * @param  {String}    pattern if you need to use a regex
 * @param  {String}    invalidPatternMessage message when pattern/regex fails
 * @param  {Boolean}   validate To show message if the input's pattern is valid/invalid
 * @param  {String}    validMessage The message when input is valid
 * @param  {String}    editableType on edit mode choose input or textarea
 */
export default function EditableComponent({
  children = null,
  onSaveChanges = () => {},
  initialValue = '',
  style = {},
  editInputStyle = {},
  checkEmpty = true,
  placeholder = 'Enter text here',
  pattern = null,
  invalidPatternMessage = 'Invalid text',
  validate = false,
  validMessage = 'Valid field',
  editableType = DEFAULT_FORM_INPUT_TYPE,
}) {
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(initialValue);

  const inputChange = (e) => {
    setValue(e.target.value);
  };

  const sendChanges = () => {
    if (!isDisable(value, checkEmpty, pattern)) {
      setEditMode(false);
      onSaveChanges(value);
    }
  };

  const onCancel = () => {
    setValue(initialValue);
    setEditMode(false);
  };

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      py="10px"
      style={style}
      width="100%"
      className="EditableComponent-MainContainer"
    >
      {editMode ? (
        <>
          <Flex pr="10px" width="100%" className="EditableComponent-EditableInput">
            <FormInput
              value={value}
              onChange={inputChange}
              placeholder={placeholder}
              pattern={pattern}
              invalidPatternMessage={invalidPatternMessage}
              validate={validate}
              validMessage={validMessage}
              style={editInputStyle}
              inputType={editableType}
              available
            />
          </Flex>
          <Box className="EditableComponent-Icons">
            <Flex className="EditableComponent-IconContainer">
              <Flex
                className="EditableComponent-CheckIconContainer"
                style={isDisable(value, checkEmpty, pattern) ? { cursor: 'not-allowed' } : {}}
              >
                <CheckIcon onClick={sendChanges} />
              </Flex>
              <CancelIcon onClick={onCancel} />
            </Flex>
          </Box>
        </>
      ) : (
        <>
          <Flex pr="10px" className="EditableComponent-ChildrenContainer">
            {children}
          </Flex>
          <Box className="EditableComponent-IconContainer" minWidth="fit-content">
            <PencilIcon onClick={() => setEditMode(true)} />
          </Box>
        </>
      )}
    </Flex>
  );
}
