import React from 'react';
import { Flex } from 'reflexbox';
import Media from 'react-media';
import { Switch, Route } from 'react-router-dom';
import Scenarios from '../../components/Events/EventScenarios';
import { useEventContext } from '../../libs/context-lib';
import ScenarioQuestionsEdition from '../ScenarioQuestionsEdition';
import ScenarioQuestionsImportation from '../ScenarioQuestionsImportation';

const getHorizontalMargins = (matches) => {
  if (matches.m) {
    return '20px';
  }
  if (matches.xl || matches.l) {
    return '15%';
  }
  return '20%';
};

export default function EventScenarios({loading = false}) {
  const { state } = useEventContext();  

  return (
        <Switch>
          <Route path="/events/:eventId/scenarios" exact>
            <Flex width={1} justifyContent="center">
              <Flex mb="5%" flexDirection="column" mt="60px">
                <Scenarios loading={loading} scenarios={state.event.scenarios} />
              </Flex>
            </Flex>
          </Route>
          <Media
            queries={{
              s: '(max-width: 1000px)',
              m: '(max-width: 1290px)',
              l: '(max-width: 1540px)',
              xl: '(min-width: 1880px)',
            }}
          >
            {(matches) => (
              <>
                <Route path="/events/:eventId/scenarios/:scenarioId/questions" exact>
                  <Flex 
                    flexDirection="column" 
                    mt="20px" 
                    width="-webkit-fill-available"
                    justifyContent="center"
                    mx={getHorizontalMargins(matches)}
                    mb="5%"
                  >
                    <ScenarioQuestionsEdition />
                  </Flex>
                </Route>
                <Route path="/events/:eventId/scenarios/:scenarioId/import" exact>
                  <Flex 
                    flexDirection="column" 
                    mt="20px" 
                    width="-webkit-fill-available"
                    justifyContent="center"
                    mx={getHorizontalMargins(matches)}
                    mb="5%"
                  >
                    <ScenarioQuestionsImportation />
                  </Flex>              
                </Route>  
              </>
            )}
          </Media>
        </Switch>
  );
}
