import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';
import debounce from 'lodash.debounce';
import trim from 'lodash.trim';
import Api from '../../api';
import { Flex, Box } from 'reflexbox';
import {
  ContextMenu,
  ContextMenuItem,  
  Form,
  FormLeyend,
  TableBody,
  TableHeader,
  Table,
  TableHead,
  TableRow,
  Paginator,
  TableData,
  LinkedText,
  RegularText,
  TableItemSkeleton,
  TeamIcon,
  Tooltip,
  Select,
  UnregularButton,
  TrashCanIcon,
  ConfirmationModal,
  RefreshIcon,
  HovereableFlex,
  CrossIcon,
  Modal,
  Button,
} from 'boss-ui';
import FormInput from '../FormInput';
import AllowListUser from './AllowListUser';
import { useDynamoPaginator } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';
import { useEventContext } from '../../libs/context-lib';
import { isValidPattern } from '../../libs/utils-lib';
import { EVENT_ACTION } from '../../libs/reducerAction-lib';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_STEP, EMAIL_MAX_IMPORT, EMAIL_REGEXP, NUMBERS } from '../../libs/constants';

const API = new Api();
const SELECT_ALLOW_STATE = [
  { value: '', label: 'All' },
  { value: 'JOINED', label: 'Joined' },
  { value: 'PENDING', label: 'Pending' },
];

const getUserState = (user) => {
  if (user.hasTeam) {
    return 'has team';
  }
  return 'has no team';
};

const prepareUserList = (users) => {
  return users.map((u) => ({ ...u, inviteState: getUserState(u) }));
};

export default function InvitesDirectList() {
  const userRouteMatch = useRouteMatch('/events/:eventId/invites/direct');
  const { dispatch } = useEventContext();
  const [{ pages, currentPage }, paginate] = useDynamoPaginator();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [rowHoverId, setRowHoverId] = useState(0);
  const [selectedUser, setSelectedUser] = useState({});
  const [confirmationDeleteInviteText, setConfirmationDeleteInviteText] = useState(null);
  const [comboAllowState, setAllowState] = useState(SELECT_ALLOW_STATE[0]);
  const [addEmailVisible, setAddEmailVisible] = useState(false);
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [errorEmails, setErrorEmails] = useState([]);
  const [errorEmailMsg, setErrorEmailMsg] = useState('');
  const [loadingEmail, setLoadingEmail] = useState(false);  
  const iconColor = useTheme().color.searchTable.logo.color;

  const searchInvites = async (q = '', comboState = comboAllowState.value, page = NUMBERS.ZERO) => {
    setLoading(true);
    let lastEvaluatedKey;
    if (page > NUMBERS.ZERO) {
      lastEvaluatedKey =
        page > currentPage
          ? pages[currentPage].nextPagePointer
          : pages[currentPage].lastPagePointer;
    }
    try {
      const result = await API.get(
        'events',
        `/admin/events/${userRouteMatch.params.eventId}/allowlist?email=${q}&state=${comboState}`,
        {
          queryStringParameters: {
            pageSize: DEFAULT_PAGE_SIZE,
            ...(lastEvaluatedKey && {
              ...lastEvaluatedKey,
            }),
          },
        }
      );
      setSearchList(prepareUserList(result.allowlist));      
      paginate(result.pagination, page);
      setLoading(false);
    } catch (e) {
      onError(e);
    }
  };

  const cleanBox = () => {
    setAllowState(SELECT_ALLOW_STATE[0]);
    setSearchString('');
    searchInvites(SELECT_ALLOW_STATE[0].value);
  };

  const delayedQuery = useCallback(
    debounce((q) => searchInvites(q), 500),
    []
  );

  useEffect(() => {
    cleanBox();
    return () => API.abortCurrentRequest();
  }, []);

  const onClickDeleteInvite = (item) => {
    setConfirmationDeleteInviteText(`Are you sure you want to delete the invite to ${item.name}?`);
    setSelectedUser(item);
  };

  const delInvitation = async () => {
    setConfirmationDeleteInviteText(null);
    setLoading(true);
    try {
      await API.del('events', `/admin/events/${userRouteMatch.params.eventId}/allowlist/${selectedUser.email}`);
      await searchInvites(searchString, comboAllowState.value);
      setSelectedUser({});
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  const cleanEmail = () => {
    setEmail('');
    setErrorEmailMsg('');
    setErrorEmails([]);
  };

  const addEmail = async () => {
    if (trim(email) === '') {
      return;
    }
    setErrorEmailMsg('');
    setErrorEmails([]);
    const vEmails = trim(email).split(',');
    if (vEmails.length > EMAIL_MAX_IMPORT) {
      setErrorEmailMsg(`Only you can add ${EMAIL_MAX_IMPORT} emails or less`);
      return;
    }
    const vWrongEmails = [];
    const vDuplicatedEmails = [];
    const objDuplicatedEmails = {};
    const emails = [];
    for (let i = 0; i < vEmails.length; i++) {
      // the email is valid?
      if (!isValidPattern(vEmails[i], EMAIL_REGEXP)) {
        vWrongEmails.push(vEmails[i]);
      } else {
        emails.push({ email: vEmails[i] });
        objDuplicatedEmails[vEmails[i]] = objDuplicatedEmails[vEmails[i]]
          ? objDuplicatedEmails[vEmails[i]] + 1
          : 1;
      }
    }
    if (vWrongEmails.length > 0) {
      if (vWrongEmails.length === 1 && vEmails.length === 1) {
        setErrorEmailMsg('Please type a valid email');
      } else {
        setErrorEmailMsg('The following are invalid emails:');
        setErrorEmails(vWrongEmails);
      }
      return;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const idx in objDuplicatedEmails) {
      if (objDuplicatedEmails[idx] > 1) {
        vDuplicatedEmails.push(idx); // is some email typed twice or more?
      }
    }
    if (vDuplicatedEmails.length > 0) {
      setErrorEmailMsg('The following are duplicated emails:');
      setErrorEmails(vDuplicatedEmails);
      return;
    }
    setLoadingEmail(true);
    try {
      await API.post('events', `/admin/events/${userRouteMatch.params.eventId}/allowlist`, {
        body: {
          allowlist: emails,
        },
      });
      setAddEmailVisible(false); // hide the modal
      setEmail('');
      searchInvites(searchString, comboAllowState.value);
    } catch (e) {
      onError(e);
    } finally {
      setLoadingEmail(false);
    }
  };

  const handleEnterSubmit = (e) => {
    if (e.key === 'Enter') {
      addEmail();
    }
  };

  const onComboChange = (selected) => {
    setAllowState(selected);
    searchInvites(searchString, selected.value);
  };

  const onItemClicked = (item) => {
    dispatch({ type: EVENT_ACTION.SET_PARTICIPANT, data: item });
    history.push(`/events/${userRouteMatch.params.eventId}/participants/${item.userId}`);
  };

  return (
    <Form noBorder>
      <Flex p="40px" pb="30px" width="924px" height="700px" flexDirection="column">
        <Flex justifyContent="space-between">
          <Box mb="10px" width="70%">
            <FormLeyend>Find a user</FormLeyend>
            <FormInput
              search={searchString}
              remove={searchString.length > 0 && cleanBox}
              onChange={(e) => {
                setSearchString(e.target.value);
                delayedQuery(e.target.value);
              }}
              value={searchString}
              placeholder="Search by email"
            />
          </Box>
          <Box width="122px" mt="16px" mx="1px">
            <Select value={comboAllowState} onChange={onComboChange} options={SELECT_ALLOW_STATE} />
          </Box>
          <TeamIcon size="52px" fillColor={iconColor} />
        </Flex>
        <HovereableFlex data-tip data-for="refresh" width="20px" ml="10px" mb="12px">
          <RefreshIcon onClick={cleanBox} rotate={loading} color="#898989" hoverColor="#d0d0d0" />
          <Tooltip id="refresh">Refresh list</Tooltip>          
        </HovereableFlex>
        <Flex flexDirection="column" justifyContent="space-between" height="100%">
          <Table onMouseOut={() => setRowHoverId(0)}>
            <TableHead>
              <TableRow height="41px">
                <TableHeader style={{ paddingLeft: '10px' }}>User</TableHeader>
                <TableHeader>Status</TableHeader>
                <TableHeader justify="flex-end">
                  <ContextMenu button={<UnregularButton active>+</UnregularButton>}>
                    <ContextMenuItem>
                      <LinkedText onClick={() => setAddEmailVisible(true)}>Add email</LinkedText>
                    </ContextMenuItem>
                    <ContextMenuItem>
                      <LinkedText onClick={() => setAddUserVisible(true)}>Add user</LinkedText>
                    </ContextMenuItem>
                  </ContextMenu>                    
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableItemSkeleton numberOfCells={3} />
              ) : (
                searchList.map((item) => (
                  <TableRow
                    height="41px" 
                    key={item.userId}
                    onMouseOver={() => setRowHoverId(item.userId)}
                    borderTransparent
                    mouseOver={rowHoverId === item.userId}                  
                  >
                    <TableData style={{ paddingLeft: '10px' }}>
                      {item.state === 'JOINED' ? (
                        <LinkedText onClick={() => onItemClicked(item)}>{item.name}</LinkedText>                        
                      ) : (
                        <>{item.email}</>
                      )}
                    </TableData>
                    <TableData>{item.state}</TableData>
                    <TableData right style={{ paddingRight: '15px' }}>
                    {item.state === 'PENDING' && (
                      <Flex justifyContent="flex-end" style={{ cursor: 'pointer' }}
                        onClick={() => {
                          onClickDeleteInvite(item);
                        }}
                      >
                        <TrashCanIcon />
                      </Flex>                    
                    )}
                    </TableData>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Flex width={1} justifyContent="center">
            <Paginator
              isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
              onNext={() => searchInvites(searchString, comboAllowState.value, currentPage + DEFAULT_PAGE_STEP)}
              isPrev={currentPage > 0}
              onPrev={() => searchInvites(searchString, comboAllowState.value, currentPage - DEFAULT_PAGE_STEP)}
              paginating={loading}
            />  
          </Flex>                  
        </Flex>
      </Flex>
      {addEmailVisible && (
        <Modal active onBackgroundClick={() => setAddEmailVisible(false)}>
          <Flex width="100%" backgroundColor="#2C2C2C" flexDirection="column">
            <Flex
              mr="10px"
              mt="6px"
              ml="auto"
              css={{
                cursor: 'pointer',
              }}
              onClick={() => setAddEmailVisible(false)}
            >
              <CrossIcon />
            </Flex>
            <Flex minHeight="80px" alignItems="center" flexDirection="column" px="50px">
              <Flex flexDirection="row">
                <Flex>
                  <FormInput
                    remove={email.length > NUMBERS.ZERO && cleanEmail}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrorEmailMsg('');
                      setErrorEmails([]);
                    }}
                    onKeyDown={handleEnterSubmit}
                    value={email}
                    placeholder="insert comma separated emails"
                    styleBox={{ height: '50px', width: '400px' }}
                    available
                  />
                </Flex>
                <Flex flexDirection="row" justifyContent="flex-end" ml="15px">
                  <Flex width="100px" mt="6px">
                    <Button
                      disabled={loadingEmail}
                      onClick={() => addEmail()}
                      style={{ height: '36px' }}
                      onKeyDown={handleEnterSubmit}
                    >
                      ADD
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" pb="30px" flexDirection="column">
                {errorEmailMsg && (
                  <Flex>
                    <RegularText color="#E06C75" mediumWeight>
                      {errorEmailMsg}
                    </RegularText>
                  </Flex>
                )}
                {errorEmails.length > 0 && (
                  <Flex flexDirection="column" mt="5px">
                    {errorEmails.map((mail) => (
                      <Flex key={mail}>
                        <RegularText>{mail}</RegularText>
                      </Flex>
                    ))}
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      )}
      {addUserVisible && (
        <Modal 
          style={{ overflow: 'hidden' }}
          margin="5% auto auto auto"
          active
          onBackgroundClick={() => {
            searchInvites(searchString, comboAllowState.value);
            setAddUserVisible(false);
          }}
        >
          <Flex width="100%" backgroundColor="#2C2C2C" flexDirection="column">
            <Flex
              mr="10px"
              mt="6px"
              ml="auto"
              css={{
                cursor: 'pointer',
              }}
              onClick={() => {
                searchInvites(searchString, comboAllowState.value);
                setAddUserVisible(false);
              }}
            >
              <CrossIcon />
            </Flex>
            <Flex alignItems="center" flexDirection="column">
              <AllowListUser eventId={userRouteMatch.params.eventId}/>
            </Flex>
          </Flex>
        </Modal>
      )}      
      {confirmationDeleteInviteText && (
        <ConfirmationModal
          onAccept={() => {
            delInvitation();
          }}
          onCancel={() => setConfirmationDeleteInviteText(null)}
          acceptLabel="DELETE INVITE"
          dangerDialog
        >
          <RegularText>{confirmationDeleteInviteText}</RegularText>
        </ConfirmationModal>
      )}      
    </Form>
  );
}
