import React, { useEffect } from 'react';
import { Flex } from 'reflexbox';
import { Menu } from 'boss-ui';
import { useRouteMatch } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import ScenarioQuestionsEdition from '../ScenarioQuestionsEdition';
import ScenarioQuestionsImportation from '../ScenarioQuestionsImportation';
import Media from 'react-media';
import { useAppContext } from '../../libs/context-lib';
import { APP_ACTIONS } from '../../libs/reducerAction-lib';

const getHorizontalMargins = (matches) => {
  if (matches.m) {
    return '20px';
  }
  if (matches.xl || matches.l) {
    return '15%';
  }
  return '20%';
};

export default function Scenario() {
  const { dispatch } = useAppContext();
  const route = useRouteMatch('/scenarios/:scenarioId');
  const scenarioId = route.params.scenarioId;

  const MENU_ITEMS = [
    {
      path: `/scenarios/${scenarioId}/questions`,
      id: 'menu-scenario-questions',
      label: 'questions',
    },
    { path: `/scenarios/${scenarioId}/import`, id: 'menu-scenario-import', label: 'import' },
  ];

  useEffect(() => {
    return () => {
      dispatch({
        type: APP_ACTIONS.SET_ROUTES,
        data: [],
      });
    };
  }, [dispatch]);

  return (
    <>
      <Menu menuItems={MENU_ITEMS} />
      <Media
        queries={{
          s: '(max-width: 1000px)',
          m: '(max-width: 1290px)',
          l: '(max-width: 1540px)',
          xl: '(min-width: 1880px)',
        }}
      >
        {(matches) => (
          <Flex
            justifyContent="center"
            mx={getHorizontalMargins(matches)}
            mb="5%"
            mt="60px"
            width="-webkit-fill-available"
          >
            <Switch>
              <Route path="/scenarios/:scenarioId/import" exact>
                <ScenarioQuestionsImportation />
              </Route>
              <Route path="/scenarios/:scenarioId/questions" exact>
                <ScenarioQuestionsEdition />
              </Route>
            </Switch>
          </Flex>
        )}
      </Media>
    </>
  );
}
