import React, { useState, useEffect, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { Flex, Box } from 'reflexbox';
import toLower from 'lodash/toLower';
import isempty from 'lodash.isempty';
import debounce from 'lodash/debounce';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  Button,
  Form,
  LinkedText,
  FormLeyend,
  ConfirmationModal,
  RegularText,
  Checkbox,
  ResourcesList,
  ContextMenu,
  LinkIcon,
} from 'boss-ui';
import Api from '../../api';
import { UserNameBadge } from '../../components';
import { SplunkSearchIcon } from '../../components/icons';
import { useAppContext, useEventContext } from '../../libs/context-lib';
import { useFormFields } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';
import { getParticipantChanges } from '../../libs/user-lib';
import { USER_ROLES } from '../../libs/constants';
import { joinSpecsAndAllocationsData, getResourcesLogsItems } from '../../libs/resource-lib';
import { getSrnGroupId, getConsumerGroupSrn } from '../../libs/event-lib';
import { generateSearchServerUrlBySpec } from '../../components/Resources/utils';
import { EVENT_ACTION } from '../../libs/reducerAction-lib';

const API = new Api();

const ButtonSet = (props) => {
  const { onSaveChanges, disableSaveChanges, changeUserState, isBanned, isAdmin, loadingUser } =
    props;
  return (
    <Flex mt="100px" flexDirection="row" justifyContent="flex-end" width={1}>
      {!isAdmin && !loadingUser && (
        <Flex>
          <Button danger onClick={changeUserState}>
            {isBanned ? 'UNBLOCK USER' : 'BLOCK USER'}
          </Button>
        </Flex>
      )}
      <Flex>
        <Button
          style={{ marginLeft: '10px' }}
          onClick={onSaveChanges}
          disabled={disableSaveChanges}
        >
          SAVE CHANGES
        </Button>
      </Flex>
    </Flex>
  );
};

const UserTeam = ({ user, eventId }) => {
  const { team, role } = user;
  const { dispatch } = useEventContext();
  const history = useHistory();
  const handleNavigateToTeam = () => {
    dispatch({ type: EVENT_ACTION.SET_TEAM, data: team });
    history.push(`/events/${eventId}/teams/${team.teamId}`);
  };
  if (team && team.name) {
    return (
      <p
        style={{
          color: '#a088a4',
          fontFamily: 'Roboto Mono',
          fontSize: '14px',
        }}
      >
        {toLower(role || 'member')} of &quot;
        <LinkedText hoverColor="#a088a4" onClick={handleNavigateToTeam}>
          {team.name}
        </LinkedText>
        &quot;
      </p>
    );
  }
  return (
    <p
      style={{
        fontFamily: 'Roboto Mono',
        fontSize: '14px',
        color: '#898989',
      }}
    >
      has no team
    </p>
  );
};

export default function ParticipantDetails() {
  const { state, dispatch } = useEventContext();
  const SKELETON_STYLE = {
    COLOR: useTheme().color.skeleton.color,
    EFFECT: useTheme().color.skeleton.highlightColor,
  };
  const lightColor = useTheme().color.text.light.color;
  const user = state.participant;
  const routeMatch = useRouteMatch('/events/:eventId/participants/:userId');
  const eventId = routeMatch.params.eventId;
  const userId = routeMatch.params.userId;
  const [userDisplayName, setUserDisplayName] = useState(user.name);
  const [baseUser, setBaseUser] = useState(user);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [validDisplayName, setValidDisplayName] = useState(true);
  const [fields, handleFieldChange] = useFormFields({
    name: user.name,
    isAdmin: false,
  });
  const [dirtyForm, setDirtyForm] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [isBanned, setBanned] = useState(false);
  const [resourcesSpec, setResourcesSpec] = useState([]);
  const [resourcesLogs, setResourcesLogs] = useState([]);
  const [loadingResources, setLoadingResources] = useState(false);
  const handleNameReset = () => {
    setValidDisplayName(true);
    handleFieldChange({ target: { value: userDisplayName, id: 'name' } });
  };
  const activeUser = useAppContext().state.user;
  const isSuperAdmin = activeUser.dynamoUser?.role === USER_ROLES.SUPERADMIN;
  const isActiveUser = activeUser.dynamoUser?.userId === userId;

  const onSaveChanges = async (e) => {
    e.preventDefault();
    const changes = getParticipantChanges(baseUser, fields);
    if (!isempty(changes)) {
      setLoading(true);
      try {
        await API.patch(
          'events',
          `/admin/events/${eventId}/users/${userId}`,
          {
            body: {
              ...changes,
            },
          }
        );
        setBaseUser({ ...baseUser, isAdmin: fields.isAdmin, name: fields.name });
      } catch (err) {
        onError(err);
      } finally {
        setLoading(false);
      }
    } else {
      setDirtyForm(false);
    }
  };

  const resetDirtyChanges = () => {
    handleFieldChange({ target: { value: baseUser.isAdmin, id: 'isAdmin' } });
    handleFieldChange({ target: { value: baseUser.name, id: 'name' } });
    setDirtyForm(false);
  };
  const changeUserState = async () => {
    setConfirmationModalVisible(false);
    setLoading(true);
    try {
      const url = `/admin/events/${eventId}/users/${userId}/ban`;

      if (isBanned) {
        await API.del('events', url);
      } else {
        await API.put('events', url);
      }
      setBanned(!isBanned);
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
      resetDirtyChanges();
    }
  };

  const onCheckBoxChange = (e) => {
    setDirtyForm(true);
    handleFieldChange({
      target: {
        value: e.target.checked,
        id: e.target.id,
      },
    });
  };

  const checkDisplayName = async (name) => {
    try {
      const data = await API.get('users', '/users/check-displayname', {
        queryStringParameters: {
          q: name,
        },
      });
      setValidDisplayName(data.user.available);
    } catch (e) {
      onError(e);
      setValidDisplayName(false);
    } finally {
      setSearchLoading(false);
    }
  };

  const delayedSearch = useCallback(
    debounce((q) => checkDisplayName(q), 300),
    []
  );

  const onNameChange = (e) => {
    handleFieldChange(e);
    if (e.target.value.length > 2) {
      setSearchLoading(true);
      setDirtyForm(true);
      delayedSearch(e.target.value);
    }
  };

  useEffect(() => {
    const getUserById = async (pUserId) => {
      setLoadingUser(true);
      try {
        const rq = await API.get(
          'events',
          `/admin/events/${eventId}/users/${pUserId}`
        );
        dispatch({ type: EVENT_ACTION.SET_PARTICIPANT, data: rq.participant });
        const isAdmin = rq.participant.role === USER_ROLES.ADMIN;
        setBanned(rq.participant.bannedAt);
        setBaseUser({ team: rq.team, ...rq.participant, isAdmin });
        setUserDisplayName(rq.participant.name);
        // update preloaded user
        handleFieldChange({
          target: { value: rq.participant.name, id: 'name' },
        });
        handleFieldChange({
          target: {
            value: isAdmin,
            id: 'isAdmin',
          },
        });
        setLoadingUser(false);
      } catch (err) {
        onError(err);
      }
    };
    getUserById(userId);
    return () => API.abortCurrentRequest();
  }, []);

  useEffect(() => {
    const getSpecs = async (eventId) => {
      const groupId = getSrnGroupId(eventId);
      const groupSrn = getConsumerGroupSrn(eventId, userId);
      setLoadingResources(true);
      try {
        const [specs, allocations] = await Promise.all([
          API.get('events', `/groups/${groupId}/specs`),
          API.get('events', `/admin/groups/${groupSrn}/allocations`),
        ]);
        if (specs && allocations) {
          const tmpResources = joinSpecsAndAllocationsData(specs.specs, allocations.allocations);
          setResourcesSpec(tmpResources);
          setResourcesLogs(getResourcesLogsItems(tmpResources));
        }
      } catch (e) {
        onError(e);
      } finally {
        setLoadingResources(false);
      }
      return [];
    };
    if (eventId) {
      getSpecs(eventId);
    }
  }, [state.event]);

  const disableSaveChanges = !dirtyForm || searchLoading || loading || !validDisplayName;

  return (
    <Flex justifyContent="center" align="center">
      <Form noBorder refreshing={loading}>
        <Box px="60px" py="40px" width="924px">
          <UserNameBadge
            editionAllowed
            name={fields.name}
            validating={searchLoading}
            available={validDisplayName}
            onNameChange={onNameChange}
            onNameReset={handleNameReset}
            onNameChanged={() => setUserDisplayName(fields.name)}
            isBanned={isBanned}
          />
          <Flex
            ml="136px"
            style={{
              fontFamily: 'Roboto Mono',
              color: '#6C6C6C',
              fontSize: '16px',
            }}
            width={1}
          >
            {loadingUser ? (
              <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
                <Skeleton width={350} />
              </SkeletonTheme>
            ) : (
              <>
                <Flex>{baseUser.email}</Flex>
                <Flex mx="9px">-</Flex>
                <Flex>{baseUser.confirmationNumber}</Flex>
              </>
            )}
          </Flex>
          <Flex mt="36px">
            {loadingUser ? (
              <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
                <Skeleton width={250} />
              </SkeletonTheme>
            ) : (
              <UserTeam user={baseUser} eventId={eventId} />
            )}
          </Flex>
          <Flex mt="22px" width={1}>
            <Flex mr="4px">
              {loadingUser ? (
                <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
                  <Skeleton circle width="15px" height="15px" />
                </SkeletonTheme>
              ) : (
                <>
                  {!isBanned && (
                    <label>
                      <Checkbox
                        checked={fields.isAdmin}
                        onChange={onCheckBoxChange}
                        id="isAdmin"
                        disabled={!isSuperAdmin || isActiveUser}
                      />
                    </label>
                  )}
                </>
              )}
            </Flex>
            {!isBanned && <FormLeyend size="12px">Admin privileges</FormLeyend>}
          </Flex>
          <Box mt="24px">
            <Flex justifyContent="space-between">
              <RegularText fontSize="14px" mediumWeight>
                Resources
              </RegularText>
              <Flex ml="auto">
                <ContextMenu
                  button={
                    <Flex px="8px" py="6px">
                      <SplunkSearchIcon size="14px" />
                    </Flex>
                  }
                >
                  {resourcesLogs.map((spec) => (
                    <Flex
                      py="6px"
                      px="8px"
                      key={spec.resourceSpecSrn}
                      alignItems="center"
                      width={1}
                      onClick={() =>
                        window.open(
                          generateSearchServerUrlBySpec(spec.resourceSpecSrn, user.userId),
                          '_blank'
                        )
                      }
                    >
                      <Flex mr="6px">
                        <LinkIcon size="14px" />
                      </Flex>
                      <LinkedText color={lightColor}>{spec.name} logs</LinkedText>
                    </Flex>
                  ))}
                </ContextMenu>
              </Flex>
            </Flex>
            <ResourcesList loading={loadingResources} resources={resourcesSpec} />
          </Box>
          <ButtonSet
            onSaveChanges={onSaveChanges}
            disableSaveChanges={disableSaveChanges}
            isBanned={isBanned}
            isAdmin={baseUser.isAdmin}
            changeUserState={() => setConfirmationModalVisible(true)}
            loadingUser={loadingUser}
          />
        </Box>
      </Form>
      {confirmationModalVisible && (
        <ConfirmationModal
          dangerDialog
          onCancel={() => setConfirmationModalVisible(false)}
          onAccept={changeUserState}
          acceptLabel={isBanned ? 'UNBLOCK USER' : 'BLOCK USER'}
        >
          <RegularText>
            Are you sure you want to {isBanned ? 'unblock' : 'block'} this user?
          </RegularText>
        </ConfirmationModal>
      )}
    </Flex>
  );
}
