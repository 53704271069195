import React from 'react';
import { Flex, Box } from 'reflexbox';
import styled from 'styled-components';
import { Button, Modal, QuestionIcon, FormLabel, Tooltip, Select } from 'boss-ui';
import { useFormFields } from '../../libs/hooks-lib';
import FormInput from '../FormInput';

const SELECT_EXPIRES_OPTIONS = [
  { value: null, label: 'Never' },
  { value: 1, label: '1 hour' },
  { value: 6, label: '6 hour' },
  { value: 12, label: '12 hour' },
  { value: 24, label: '1 day' },
];

const Background = styled(Flex)`
  background-color: ${(props) => props.theme.color.form.background};
  padding: ${(props) => props.theme.margin.m};
`;

export default function CreateLinkInvite({ onAccept, onCancel, loading }) {
  const [form, handleFormChange] = useFormFields({
    campaignName: '',
    expiresIn: null,
    maxUsers: null,
  });

  return (
    <Modal
      style={{ overflow: 'hidden' }}
      margin="5% auto auto auto"  
      onBackgroundClick={onCancel}
    >
      <Background justifyContent="center">
        <Box width="300px">
          <Box mb="30px">
            <FormLabel>Campaign name</FormLabel>
            <FormInput
              autoFocus
              id="campaignName"
              value={form.campaignName}
              onChange={handleFormChange}
              placeholder="Enter invite name"
            />
          </Box>
          <Box style={{ position: 'relative' }} mb="30px">
            <FormLabel>Expires in</FormLabel>
            <Select
              value={form.expiresIn}
              onChange={(e) => handleFormChange({ target: { value: e, id: 'expiresIn' } })}
              options={SELECT_EXPIRES_OPTIONS}
            />
          </Box>
          <Flex width={1 / 2}>
            <Box style={{ position: 'relative' }}>
              <FormLabel>User limit</FormLabel>
              <FormInput
                id="maxUsers"
                value={form.maxUsers}
                onChange={handleFormChange}
                placeholder="no limit"
                type="number"
              />
              <Flex
                data-tip
                data-for="maxUsers"
                style={{
                  position: 'absolute',
                  right: '34px',
                  top: '0px',
                }}
              >
                <QuestionIcon />
              </Flex>
              <Tooltip id="maxUsers">Number of users that can use this invite</Tooltip>
            </Box>
          </Flex>
          <Flex justifyContent="right">
            <Flex ml="auto" width={2 / 3} mt="40px" justifyContent="space-between">
              <Box width={3 / 7}>
                <Button secondary onClick={onCancel}>
                  CANCEL
                </Button>
              </Box>
              <Box width={3 / 7}>
                <Button
                  onClick={() => onAccept(form)}
                  disabled={form.campaignName.length === 0 || loading}
                >
                  CREATE
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Background>
    </Modal>
  );
}
