import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Auth, API } from 'aws-amplify';
import { Flex } from 'reflexbox';
import { Nav, LoaderIcon } from 'boss-ui';
import SplunkRum from '@splunk/otel-web';
import get from 'lodash.get';
import { AppContext } from './libs/context-lib';
import { formatLoggedInUser } from './libs/cognito-lib';
import Theme from './theme';
import Routes from './routes';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { setUserHeaders } from './libs/utils-lib';
import { onError } from './libs/error-lib';
import { useQuery, useBreadcrumb } from './libs/hooks-lib';
import { useAppReducer } from './libs/reducer-lib';
import { APP_ACTIONS } from './libs/reducerAction-lib';
import { ToastContainerNotification } from './components';
import { validateUserSession } from './libs/user-lib';

function App() {
  const [state, dispatch] = useAppReducer();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const query = useQuery();
  const internalRedirect = query.get('redirect');
  const location = useLocation();
  const match = useRouteMatch(location.pathname);
  const [breadcrumbs, setBreadcrumbs] = useBreadcrumb();

  useEffect(() => {
    const firstRoutePath = location.pathname === '/' ? '' : location.pathname.split('/')[1];
    // there is some routing that match with the location url
    let matchedRoute = state.routes.find(
      (route) => route.path && route.path.split('/')[1] === firstRoutePath
    );
    if (firstRoutePath !== '' && matchedRoute) {
      setBreadcrumbs(state.routes, match);
    } else {
      setBreadcrumbs([], match);
    }
  }, [location, state.routes]);

  const setDynamoUser = async (cognitoUser) => {
    try {
      const registeredUser = await API.get('users', '/user');
      if (!registeredUser.user) {
        dispatch({ type: APP_ACTIONS.SET_COGNITO_USER, data: cognitoUser });
        history.push('/firstLogin');
        return;
      }
      const combinedUser = { ...cognitoUser, dynamoUser: registeredUser.user };
      if (validateUserSession(combinedUser)) {
        dispatch({ type: APP_ACTIONS.SET_COGNITO_USER, data: cognitoUser });
        dispatch({ type: APP_ACTIONS.SET_DYNAMO_USER, data: registeredUser.user });
        setUserHeaders(combinedUser);
        SplunkRum?.setGlobalAttributes({
          'user.id': registeredUser.user.userId,
          'user.email': registeredUser.user.email,
          'user.username': registeredUser.user.displayName,
        });
      } else {
        history.push('/not-allowed');
      }
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    async function setLoggedInUser() {
      try {
        const loggedUser = formatLoggedInUser(await Auth.currentAuthenticatedUser());
        await setDynamoUser(loggedUser);
      } catch (e) {
        history.push(
          internalRedirect
            ? `/login?redirect=${internalRedirect}`
            : `/login?redirect=${pathname}${search}`
        );
      } finally {
        setIsAuthenticating(false);
      }
    }

    // avoid setting the user in app state if path is the saml idp redirection
    if (pathname !== '/successLogin') {
      setLoggedInUser();
    } else {
      setIsAuthenticating(false);
    }
  }, []);

  function handleLogout() {
    history.push('/logout');
  }

  if (isAuthenticating) {
    return (
      <AppContext.Provider value={state}>
        <Flex mt="15%" alignItems="center" justifyContent="center" flexDirection="column">
          <LoaderIcon appType={state.appType} />
        </Flex>
      </AppContext.Provider>
    );
  }

  const userDisplayName = get(state.user, 'dynamoUser.displayName');
  const userEmail = get(state.user, 'dynamoUser.email');
  const hideUserProfileActions = !state.user || !state.user.dynamoUser;

  return (
    <div className="app-container">
      <ThemeProvider theme={Theme}>
        <ToastContainerNotification
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AppContext.Provider
          value={{
            state,
            dispatch,
            APP_ACTIONS,
          }}
        >
          <div className="app-header">
            <Nav
              title={state.navTitle}
              breadcrumbs={breadcrumbs}
              onLogOut={handleLogout}
              userDisplayName={userDisplayName}
              userEmail={userEmail}
              loggedIn={state.user}
              hideUserSettings={hideUserProfileActions}
            />
          </div>
          <Routes />
        </AppContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
