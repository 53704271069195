import { RegularText, SpinnerIcon, ConfirmationModal } from 'boss-ui';

export default function DeleteConfirmationModal({message, loading, onAccept, onCancel}) {
  return (
    <ConfirmationModal
      onAccept={onAccept}
      onCancel={onCancel}
      acceptLabel={loading ? <SpinnerIcon size={20} color="#898989" /> : 'YES'}
      dangerDialog={true}
      loading={loading}
    >
      <RegularText>{message}</RegularText>
    </ConfirmationModal>
  )
}