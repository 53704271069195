export const EVENT_REGISTRATION_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};
export const EVENT_HINTS_STRATEGIES = {
  PURCHASE: 'PURCHASE',
  TIME: 'TIME',
};
export const EVENT_SCORING = {
  LEGACY: 'LEGACY'
};
export const EVENT_ACCESS_TYPE = {
  RESTRICTED: 'RESTRICTED',
  OPEN: 'OPEN'
};
export const EVENT_TYPES = [
  {
    label: 'BOO',
    value: 'NOC'
  },
  {
    label: 'BOTS',
    value: 'SOC'
  }
]