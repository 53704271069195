import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Menu } from 'boss-ui';
import { Flex } from 'reflexbox';
import { RankingCalculator, EventFloatingBox } from '../../components';
import Event from '../Event';
import { EventContext, useAppContext } from '../../libs/context-lib';
import { filterMenuByRole } from '../../libs/user-lib';
import { useEventReducer } from '../../libs/reducer-lib';
import SearchEvents from '../../components/Events/SearchEvents';

export default function Events() {
  const appState = useAppContext().state;
  const [state, dispatch] = useEventReducer();

  return (
    <Flex flexDirection="column">
      <EventContext.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <Switch>
          <Route path="/events" exact>
            <Menu menuItems={filterMenuByRole(appState.user)} />
            <Flex width={1} justifyContent="center">
              <Flex mb="5%" flexDirection="column" mt="60px">
                <SearchEvents />
              </Flex>
            </Flex>
          </Route>
          <Route path="/events/:eventId">
            <EventFloatingBox />
            <RankingCalculator />
            <Event />
          </Route>
        </Switch>
      </EventContext.Provider>
    </Flex>
  );
}
