import React, { useState, useEffect } from 'react';
import toLower from 'lodash/toLower';
import cloneDeep from 'lodash.clonedeep';
import find from 'lodash.find';
import {
  ContextMenu,
  ContextMenuItem,
  LinkedText,
  RegularText,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableItemSkeleton,
  TableRow,
  Tooltip,
  UnregularButton,
  InfoIcon,
  TreeDotsIcon,
} from 'boss-ui';
import { Flex } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import SearchTeamUsers from './SearchTeamUsers';
import { TEAM_MEMBER_ROLE, USER_INVITE_STATE } from '../../../libs/constants';
import { useAppContext, useEventContext } from '../../../libs/context-lib';
import { isTodayAfterThan } from '../../../libs/dates-lib';

function TeamMembersTable(props) {
  const {
    participants,
    setParticipants,
    initialNroCaptains,
    loadingParticipants,
    handleModal,
    screenWidthSchema,
  } = props;
  const user = useAppContext().state.user;
  const event = useEventContext().state.event;
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [filteredParticipants, setFilterdParticipants] = useState([]);
  const [nroCaptains, setNroCaptains] = useState(initialNroCaptains);
  const history = useHistory();
  const canEditTeam = !isTodayAfterThan(event.endDate);

  const addMember = (newMember, role) => {
    if (role === TEAM_MEMBER_ROLE.CAPTAIN && user.state === USER_INVITE_STATE.ACCEPTED) {
      setNroCaptains(nroCaptains + 1);
    }
    setParticipants((members) => [
      ...members,
      { ...{ state: USER_INVITE_STATE.PENDING, dirty: true, ...newMember }, role },
    ]);
  };

  const removeTeamMember = (member) => {
    const teamMembers = cloneDeep(participants);
    const teamMember = find(
      teamMembers,
      (p) => p.userId === member.userId && p.state === member.state
    );
    if (!teamMember) {
      return;
    }
    if (
      teamMember.role === TEAM_MEMBER_ROLE.CAPTAIN &&
      teamMember.state === USER_INVITE_STATE.ACCEPTED
    ) {
      setNroCaptains(nroCaptains - 1);
    }
    if (teamMember.recentlyAdded) {
      setParticipants(teamMembers.filter((u) => u.userId !== teamMember.userId));
    } else {
      // if the user hasn't accepted the invitation invite should be canceled otherwise rejected
      teamMember.state =
        teamMember.state === USER_INVITE_STATE.PENDING
          ? USER_INVITE_STATE.REJECTED
          : USER_INVITE_STATE.CANCELLED;
      teamMember.dirty = true;
      setParticipants(teamMembers);
    }
  };

  const changeMemberRole = (member, role) => {
    const teamMembers = cloneDeep(participants);
    const teamMember = find(
      teamMembers,
      (p) => p.userId === member.userId && p.state === member.state
    );
    if (!teamMember) {
      return;
    }
    if (teamMember.state === USER_INVITE_STATE.ACCEPTED) {
      if (role === TEAM_MEMBER_ROLE.CAPTAIN) {
        setNroCaptains(nroCaptains + 1);
      } else {
        setNroCaptains(nroCaptains - 1);
      }
    }
    teamMember.role = role;
    teamMember.dirty = true;
    setParticipants(teamMembers);
  };

  const onRemoveMemberClick = (member) => {
    if (member.role === TEAM_MEMBER_ROLE.CAPTAIN) {
      if (nroCaptains > 1 || member.state === USER_INVITE_STATE.PENDING) {
        removeTeamMember(member);
      }
    } else {
      removeTeamMember(member);
    }
  };

  const onChangeRoleMemberClick = (member) => {
    if (member.role === TEAM_MEMBER_ROLE.CAPTAIN) {
      if (nroCaptains > 1 || member.state === USER_INVITE_STATE.PENDING) {
        changeMemberRole(member, TEAM_MEMBER_ROLE.REGULAR);
      }
    } else {
      changeMemberRole(member, TEAM_MEMBER_ROLE.CAPTAIN);
    }
  };

  const disableChangeMember = (member) => {
    if (member.role !== TEAM_MEMBER_ROLE.CAPTAIN) {
      return false;
    }
    if (member.state === USER_INVITE_STATE.ACCEPTED && (nroCaptains === 1 || member.creator)) {
      return true;
    }
    return false;
  };

  const disableRemoveMember = (member) => {
    if (member.role !== TEAM_MEMBER_ROLE.CAPTAIN) {
      return false;
    }
    if (member.state === USER_INVITE_STATE.ACCEPTED && (nroCaptains === 1 || member.creator)) {
      return true;
    }
    if (user.dynamoUser.userId === member.userId) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setFilterdParticipants(
      participants.filter(
        (p) => !(p.state === USER_INVITE_STATE.CANCELLED || p.state === USER_INVITE_STATE.REJECTED)
      )
    );
  }, [participants]);

  useEffect(() => {
    if (handleModal) {
      handleModal(addUserModalVisible);
    }
  }, [addUserModalVisible]);

  useEffect(() => {
    setNroCaptains(initialNroCaptains);
  }, [initialNroCaptains]);

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>Members</TableHeader>
            <TableHeader>Role</TableHeader>
            {canEditTeam ? (
                  <TableHeader center width="5%">
                    <UnregularButton onClick={() => setAddUserModalVisible(true)} active>
                      +
                    </UnregularButton>
                  </TableHeader>
              ) : (<TableHeader width="5%" />)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {loadingParticipants ? (
            <TableItemSkeleton numberOfCells={3} />
          ) : (
            filteredParticipants.map((member) => {
              const isCaptain = member.role === TEAM_MEMBER_ROLE.CAPTAIN;
              return (
                <TableRow captain={isCaptain} key={member.name}>
                  <TableData>
                    <Flex style={{ position: 'relative' }} alignItems="center">
                      {member.state === USER_INVITE_STATE.PENDING ? (
                        <>
                          <Flex
                            alignItems="center"
                            data-tip
                            data-for={member.name}
                            style={{
                              position: 'absolute',
                            }}
                          >
                            {member.name}
                            <Flex ml="6px">
                              <InfoIcon size="16px" />
                            </Flex>
                          </Flex>
                          <Tooltip id={member.name} place="right">
                            {member.name} has not confirmed yet
                          </Tooltip>
                        </>
                      ) : (
                        <RegularText mediumWeight fontSize="14px">
                          <LinkedText
                            {...(isCaptain
                              ? { color: '#de935f', hoverColor: '#de935f' }
                              : { color: '#D0D0D0', hoverColor: '#D0D0D0' })}
                            onClick={() => {
                              history.push(`/events/${event.eventId}/participants/${member.userId}`);
                            }}
                          >
                            {member.name}
                          </LinkedText>
                        </RegularText>
                      )}
                    </Flex>
                  </TableData>
                  <TableData>{toLower(member.role)}</TableData>
                  <TableData>
                    {canEditTeam && (
                      <ContextMenu button={<TreeDotsIcon />}>
                        <>
                          <ContextMenuItem>
                            <LinkedText
                              disabled={disableRemoveMember(member)}
                              onClick={() => onRemoveMemberClick(member)}
                            >
                              Remove {toLower(member.role)}
                            </LinkedText>
                          </ContextMenuItem>
                          <ContextMenuItem>
                            <LinkedText
                              disabled={disableChangeMember(member)}
                              onClick={() => onChangeRoleMemberClick(member)}
                            >
                              {isCaptain ? 'Demote to member' : 'Promote to captain'}
                            </LinkedText>
                          </ContextMenuItem>
                        </>
                      </ContextMenu>
                    )}
                    </TableData>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>

      {addUserModalVisible && (
        <SearchTeamUsers
          onClose={() => setAddUserModalVisible(false)}
          participants={filteredParticipants}
          addParticipants={addMember}
          screenWidthSchema={screenWidthSchema}
        />
      )}
    </>
  );
}

export default TeamMembersTable;
