import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Box } from 'reflexbox';
import { useHistory, useRouteMatch } from 'react-router-dom';
import debounce from 'lodash.debounce';
import isempty from 'lodash.isempty';
import { Button, ConfirmationModal, Form, RegularText, ResourcesList } from 'boss-ui';
import Media from 'react-media';
import Api from '../../api';
import {
  AccessibilityToggle,
  TeamMembersTable,
  TeamNameBadge,
  VisibilityToggle,
  DirectPointConceiver,
} from '../../components';
import { useFormFields } from '../../libs/hooks-lib';
import {
  TEAM_ACCESSIBILITY,
  TEAM_VISIBILITY,
  USER_INVITE_STATE,
  TEAM_MEMBER_ROLE,
} from '../../libs/constants';
import { onError } from '../../libs/error-lib';
import {
  getTeamChanges,
  VISIBILITY_VALUE,
  ACCESSIBILITY_VALUE,
  getStyleMedia,
} from '../../libs/team-lib';
import { joinSpecsAndAllocationsData } from '../../libs/resource-lib';
import { getSrnGroupId, getConsumerGroupSrn } from '../../libs/event-lib';
import { useEventContext } from '../../libs/context-lib';
import { EVENT_ACTION } from '../../libs/reducerAction-lib';

const DISBAND_TEAM_CONFIRMATION_TEXT = 'Are you sure on disbanding this team?';
const API = new Api();

const ButtonSet = (props) => {
  const [confirmationDisbandText, setconfirmationDisbandText] = useState(null);
  const { onDisbandTeam, loading, screenWidthSchema } = props;

  return (
    <Flex mt="100px">
      <Flex
        alignItems="flex-end"
        justifyContent="flex-end"
        width={getStyleMedia(screenWidthSchema).btnSetLeaveWidth}
      >
        <Flex>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setconfirmationDisbandText(DISBAND_TEAM_CONFIRMATION_TEXT);
            }}
            danger
            disabled={loading}
            id="disbandBtn"
          >
            DISBAND TEAM
          </Button>
        </Flex>
      </Flex>
      {confirmationDisbandText && (
        <ConfirmationModal
          onAccept={(e) => {
            e.preventDefault();
            setconfirmationDisbandText(null);
            onDisbandTeam();
          }}
          onCancel={() => setconfirmationDisbandText(null)}
          acceptLabel="DISBAND TEAM"
          dangerDialog
        >
          <RegularText>{confirmationDisbandText}</RegularText>
        </ConfirmationModal>
      )}
    </Flex>
  );
};

export default function TeamDetails() {
  const { state, dispatch } = useEventContext();
  const userRouteMatch = useRouteMatch('/events/:eventId/teams/:teamId');
  const [participants, setParticipants] = useState([]);
  const [teamCustomScore, setTeamCustomScore] = useState({});
  const [baseTeam, setBaseTeam] = useState(state.team);
  const [teamName, setTeamName] = useState(state.team.name);
  const [teamCaptains, setNroCaptains] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isLoadingTeam, loadingTeam] = useState(true);
  const [loadingResources, setLoadingResources] = useState(false);
  const [isAssigningPoints, setAssigningPoints] = useState(false);
  const [validTeamName, setValidTeamName] = useState(true);
  const [modalParticipants, setModalParticipants] = useState(false);
  const [resourcesSpec, setResourcesSpec] = useState([]);
  const [fields, handleFieldChange] = useFormFields({
    name: state.team.name ? state.team.name : '',
    visibility: state.team.visibility === TEAM_VISIBILITY.PUBLIC,
    accessibility: state.team.accessibility === TEAM_ACCESSIBILITY.OPEN,
  });
  const history = useHistory();
  const TEAM_ROOT_PATH = `/events/${userRouteMatch.params.eventId}/teams`;

  const loadParticipants = async () => {
    try {
      const rq = await API.get(
        'teams',
        `/admin/events/${userRouteMatch.params.eventId}/teams/${state.team.teamId}`
      );
      setBaseTeam(rq.team);
      setParticipants(rq.team.participants);
    } catch (err) {
      onError(err);
    }
  };

  const saveData = async (changes) => {
    setLoading(true);
    try {
      await API.patch('teams', `/admin/teams/${state.team.teamId}`, {
        body: { ...changes, eventId: userRouteMatch.params.eventId },
      });
      loadParticipants();
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleNameReset = () => {
    setValidTeamName(true);
    handleFieldChange({ target: { value: teamName, id: 'name' } });
  };

  const onDisbandTeam = async () => {
    setLoading(true);
    try {
      await API.del('teams', `/admin/teams/${state.team.teamId}`);
      history.push(TEAM_ROOT_PATH);
    } catch (e) {
      onError(e);
      setLoading(false);
    }
  };

  const checkTeamName = async (name) => {
    try {
      const data = await API.get(
        'events',
        `/events/${userRouteMatch.params.eventId}/check-teamname`,
        {
          queryStringParameters: {
            q: name,
          },
        }
      );
      setValidTeamName(data.team.available);
    } catch (e) {
      onError(e);
      setValidTeamName(false);
    } finally {
      setSearchLoading(false);
    }
  };

  const delayedSearch = useCallback(
    debounce((q) => checkTeamName(q), 300),
    []
  );

  const onNameChange = (e) => {
    handleFieldChange(e);
    if (e.target.value.length > 2) {
      setSearchLoading(true);
      delayedSearch(e.target.value);
    }
  };

  useEffect(() => {
    const getTeamById = async (teamId) => {
      loadingTeam(true);
      try {
        const rq = await API.get(
          'teams',
          `/admin/events/${userRouteMatch.params.eventId}/teams/${teamId}`
        );
        setBaseTeam(rq.team);
        setTeamCustomScore(rq.customScore);
        setParticipants(rq.team.participants);
        dispatch({ type: EVENT_ACTION.SET_TEAM, data: rq.team });
        // update preloaded team
        handleFieldChange({ target: { value: rq.team.name, id: 'name' } });
        handleFieldChange({
          target: {
            value: rq.team.visibility === TEAM_VISIBILITY.PUBLIC,
            id: 'visibility',
          },
        });
        handleFieldChange({
          target: {
            value: rq.team.accessibility === TEAM_ACCESSIBILITY.OPEN,
            id: 'accessibility',
          },
        });
        loadingTeam(false);
      } catch (err) {
        onError(err);
      }
    };

    getTeamById(userRouteMatch.params.teamId);
    return () => API.abortCurrentRequest();
  }, []);

  useEffect(() => {
    const getSpecs = async (eventId) => {
      setLoadingResources(true);
      try {
        if (participants && participants.length) {
          const firstParticipantAccepted = participants.find(
            (p) => p.state === USER_INVITE_STATE.ACCEPTED
          );
          if (firstParticipantAccepted) {
            const groupId = getSrnGroupId(eventId);
            const groupSrn = getConsumerGroupSrn(eventId, firstParticipantAccepted.userId);
            const [specs, allocations] = await Promise.all([
              API.get('events', `/groups/${groupId}/specs`),
              API.get('events', `/admin/groups/${groupSrn}/allocations`),
            ]);
            if (specs && allocations) {
              setResourcesSpec(joinSpecsAndAllocationsData(specs.specs, allocations.allocations));
            }
          }
        }
      } catch (e) {
        onError(e);
      } finally {
        setLoadingResources(false);
      }
      return [];
    };
    if (state.event.eventId) {
      getSpecs(state.event.eventId);
    }
  }, [state.event.eventId, participants]);

  useEffect(() => {
    if (isempty(state.team.teamId)) {
      return;
    }
    let captains = 0;
    participants.forEach((p) => {
      if (p.role === TEAM_MEMBER_ROLE.CAPTAIN && p.state === USER_INVITE_STATE.ACCEPTED) {
        captains++;
      }
    });
    try {
      const changes = getTeamChanges(baseTeam, { ...fields, participants });
      if (changes.participants && changes.participants.length > 0) {
        saveData(changes);
      }
      setNroCaptains(captains);
    } catch (err) {
      onError(err);
    }
  }, [participants]);

  const onParticipantsEdition = (theParticipants) => {
    return setParticipants(theParticipants);
  };

  const onSwitchChange = (e) => {
    if (loading) {
      return;
    }
    handleFieldChange(e);
    const obj = {};
    obj[e.target.id] =
      e.target.id === 'visibility'
        ? VISIBILITY_VALUE[e.target.value]
        : ACCESSIBILITY_VALUE[e.target.value];
    saveData(obj);
  };

  const onAssignPoints = async ({ points, reason }) => {
    setAssigningPoints(true);
    try {
      const score =
        Number(points) > 0
          ? { penalty: 0, bonus: Number(points) }
          : { penalty: Math.abs(points), bonus: 0 };
      const rq = await API.post(
        'teams',
        `/admin/events/${userRouteMatch.params.eventId}/teams/${state.team.teamId}/score`,
        {
          body: { score, description: reason },
        }
      );
      setTeamCustomScore(rq);
    } catch (e) {
      onError(e);
    } finally {
      setAssigningPoints(false);
    }
  };

  const handleModal = (modalOpen) => {
    setModalParticipants(modalOpen);
  };

  const onNameChanged = () => {
    if (fields.name !== baseTeam.name) {
      setTeamName(fields.name);
      saveData({ name: fields.name });
    }
  };

  return (
    <Media
      queries={{
        s: '(max-width: 600px)',
        m: '(max-width: 766px)',
        l: '(max-width: 940px)',
      }}
    >
      {(screenWidthSchema) => (
        <Flex
          mb="50px"
          justifyContent="center"
          align="center"
          width={getStyleMedia(screenWidthSchema).flexWidth}
          px={getStyleMedia(screenWidthSchema).flexPx}
        >
          <Form
            noBorder
            refreshing={loading && !modalParticipants}
            style={{ width: getStyleMedia(screenWidthSchema).formWidth }}
          >
            <Box
              py="40px"
              px={getStyleMedia(screenWidthSchema).boxPx}
              width={getStyleMedia(screenWidthSchema).boxWidth}
            >
              <TeamNameBadge
                mobile={getStyleMedia(screenWidthSchema).teamNameMobile}
                editionAllowed
                name={fields.name}
                validating={searchLoading}
                available={validTeamName}
                onNameChange={onNameChange}
                onNameReset={handleNameReset}
                onNameChanged={onNameChanged}
              />
              {!isLoadingTeam ? (
                <Flex
                  mt="40px"
                  width={getStyleMedia(screenWidthSchema).switchButtonsWidth}
                  justifyContent="space-between"
                  flexDirection={getStyleMedia(screenWidthSchema).switchButtonsFlex}
                >
                  <VisibilityToggle
                    fieldValue={fields.visibility}
                    handleFieldChange={onSwitchChange}
                  />
                  <AccessibilityToggle
                    fieldValue={fields.accessibility}
                    handleFieldChange={onSwitchChange}
                  />
                </Flex>
              ) : (
                <Flex my="35px" />
              )}
              <TeamMembersTable
                loadingParticipants={isLoadingTeam}
                participants={participants}
                setParticipants={onParticipantsEdition}
                initialNroCaptains={teamCaptains}
                contextAction="team-page:edit"
                handleModal={handleModal}
                screenWidthSchema={screenWidthSchema}
              />
              <Box mt="24px">
                <RegularText fontSize="14px" mediumWeight>
                  Team Resources
                </RegularText>
                <ResourcesList loading={loadingResources} resources={resourcesSpec} />
              </Box>
              <Box mt="24px">
                <DirectPointConceiver
                  onSend={onAssignPoints}
                  pointsHistory={teamCustomScore}
                  loading={isAssigningPoints}
                  screenWidthSchema={screenWidthSchema}
                />
              </Box>
              <ButtonSet
                loading={loading || searchLoading}
                onDisbandTeam={onDisbandTeam}
                screenWidthSchema={screenWidthSchema}
              />
            </Box>
          </Form>
        </Flex>
      )}
    </Media>
  );
}
