import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Flex } from 'reflexbox';
import TopScoreBoard from '../TopScoreBoard';
import ScoreBoard from '../ScoreBoard';
import TimelineBoard from '../TimelineBoard';
import { useAppContext, useEventContext } from '../../libs/context-lib';
import { APP_ACTIONS } from '../../libs/reducerAction-lib';

export default function Scoring() {
  const appDispatch = useAppContext().dispatch;
  const { state } = useEventContext();  
  const route = useRouteMatch('/events/:eventId');
  const eventId = route ? route.params.eventId : '';

  useEffect(() => {
    appDispatch({
      type: APP_ACTIONS.SET_ROUTES,
      data: [
        { path: `/events`, name: 'Events' },
        { path: `/events/${eventId}`, name: state.event.name },
        { path: `/events/${eventId}/scoring`, name: 'Scoring' },
      ],
    });
  }, [state.event, eventId, appDispatch]);

  return (
    <Switch>
      <Route path="/events/:eventId/scoring" exact>
        <Redirect to={`/events/${eventId}/scoring/top10`} />
      </Route>
      <Route path="/events/:eventId/scoring/top10" exact>
        <Flex width={1} justifyContent="center">
          <Flex mb="5%" flexDirection="column" mt="10px" width="100%">
            <TopScoreBoard eventId={eventId} />
          </Flex>
        </Flex>
      </Route>
      <Route path="/events/:eventId/scoring/allTeam" exact>
        <Flex width={1} justifyContent="center">
          <Flex mb="5%" flexDirection="column" mt="10px" width="100%">
            <ScoreBoard eventId={eventId} />
          </Flex>
        </Flex>
      </Route>
      <Route path="/events/:eventId/scoring/timeLine" exact>
        <Flex width={1} justifyContent="center">
          <Flex mb="5%" flexDirection="column" mt="10px" width="100%">
            <TimelineBoard eventId={eventId} />
          </Flex>
        </Flex>
      </Route>      
    </Switch>
  );
}
