import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import Api from '../../../api';
import { Flex } from 'reflexbox';
import {
  Form,
  FormLeyend,
  TableBody,
  TableHeader,
  Table,
  TableHead,
  TableRow,
  Paginator,
  TableData,
  LinkedText,
  TableItemSkeleton,
  RefreshIcon,
  Tooltip,
  HovereableFlex,
  RegularText,
  EnrollIcon,
  Select,
} from 'boss-ui';
import FormInput from '../../FormInput';
import { useDynamoPaginator } from '../../../libs/hooks-lib';
import { onError } from '../../../libs/error-lib';
import { useAppContext, useEventContext } from '../../../libs/context-lib';
import { USER_ROLES, EVENT_DELIVERY } from '../../../libs/constants';
import { EVENT_ACCESS_TYPE, EVENT_TYPES } from '../../../libs/Constants/event-constants';
import { APP_ACTIONS, EVENT_ACTION } from '../../../libs/reducerAction-lib';
import {
  MainContainer,
  FilterContainer,
  NameFilterContainer,
  PartialFilterContainer,
  ActionsContainer,
} from './styles';
import { getMomentDate } from '../../../libs/dates-lib';

const DEFAULT_PAGE_SIZE = 10;
const ZERO = 0;
const PAGE_STEP = 1;
const routes = [];
const API = new Api();

const getDeliveryType = (event) => {
  if (event.accessType === EVENT_ACCESS_TYPE.RESTRICTED) {
    return 'RESTRICTED';
  } else if (event.delivery === EVENT_DELIVERY.ON_DEMAND) {
    return 'ON DEMAND';
  }
  return 'NORMAL';
};

const getEventType = (type) => {
  if (type === 'NOC') {
    return 'BOO';
  }

  if (type === 'SOC') {
    return 'BOTS';
  }
};

const isAdminOnEvent = (event, isAdmin) => {
  return isAdmin && event.participant && event.participant.role === USER_ROLES.ADMIN;
};

const isEnrolled = (event) => {
  return event.participant && event.participant.enrolled;
};

const EVENT_TYPES_FILTER_VALUES = [{ label: 'ALL', value: '' }, ...EVENT_TYPES];

export default function SearchEvents() {
  const [{ pages, currentPage }, paginate] = useDynamoPaginator();
  const { dispatch } = useEventContext();
  const appDispatch = useAppContext().dispatch;
  const user = useAppContext().state.user;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [selectedType, setSelectedType] = useState(EVENT_TYPES_FILTER_VALUES[0]);
  const isSuperAdmin = user.dynamoUser && user.dynamoUser.role === USER_ROLES.SUPERADMIN;
  const isAdmin = user.dynamoUser && user.dynamoUser.role === USER_ROLES.ADMIN;

  const searchData = async (q = '', type = '', page = ZERO) => {
    setLoading(true);
    let lastEvaluatedKey;
    if (page > ZERO) {
      lastEvaluatedKey =
        page > currentPage
          ? pages[currentPage].nextPagePointer
          : pages[currentPage].lastPagePointer;
    }
    try {
      const result = await API.get('events', `/admin/events`, {
        queryStringParameters: {
          pageSize: DEFAULT_PAGE_SIZE,
          q,
          type,
          ...(lastEvaluatedKey && {
            ...lastEvaluatedKey,
          }),
        },
      });
      setSearchList(result.events);
      paginate(result.pagination, page);
      setLoading(false);
    } catch (e) {
      onError(e);
    }
  };

  const cleanBox = () => {
    setSearchString('');
    searchData('', selectedType.value);
  };

  const delayedQuery = useCallback(
    debounce((q, type) => searchData(q, type), 500),
    []
  );

  useEffect(() => {
    searchData();
    appDispatch({ type: APP_ACTIONS.SET_ROUTES, data: routes });
    return () => API.abortCurrentRequest();
  }, []);

  const goEvent = (event) => {
    dispatch({ type: EVENT_ACTION.SET_EVENT, data: event });
    history.push(`/events/${event.eventId}/scenarios`);
  };

  const onEnrollEvent = async (event) => {
    setLoading(true);
    try {
      await API.post('events', `/events/${event.eventId}/join`, {
        body: {
          name: user.dynamoUser.displayName,
          email: user.dynamoUser.email,
        },
      });
      goEvent(event);
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  const onTypeOptionChange = (type) => {
    if (type != selectedType) {
      setSelectedType(type);
      searchData(searchString, type.value);
    }
  };

  return (
    <Form noBorder>
      <MainContainer>
        <FilterContainer>
          <NameFilterContainer>
            <FormLeyend>Find an Event</FormLeyend>
            <FormInput
              search={searchString}
              remove={searchString.length > 0 && cleanBox}
              onChange={(e) => {
                setSearchString(e.target.value);
                delayedQuery(e.target.value, selectedType.value);
              }}
              value={searchString}
              placeholder="Search by Event Name"
              height="34px"
            />
          </NameFilterContainer>
          <PartialFilterContainer>
            <FormLeyend>Event Type</FormLeyend>
            <Select
              options={EVENT_TYPES_FILTER_VALUES}
              onChange={onTypeOptionChange}
              value={selectedType}
            />
          </PartialFilterContainer>
        </FilterContainer>
        <HovereableFlex data-tip data-for="refresh" width="20px" ml="10px" mb="12px">
          <RefreshIcon
            onClick={() => searchData(searchString, selectedType.value)}
            rotate={loading}
            color="#898989"
            hoverColor="#d0d0d0"
          />
          <Tooltip id="refresh">Refresh list</Tooltip>
        </HovereableFlex>
        <Flex flexDirection="column" justifyContent="space-between" height="100%">
          <Table>
            <TableHead>
              <TableRow height="41px">
                <TableHeader>Event</TableHeader>
                <TableHeader width="100px">Event Type</TableHeader>
                <TableHeader width="120px">Start Date</TableHeader>
                <TableHeader width="120px">Delivery Type</TableHeader>
                <TableHeader width="60px" />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableItemSkeleton numberOfCells={5} />
              ) : (
                searchList?.map((item) => (
                  <TableRow height="41px" key={item.eventId}>
                    <TableData>
                      {(isEnrolled(item) && isSuperAdmin) || isAdminOnEvent(item, isAdmin) ? (
                        <LinkedText onClick={() => goEvent(item)}>{item.name}</LinkedText>
                      ) : (
                        <RegularText fontSize="14px">{item.name}</RegularText>
                      )}
                    </TableData>
                    <TableData>{getEventType(item.type)}</TableData>
                    <TableData>
                      {item.startDate
                        ? getMomentDate({
                            date: item.startDate,
                            format: 'YYYY-MM-DD',
                            tz: item.startDateTimezone,
                          })
                        : ''}
                    </TableData>
                    <TableData>{getDeliveryType(item)}</TableData>
                    <TableData>
                      <ActionsContainer>
                        {!isEnrolled(item) && isSuperAdmin && (
                          <HovereableFlex data-tip data-for={`enroll${item.eventId}`}>
                            <EnrollIcon size="20px" onClick={() => onEnrollEvent(item)} />
                            <Tooltip id={`enroll${item.eventId}`}>Enroll</Tooltip>
                          </HovereableFlex>
                        )}
                      </ActionsContainer>
                    </TableData>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <Flex width={1} justifyContent="center">
            <Paginator
              isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
              onNext={() => searchData(searchString, selectedType.value, currentPage + PAGE_STEP)}
              isPrev={currentPage > 0}
              onPrev={() => searchData(searchString, selectedType.value, currentPage - PAGE_STEP)}
              paginating={loading}
            />
          </Flex>
        </Flex>
      </MainContainer>
    </Form>
  );
}
