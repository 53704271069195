import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import Api from '../../../api';
import { Flex } from 'reflexbox';
import {
  Form,
  TableBody,
  TableHeader,
  Table,
  TableHead,
  TableRow,
  Paginator,
  TableData,
  LinkedText,
  TableItemSkeleton,
  RefreshIcon,
  Tooltip,
  Checkbox,
  Select,
} from 'boss-ui';
import FormInput from '../../FormInput';
import { useDynamoPaginator } from '../../../libs/hooks-lib';
import { onError } from '../../../libs/error-lib';
import {
  MainContainer,
  FilterContainer,
  NameFilterContainer,
  PartialFilterContainer,
  ButtonWrapper,
  RefreshIconContainer,
  TableContainer,
  CheckboxTableData,
  RoleLabel,
  LabelsContainer,
  FormLeyend
} from './styles';
import { useAppContext } from '../../../libs/context-lib';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_STEP, NUMBERS, USER_ROLES } from '../../../libs/constants';
import { useTheme } from 'styled-components';
import toggleItemInArray from 'lodash.xor';

const API = new Api();

const EVENT_ROLES_COMBO = [
  { value: USER_ROLES.PLAYER, label: USER_ROLES.PLAYER },
  { value: USER_ROLES.ADMIN, label: USER_ROLES.ADMIN },
];

const USER_ROLES_COMBO = [
  { value: '', label: 'ALL' },
  { value: USER_ROLES.MEMBER, label: USER_ROLES.MEMBER },
  { value: USER_ROLES.ADMIN, label: USER_ROLES.ADMIN },
  { value: USER_ROLES.SUPERADMIN, label: USER_ROLES.SUPERADMIN },
];

export default function SearchUsers({
  height,
  selectable,
  onSelectedSubmit,
  submitSelectedButton,
  loadingSubmit,
  enrolledUsers = [],
  onClickedUser = () => { }
}) {
  const [{ pages, currentPage }, paginate] = useDynamoPaginator();
  const history = useHistory();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [searchString, setSearchString] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState(USER_ROLES_COMBO[0]);
  const user = useAppContext().state.user;
  const isSuperAdmin = user?.dynamoUser?.role === USER_ROLES.SUPERADMIN;

  const searchUser = async (q, role, page = NUMBERS.ZERO) => {
    setLoading(true);
    let lastEvaluatedKey;
    if (page > NUMBERS.ZERO) {
      lastEvaluatedKey =
        page > currentPage
          ? pages[currentPage].nextPagePointer
          : pages[currentPage].lastPagePointer;
    }
    try {
      const result = await API.get('events', `/admin/users`, {
        queryStringParameters: {
          pageSize: DEFAULT_PAGE_SIZE,
          q,
          role,
          ...(lastEvaluatedKey && {
            ...lastEvaluatedKey,
          }),
        },
      });
      setSearchList(result.users);
      paginate(result.pagination, page);
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  const cleanBox = () => {
    setSearchString('');
    searchUser('', selectedRole.value);
  };

  const delayedQuery = useCallback(
    debounce((q) => searchUser(q, selectedRole.value), 500),
    [selectedRole]
  );

  useEffect(() => {
    searchUser('');
    return () => API.abortCurrentRequest();
  }, []);

  const onSelectionChange = (user) => {
    const selected = toggleItemInArray(selectedUsers, [user]);
    user.eventRole = USER_ROLES.PLAYER;
    setSelectedUsers(selected);
  }

  const onUserRoleChange = (value, user) => {
    user.eventRole = value.value;
  }

  const onRoleOptionChange = (role) => {
    if (role != selectedRole) {
      setSelectedRole(role);
      searchUser(searchString, role.value);
    }
  }

  return (
    <Form noBorder height={height}>
      <MainContainer>
        <LabelsContainer>
          <FormLeyend width="60%">Find a User</FormLeyend>
          <FormLeyend width="20%" ml="10px">Role</FormLeyend>
          {isSuperAdmin && selectable && <FormLeyend width="20%" ml="10px" />}
        </LabelsContainer>
        <FilterContainer>
          <NameFilterContainer>
            <FormInput
              search={searchString}
              remove={searchString.length > NUMBERS.ZERO && cleanBox}
              onChange={(e) => {
                setSearchString(e.target.value);
                delayedQuery(e.target.value);
              }}
              value={searchString}
              placeholder="Search by email address or by profile Display Name"
              height="34px"
            />
          </NameFilterContainer>
          <PartialFilterContainer>
            <Select
              options={USER_ROLES_COMBO}
              onChange={onRoleOptionChange}
              value={selectedRole}
              marginTop="0px"
            />
          </PartialFilterContainer>
          {isSuperAdmin && selectable && (
            <PartialFilterContainer>
              <ButtonWrapper
                onClick={() => onSelectedSubmit(selectedUsers)}
                isLoading={loadingSubmit || searchList?.length === 0}
              >
                {submitSelectedButton}
              </ButtonWrapper>
            </PartialFilterContainer>)}
        </FilterContainer>
        <RefreshIconContainer data-tip data-for="refresh">
          <RefreshIcon
            onClick={() => searchUser(searchString, selectedRole.value)}
            rotate={loading}
            color={theme.color.text.medium.color}
            hoverColor={theme.color.text.light.color}
          />
          <Tooltip id="refresh">Refresh list</Tooltip>
        </RefreshIconContainer>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow height="41px">
                {selectable && <TableHeader />}
                <TableHeader>User</TableHeader>
                <TableHeader>Email</TableHeader>
                {selectable && <TableHeader width="180px">Event role</TableHeader>}
                <TableHeader width="120px">User role</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableItemSkeleton numberOfCells={5} />
              ) : (
                searchList.map((user) => {
                  const checked = selectedUsers.includes(user);
                  const enrolledUser = enrolledUsers?.filter(u => u.userId === user.userId)[0];
                  return (
                    <TableRow height="41px" key={user.userId}>
                      {selectable && (
                        <CheckboxTableData>
                          <label>
                            <Checkbox
                              checked={checked || enrolledUser}
                              onChange={() => onSelectionChange(user)}
                              disabled={enrolledUser}
                            />
                          </label>
                        </CheckboxTableData>
                      )}
                      <TableData>
                        <LinkedText
                          onClick={() => {
                            onClickedUser(user);
                            history.push(`/users/${user.userId}`);
                          }}
                        >
                          {user.displayName}
                        </LinkedText>
                      </TableData>
                      <TableData>{user.email}</TableData>
                      {selectable && (
                        <TableData>
                          {checked && (
                            <Select
                              value={user.selectedRole}
                              options={EVENT_ROLES_COMBO}
                              defaultValue={EVENT_ROLES_COMBO[0]}
                              onChange={(value) => onUserRoleChange(value, user)}
                              marginTop="0px"
                              marginRight="15px"
                              minHeight="0px"
                              height="25px"
                            />
                          )}

                          {enrolledUser && <RoleLabel>{enrolledUser.role}</RoleLabel>}
                        </TableData>
                      )}
                      <TableData>
                        <RoleLabel>{user.role}</RoleLabel>
                      </TableData>
                    </TableRow>
                  )
                })
              )}
            </TableBody>
          </Table>
          <Flex width={1} justifyContent="center">
            <Paginator
              isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
              onNext={() => searchUser(searchString, selectedRole.value, currentPage + DEFAULT_PAGE_STEP)}
              isPrev={currentPage > NUMBERS.ZERO}
              onPrev={() => searchUser(searchString, selectedRole.value, currentPage - DEFAULT_PAGE_STEP)}
              paginating={loading}
            />
          </Flex>
        </TableContainer>
      </MainContainer>
    </Form>
  );
}