import { Button, HovereableFlex, TableData } from "boss-ui";
import { Flex, Box } from "reflexbox";
import styled from "styled-components";

const MainContainer = styled(Flex)`
  padding: 30px 40px;
  width: 924px;
  height: 700px;
  flex-direction: column;
`;
MainContainer.displayName = 'SearchUsers-MainContainer';

const LabelsContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;
LabelsContainer.displayName = 'SearchUsers-LabelsContainer';

const FilterContainer = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
FilterContainer.displayName = 'SearchUsers-FilterContainer';

const NameFilterContainer = styled(Box)`
  width: 60%;
`;
NameFilterContainer.displayName = 'SearchUsers-NameFilterContainer';

const PartialFilterContainer = styled(Box)`
  width: 20%;
  margin-left: 10px;
`;
PartialFilterContainer.displayName = 'SearchUsers-PartialFilterContainer';

const ButtonWrapper = styled(Button).attrs({ height: '38px' })``;
ButtonWrapper.displayName = 'SearchUsers-ButtonWrapper';

const RefreshIconContainer = styled(HovereableFlex)`
  width: 20px;
  margin-left: 10px;
  margin-bottom: 12px;
`;
RefreshIconContainer.displayName = 'SearchUsers-RefreshIconContainer';

const TableContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
TableContainer.displayName = 'SearchUsers-TableContainer';

const CheckboxTableData = styled(TableData)`
  width: 20px;
  padding-left: 10px !important;
`;
CheckboxTableData.displayName = 'SearchUsers-CheckboxTableData';

const RoleLabel = styled.p`
  color: #a5d6a7;
  margin: 0;
`;
CheckboxTableData.displayName = 'SearchUsers-CheckboxTableData';

const FormLeyend = styled(Box)`
  text-align: left;
  font-family: 'Roboto Mono';
  font-size: ${(props) => (props.size ? props.size : '12px')};
  letter-spacing: 0.24px;
  color: ${(props) => props.theme.color.form.legend.color};
  opacity: 1;
`;
FormLeyend.displayName = 'SearchUsers-FormLeyend';

export {
  MainContainer,
  LabelsContainer,
  FilterContainer,
  NameFilterContainer,
  PartialFilterContainer,
  ButtonWrapper,
  RefreshIconContainer,
  TableContainer,
  CheckboxTableData,
  RoleLabel,
  FormLeyend
}