export const APP_ACTIONS = {
  SET_COGNITO_USER: 'SET_COGNITO_USER',
  SET_DYNAMO_USER: 'SET_DYNAMO_USER',
  UPDATE_DYNAMO_USER: 'UPDATE_DYNAMO_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  SET_ROUTES: 'SET_ROUTES',
};

export const SCENARIO_QUESTION_ACTIONS = {
  SET_SCENARIO: 'SET_SCENARIO',
  UPDATE_SCENARIO: 'UPDATE_SCENARIO',
  SET_OPENED_QUESTION: 'SET_OPENED_QUESTION',
  UPDATE_OPENED_QUESTION: 'UPDATE_OPENED_QUESTION',
  SET_QUESTIONS_LIST: 'SET_QUESTIONS_LIST',
  REMOVE_QUESTION_QUESTIONS_LIST: 'REMOVE_QUESTION_QUESTIONS_LIST',
  UPDATE_QUESTIONS_LIST: 'UPDATE_QUESTIONS_LIST',
  SET_QUESTION_IMPORTATION_ERROR: 'SET_QUESTION_IMPORTATION_ERROR',
  CLEAR_QUESTION_IMPORTATION_ERROR: 'CLEAR_QUESTION_IMPORTATION_ERROR',
};

export const EVENT_ACTION = {
  SET_EVENT: 'SET_EVENT',
  SET_TEAM: 'SET_TEAM',
  SET_PARTICIPANT: 'SET_PARTICIPANT',
};