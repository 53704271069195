import React, { useEffect, useState } from 'react';
import styled, { withTheme, useTheme } from 'styled-components';
import { Flex } from 'reflexbox';
import truncate from 'lodash.truncate';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { StyledText, RegularText, Checkbox, TeamIcon, RefreshIcon } from 'boss-ui';
import Api from '../../api';
import { onError } from '../../libs/error-lib';
import { useRefreshScore } from '../../libs/hooks-lib';

const API = new Api();

const Container = styled(Flex)`
  background: ${(props) => props.theme.color.form.background} 0% 0% no-repeat padding-box;
  width: 948px;
  padding: ${(props) => props.theme.margin.m};
  flex-direction: column;
`;

const ProgressBar = styled.div`
  height: 18px;
  background-color: ${(props) => props.theme.color.boards.top.progressBar};
  width: ${(props) => `${props.progress * 100}%`};
`;

const ScoreItem = withTheme(({ score = {}, idx, higherScore = 1, theme }) => {
  return (
    <>
      <Flex justifyContent="space-between" width="35px">
        <RegularText color={theme.color.boards.top.activeLight}>#</RegularText>
        <RegularText color={theme.color.boards.top.activeLight}>{idx + 1}</RegularText>
      </Flex>
      <Flex
        ml={theme.margin.xs}
        alignItems="center"
        width="-webkit-fill-available"
        style={{ width: '-moz-available' }}
      >
        <Flex mx={theme.margin.xs} width={3 / 9}>
          <RegularText>
            {truncate(score.team.name, {
              length: 25,
              omission: '...',
            })}
          </RegularText>
        </Flex>
        <Flex ml={theme.margin.xs} width={5 / 9}>
          <ProgressBar progress={score.total / higherScore} />
        </Flex>
        <Flex ml="auto">
          <RegularText color={theme.color.boards.top.activeLight}>
            {Math.round(score.total)}
          </RegularText>
        </Flex>
      </Flex>
    </>
  );
});

export default function TopScoreBoard({ eventId }) {
  const [loadingScore, setLoadingScore] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [higherScore, setHigher] = useState(null);
  const [autoRefreshGraph, setAutoRefreshGraph] = useState(false);
  const [refreshHover, setRefreshHover, getLastUpdatedLabel] = useRefreshScore(loadingScore);
  let intervalRefresh;

  const SKELETON_STYLE = {
    COLOR: useTheme().color.skeleton.color,
    EFFECT: useTheme().color.skeleton.highlightColor,
  };

  const getDashboard = async () => {
    try {
      setLoadingScore(true);
      const rq = await API.get('events', `/events/${eventId}/dashboard`);
      setDashboardData(rq.teams.filter((score) => score.total > 0));
      setLoadingScore(false);
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    getDashboard();
    return () => API.abortCurrentRequest();
  }, []);

  useEffect(() => {
    if (dashboardData[0]) {
      setHigher(dashboardData[0].total);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (autoRefreshGraph) {
      getDashboard();
      clearInterval(intervalRefresh);
      intervalRefresh = setInterval(getDashboard, 60000);
    }
    return () => clearInterval(intervalRefresh);
  }, [autoRefreshGraph]);

  return (
    <Flex justifyContent="center" mt="50px">
      <Container>
        <Flex justifyContent="center" alignItems="center" width={1} mb="40px">
          <Flex mr="4px">
            <TeamIcon size={40} color="#2c4b2d" />
          </Flex>
          <StyledText size="24px" light>
            Top 10 Teams
          </StyledText>
        </Flex>
        <Flex width={1} mb="6px">
          <Flex
            alignItems="center"
            justifyContent="flex-start"
            onClick={getDashboard}
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setRefreshHover(true)}
            onMouseLeave={() => setRefreshHover(false)}
          >
            <RefreshIcon rotate={loadingScore} hover={refreshHover} />
            <RegularText fontSize="12px" marginLeft="3px">
              {refreshHover ? (
                <RegularText fontSize="12px" color="#66bb6a">
                  Refresh score
                </RegularText>
              ) : (
                getLastUpdatedLabel()
              )}
            </RegularText>
          </Flex>
          <Flex ml="auto">
            <Flex mr="4px">
              <label>
                <Checkbox
                  checked={autoRefreshGraph}
                  onChange={(e) => setAutoRefreshGraph(e.target.checked)}
                  id="auto-refresh"
                />
              </label>
            </Flex>
            <Flex onClick={() => setAutoRefreshGraph((v) => !v)} style={{ cursor: 'pointer' }}>
              <RegularText fontSize="12px">Auto refresh score</RegularText>
            </Flex>
          </Flex>
        </Flex>
        {loadingScore ? (
          <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
            <Skeleton width="100%" height="30px" />
          </SkeletonTheme>
        ) : dashboardData.length ? (
          dashboardData.map((score, idx) => (
            <Flex
              key={score.team.slug}
              width={1}
              py="16px"
              style={{ borderBottom: '1px solid #2C2C2C' }}
            >
              <ScoreItem score={score} idx={idx} higherScore={higherScore} />
            </Flex>
          ))
        ) : (
          <Flex
            width={1}
            justifyContent="center"
            p="12px"
            style={{ borderBottom: '1px solid #2C2C2C' }}
          >
            <RegularText>There is no data yet...</RegularText>
          </Flex>
        )}
      </Container>
    </Flex>
  );
}
