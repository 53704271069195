import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';
import debounce from 'lodash.debounce';
import Api from '../../../api';
import { Flex } from 'reflexbox';
import {
  Form,
  FormLeyend,
  TableBody,
  TableHeader,
  Table,
  TableHead,
  TableRow,
  Paginator,
  TableData,
  LinkedText,
  TableItemSkeleton,
  Tooltip,
  UnlockIcon,
  BlockIcon,
  ConfirmationModal,
  RefreshIcon,
  RegularText,
  Modal,
  SaveButton,
} from 'boss-ui';
import FormInput from '../../FormInput';
import { useDynamoPaginator } from '../../../libs/hooks-lib';
import { onError } from '../../../libs/error-lib';
import { useAppContext, useEventContext } from '../../../libs/context-lib';
import { EVENT_ACTION } from '../../../libs/reducerAction-lib';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_STEP, NUMBERS, USER_ROLES } from '../../../libs/constants';
import {
  FilterContainer,
  NameFilterContainer,
  PartialFilterContainer,
  ButtonWrapper,
  MainContainer,
  RefreshIconContainer,
  TableContainer
} from './styles';
import SearchUsers from '../../Users/SearchUsers';

const API = new Api();

export default function SearchParticipants({ selectable, onSelectedSubmit }) {
  const [{ pages, currentPage }, paginate] = useDynamoPaginator();
  const routeMatch = useRouteMatch('/events/:eventId/participants');
  const eventId = routeMatch.params.eventId;
  const { dispatch } = useEventContext();
  const history = useHistory();
  const theme = useTheme();
  const hasTeamColor = theme.color.team.memberShip.hasTeam;
  const hasNoTeamColor = theme.color.team.memberShip.hasNoTeam;
  const [loading, setLoading] = useState(true);
  const [loadingEnroll, setLoadingEnroll] = useState(false);
  const [enrollUsersVisible, setEnrollUsersVisible] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searchList, setSearchList] = useState([]);
  const [rowHoverId, setRowHoverId] = useState(NUMBERS.ZERO);
  const [selectedUser, setSelectedUser] = useState({});
  const [confirmationBlockText, setConfirmationBlockText] = useState(null);
  const user = useAppContext().state.user;
  const isSuperAdmin = user?.dynamoUser?.role === USER_ROLES.SUPERADMIN;

  const searchParticipant = async (q = '', page = NUMBERS.ZERO) => {
    setLoading(true);
    let lastEvaluatedKey;
    if (page > NUMBERS.ZERO) {
      lastEvaluatedKey =
        page > currentPage
          ? pages[currentPage].nextPagePointer
          : pages[currentPage].lastPagePointer;
    }
    try {
      const result = await API.get('events', `/admin/events/${eventId}/users`, {
        queryStringParameters: {
          pageSize: DEFAULT_PAGE_SIZE,
          q,
          ...(lastEvaluatedKey && {
            ...lastEvaluatedKey,
          }),
        },
      });
      setSearchList(result.participants);
      paginate(result.pagination, page);
      setLoading(false);
    } catch (e) {
      onError(e);
    }
  };

  const cleanBox = () => {
    setSearchString('');
    searchParticipant();
  };

  const delayedQuery = useCallback(
    debounce((q) => searchParticipant(q), 500),
    []
  );

  useEffect(() => {
    searchParticipant();
    return () => API.abortCurrentRequest();
  }, []);

  const onItemClicked = (item) => {
    dispatch({ type: EVENT_ACTION.SET_PARTICIPANT, data: item });
    history.push(`/events/${eventId}/participants/${item.userId}`);
  };

  const onClickChangeState = (item) => {
    const stateLabel = item.bannedAt ? 'unblock' : 'block';
    setConfirmationBlockText(`Are you sure on ${stateLabel} user ${item.name}?`);
    setSelectedUser(item);
  };

  const onChangeStateUser = async () => {
    setConfirmationBlockText(null);
    setLoading(true);
    try {
      const url = `/admin/events/${eventId}/users/${selectedUser.userId}/ban`;
      if (selectedUser.bannedAt) {
        await API.del('events', url);
      } else {
        await API.put('events', url);
      }
      cleanBox();
      setSelectedUser({});
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  const onEnrollUsers = async (users) => {
    setLoadingEnroll(true);

    try {
      const promises = users.map(user => {
        const userToEnroll = {
          userId: user.userId,
          role: user.eventRole,
          data: {
            name: user.displayName,
            email: user.email
          }
        }

        return API.post('events', `/admin/events/${eventId}/join`, { body: userToEnroll })
      });

      await Promise.all(promises);
      setEnrollUsersVisible(false);
      searchParticipant();
    } catch (e) {
      onError(e);
    } finally {
      setLoadingEnroll(false);
    }
  }

  return (
    <>
      <Form noBorder>
        <MainContainer>
          <FormLeyend>Find a participant</FormLeyend>
          <FilterContainer>
            <NameFilterContainer>
              <FormInput
                search={searchString}
                remove={searchString.length > NUMBERS.ZERO && cleanBox}
                onChange={(e) => {
                  setSearchString(e.target.value);
                  delayedQuery(e.target.value);
                }}
                value={searchString}
                placeholder="Search by email address or by profile Display Name"
              />
            </NameFilterContainer>
            {isSuperAdmin && (
              <PartialFilterContainer>
                <ButtonWrapper onClick={() => setEnrollUsersVisible(true)}>
                  + ENROLL
                </ButtonWrapper>
              </PartialFilterContainer>
            )}
          </FilterContainer>
          <RefreshIconContainer data-tip data-for="refresh">
            <RefreshIcon
              onClick={cleanBox}
              rotate={loading}
              color={theme.color.text.medium.color}
              hoverColor={theme.color.text.light.color}
            />
            <Tooltip id="refresh">Refresh list</Tooltip>
          </RefreshIconContainer>
          <TableContainer>
            <Table onMouseOut={() => setRowHoverId(NUMBERS.ZERO)}>
              <TableHead>
                <TableRow height="41px">
                  <TableHeader>Participant</TableHeader>
                  <TableHeader>Team Membership</TableHeader>
                  <TableHeader />
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableItemSkeleton numberOfCells={4} />
                ) : (
                  searchList.map((item) => (
                    <TableRow
                      height="41px"
                      key={item.userId}
                      onMouseOver={() => setRowHoverId(item.userId)}
                      borderTransparent
                      mouseOver={rowHoverId === item.userId}

                    >
                      <TableData>
                        <LinkedText onClick={() => onItemClicked(item)}>{item.name}</LinkedText>
                      </TableData>
                      <TableData style={{ color: item.hasTeam ? hasTeamColor : hasNoTeamColor }}>{item.hasTeam ? 'has team' : 'has no team'}</TableData>
                      <TableData right style={{ paddingRight: '10px' }}>
                        <Flex justifyContent="flex-end"
                          style={item.role === USER_ROLES.PLAYER ? { visibility: 'visible', cursor: 'pointer' } : { visibility: 'hidden' }}
                          onClick={() => {
                            onClickChangeState(item);
                          }}
                        >
                          {item.bannedAt ? <UnlockIcon size="22px" style={{ marginRight: '5px' }} /> : <BlockIcon />}
                        </Flex>
                      </TableData>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <Flex width={1} justifyContent="center">
              <Paginator
                isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
                onNext={() => searchParticipant(searchString, currentPage + DEFAULT_PAGE_STEP)}
                isPrev={currentPage > NUMBERS.ZERO}
                onPrev={() => searchParticipant(searchString, currentPage - DEFAULT_PAGE_STEP)}
                paginating={loading}
              />
            </Flex>
          </TableContainer>
        </MainContainer>
        {confirmationBlockText && (
          <ConfirmationModal
            onAccept={() => {
              onChangeStateUser();
            }}
            onCancel={() => setConfirmationBlockText(null)}
            acceptLabel={selectedUser.bannedAt ? 'UNBLOCK USER' : 'BLOCK USER'}
            dangerDialog={!selectedUser.bannedAt}
          >
            <RegularText>{confirmationBlockText}</RegularText>
          </ConfirmationModal>
        )}
      </Form>
      {
        enrollUsersVisible && (
          <Modal
            onBackgroundClick={() => setEnrollUsersVisible(false)}
            margin={'10vh auto'}
          >
            <SearchUsers
              selectable
              loadingSubmit={loadingEnroll}
              onSelectedSubmit={onEnrollUsers}
              submitSelectedButton={<SaveButton loading={loadingEnroll} />}
              enrolledUsers={searchList}
            />
          </Modal>
        )
      }
    </>
  );
}