import React, { useMemo, useEffect } from 'react';
import Media from 'react-media';
import { Box, Flex } from 'reflexbox';
import ContainerEvtPlayerMobile from './ContainerEvtPlayerMobile';
import CountDown from './CountDown';
import { FloatingPanel } from 'boss-ui';
import {
  NAV_MOBILE_MAXWIDTH,
  EVENT_DELIVERY,
  DEFAULT_ON_DEMAND_EVENT_DURATION,
  ONE_MINUTE_MS,
  MAX_INT32,
} from '../../libs/constants';
import { useEventContext } from '../../libs/context-lib';
import { isTodayAfterThan, isTodayBetween } from '../../libs/dates-lib';
import { EVENT_ACTION } from '../../libs/reducerAction-lib';

const nowDate = new Date();

export default function EventFloatingBox() {
  const { state, dispatch } = useEventContext();
  const isOnDemandEvent = useMemo(
    () => state.event.delivery === EVENT_DELIVERY.ON_DEMAND,
    [state.event.delivery]
  );

  const countDownStartDate = useMemo(() => {
    if (!isOnDemandEvent) {
      return state.event.startDate;
    }
    if (state.event.participant && state.event.participant.joinDate) {
      return state.event.participant.joinDate;
    }
    return nowDate;
  }, [isOnDemandEvent, state.event.startDate, state.event.participant]);

  const countDownEndDate = useMemo(() => {
    if (!isOnDemandEvent) {
      return state.event.endDate;
    }
    if (state.event.duration) {
      if (state.event.participant && state.event.participant.joinDate) {
        const joinDate = new Date(state.event.participant.joinDate);
        return new Date(joinDate.getTime() + state.event.duration * ONE_MINUTE_MS);
      }
      return new Date(nowDate.getTime() + state.event.duration * ONE_MINUTE_MS);
    }
    return new Date(nowDate.getTime() + DEFAULT_ON_DEMAND_EVENT_DURATION * ONE_MINUTE_MS);
  }, [isOnDemandEvent, state.event.endDate, state.event.participant, state.event.duration]);

  // set isEventRunning to false in order to block some UI actions
  useEffect(() => {
    const endDateInMs = new Date(countDownEndDate).getTime() - new Date().getTime();
    let eventRunningTimeout;
    // setTimeout limit is MAX_INT32 if end date in ms is bigger avoid timeout
    if (endDateInMs < MAX_INT32) {
      eventRunningTimeout = setTimeout(() => {
        console.log(
          `EVENT-END-TIMEOUT countDownEndDate: ${countDownEndDate}, isEventRunning: false, isEventEnded: true`
        );
        dispatch({
          type: EVENT_ACTION.SET_EVENT,
          data: {
            ...state.event,
            isEventRunning: false,
            isEventEnded: true,
          },
        });
      }, endDateInMs);
    }
    return () => clearTimeout(eventRunningTimeout);
  }, [countDownEndDate, dispatch, state.event.isEventRunning, state.event.isEventEnded]);

  // set isEventStarted to true in order to show up the scoring in the menu
  useEffect(() => {
    let startEventInterval;
    const startDateInMs = new Date(countDownStartDate).getTime() - new Date().getTime();
    // setTimeout limit is MAX_INT32 if start date in ms is bigger avoid timeout
    if (startDateInMs < MAX_INT32) {
      startEventInterval = setTimeout(() => {
        console.log(
          `EVENT-START-TIMEOUT countDownStartDate: ${countDownStartDate}, countDownEndDate: ${countDownEndDate}, isEventStarted: true, isEventRunning: ${isTodayBetween(
            countDownStartDate,
            countDownEndDate
          )}, isEventEnded: ${isTodayAfterThan(countDownEndDate)}`
        );
        dispatch({
          type: EVENT_ACTION.SET_EVENT,
          data: {
            ...state.event,
            isEventStarted: true,
            isEventRunning: isTodayBetween(countDownStartDate, countDownEndDate),
            isEventEnded: isTodayAfterThan(countDownEndDate),
          },
        });
      }, startDateInMs);
    }
    return () => clearTimeout(startEventInterval);
  }, [
    countDownStartDate,
    countDownEndDate,
    dispatch,
    state.event.isEventStarted,
    state.event.isEventRunning,
    state.event.isEventEnded,
  ]);

  useEffect(() => {
    console.log(
      `EFFECT countDownStartDate: ${countDownStartDate}, countDownEndDate: ${countDownEndDate}, isEventStarted: ${isTodayAfterThan(
        countDownStartDate
      )}, isEventRunning: ${isTodayBetween(
        countDownStartDate,
        countDownEndDate
      )}, isEventEnded: ${isTodayAfterThan(countDownEndDate)}`
    );
    dispatch({
      type: EVENT_ACTION.SET_EVENT,
      data: {
        ...state.event,
        isEventRunning: isTodayBetween(countDownStartDate, countDownEndDate),
        isEventStarted: isTodayAfterThan(countDownStartDate),
        isEventEnded: isTodayAfterThan(countDownEndDate),
      },
    });
  }, [
    countDownStartDate,
    countDownEndDate,
    dispatch,
    state.event.isEventRunning,
    state.event.isEventStarted,
    state.event.isEventEnded,
  ]);

  return (
    <Media query={`(max-width: ${NAV_MOBILE_MAXWIDTH})`}>
      {(mobileScreen) => (
        <>
          {mobileScreen ? (
            <ContainerEvtPlayerMobile>
              <Flex pl="12px">
                <CountDown
                  endDate={countDownEndDate}
                  startDate={countDownStartDate}
                  mobile
                  allowStartingPreview
                />
              </Flex>
            </ContainerEvtPlayerMobile>
          ) : (
            <FloatingPanel>
              <Box style={{ position: 'fixed', top: '130px', zIndex: '100' }}>
                <CountDown
                  endDate={countDownEndDate}
                  startDate={countDownStartDate}
                  allowStartingPreview
                />
              </Box>
            </FloatingPanel>
          )}
        </>
      )}
    </Media>
  );
}
