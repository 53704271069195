import { BOTS_TYPE } from './constants';

export const getEventShortName = (type) => {
  if (type === BOTS_TYPE.SOC) {
    return 'BOTS';
  }
  return 'BOO';
};

export const getNoImageUrl = (type, size) => {
  if (type === BOTS_TYPE.SOC) {
    switch (size) {
      case 'large':
        return `${process.env.PUBLIC_URL}/bots/android-chrome-766x432.png`;
      case 'medium':
        return `${process.env.PUBLIC_URL}/bots/android-chrome-550x310.png`;
      default:
        return `${process.env.PUBLIC_URL}/bots/android-chrome-330x186.png`;
    }
  } else {
    switch (size) {
      case 'large':
        return `${process.env.PUBLIC_URL}/boo/android-chrome-766x432.png`;
      case 'medium':
        return `${process.env.PUBLIC_URL}/boo/android-chrome-550x310.png`;
      default:
        return `${process.env.PUBLIC_URL}/boo/android-chrome-330x186.png`;
    }
  }
};

export const getSrnGroupId = (eventId) => {
  return `srn:group::${eventId}`;
}

export const getConsumerGroupSrn = (eventId, userId) => {
  return `srn:group:${userId}:${eventId}`;
}