import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Flex } from 'reflexbox';
import omit from 'lodash.omit';
import Api from '../../api';
import { ScenarioContext, useAppContext } from '../../libs/context-lib';
import { DISPLAY_QUESTIONS_MODE, NO_DATA_IN_LIST_MESSAGE } from '../../libs/constants';
import { ScenarioDisplaySelector, QuestionsGrid, QuestionsList } from '../../components';
import {
  Button,
  ClearIcon,
  CsvImportModal,
  ExportIcon,
  ImportIcon,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
} from 'boss-ui';
import {
  exportQuestionsFile,
  makeQuestionPayload,
  reduceQuestionFile,
} from '../../components/Questions/utils';
import { onError } from '../../libs/error-lib';
import { useRouteMatch, Prompt } from 'react-router-dom';
import { useScenarioQuestionReducer } from '../../libs/reducer-lib';
import { APP_ACTIONS, SCENARIO_QUESTION_ACTIONS } from '../../libs/reducerAction-lib';
import { parseCSV } from '../../libs/csv-lib';
import InfoIcon from 'boss-ui/src/icons/InfoIcon';

const SUBMITTED_MESSAGE = 'Submitted successfully';
const API = new Api();

export default function ScenarioQuestionsEdition() {
  const appDispatch = useAppContext().dispatch;
  const [state, dispatch] = useScenarioQuestionReducer();
  const theme = useTheme();
  const [focusedQuestionId, setFocusedQuestionId] = useState(null);
  const [removingQuestionId, setRemovingQuestionId] = useState(null);
  const [showImportModal, setShowImportModal] = useState(false);
  const [displayMode, setDisplayMode] = useState(DISPLAY_QUESTIONS_MODE.LIST);
  const [submittingQuestions, setSubmittingQuestions] = useState(false);
  const [submittedQuestions, setSubmittedQuestions] = useState(false);
  const routeMatchEventScenario = useRouteMatch('/events/:eventId/scenarios/:scenarioId');
  const routeMatchScenarios = useRouteMatch('/scenarios/:scenarioId');
  const routeMatch = routeMatchScenarios ? routeMatchScenarios : routeMatchEventScenario;
  
  useEffect(() => {
    const getScenarioQuestionsById = async (scenarioId) => {
      try {
        let url = `/admin/scenarios/${scenarioId}`;
        if (routeMatchEventScenario) {
          url = `/admin/events/${routeMatchEventScenario.params.eventId}/scenarios/${scenarioId}`;
        }      
        const rq = await API.get('events', url);
        dispatch({
          type: SCENARIO_QUESTION_ACTIONS.UPDATE_SCENARIO,
          data: omit(rq.scenario, ['questions', 'requiredResources']),
        });
        if (routeMatchScenarios) {
          appDispatch({
            type: APP_ACTIONS.SET_ROUTES,
            data: [
              {
                path: `/scenarios`,
                name: 'Scenarios',
              },
              {
                path: `/scenarios/${routeMatch.params.scenarioId}`,
                name: rq.scenario.title,
              },
            ],
          });
        }
      } catch (e) {
        onError(e);
      }
    };

    getScenarioQuestionsById(routeMatch.params.scenarioId);
    return () => API.abortCurrentRequest();
  }, [routeMatch.params.scenarioId, dispatch, appDispatch]);

  const onQuestionCardClick = (questionClicked) => {
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.SET_OPENED_QUESTION,
      data: questionClicked,
    });
    setFocusedQuestionId(questionClicked.question_id);
  };

  const onQuestionCardClose = () => {
    dispatch({ type: SCENARIO_QUESTION_ACTIONS.SET_OPENED_QUESTION, data: null });
    setFocusedQuestionId(null);
  };

  const onRemoveQuestion = async (questionId) => {
    setRemovingQuestionId(questionId);
    try {
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.REMOVE_QUESTION_QUESTIONS_LIST,
        data: { questionId },
      });
    } catch (e) {
      onError(e);
    } finally {
      setRemovingQuestionId(false);
    }
  };

  const onImportCSV = async (file) => {
    setSubmittedQuestions(false);
    try {
      const parsedFile = await parseCSV({ file });
      const questions = reduceQuestionFile(parsedFile);
      const questionsWithLine = questions.map((q, idx) => {
        return { question: { ...omit(q, 'scenario_id'), line: idx, question_id: q.question_id } };
      });
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.SET_QUESTIONS_LIST,
        data: questionsWithLine,
      });
      setShowImportModal(false);
    } catch (e) {
      onError(e);
    }
  };

  const onSubmitQuestions = async () => {
    setSubmittingQuestions(true);
    try {
      dispatch({ type: SCENARIO_QUESTION_ACTIONS.CLEAR_QUESTION_IMPORTATION_ERROR });

      let url = `/admin/scenarios/${state.scenario.scenarioId}/questions`;
      if (routeMatchEventScenario) {
        url = `/admin/events/${routeMatchEventScenario.params.eventId}/scenarios/${state.scenario.scenarioId}/questions`;
      }
      await API.put('events', url, {
        body: state.questionsList.map((q) => makeQuestionPayload(q.question)),
      });

      setSubmittedQuestions(true);
      dispatch({ type: SCENARIO_QUESTION_ACTIONS.SET_QUESTIONS_LIST, data: [] });
    } catch (e) {
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.SET_QUESTION_IMPORTATION_ERROR,
        data: e,
      });
      onError(e);
    } finally {
      setSubmittingQuestions(false);
    }
  };

  const onClearQuestions = () => {
    dispatch({ type: SCENARIO_QUESTION_ACTIONS.SET_QUESTIONS_LIST, data: [] });
    dispatch({ type: SCENARIO_QUESTION_ACTIONS.CLEAR_QUESTION_IMPORTATION_ERROR });
  };

  return (
    <Flex
      width="-webkit-fill-available"
      minHeight="689px"
      backgroundColor="#1d1d1d"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      px="60px"
      py="40px"
    >
      <>
        <Prompt
          when={state.questionsList.length > 0 && !submittedQuestions}
          message="Are you sure on leaving? Not submitted questions will be lost"
        />
        <Flex alignItems="center" width={1} mb="12px">
          <Flex ml="auto" flexDirection="column">
            <Flex ml="auto" mb="28px" alignItems="center">
              {state.questionsList.length ? (
                <>
                  <Flex width="100px" mr="40px" alignItems="center" justifyContent="space-between">
                    <Flex onClick={onClearQuestions} data-tip data-for="import-clear">
                      <ClearIcon />
                      <Tooltip id="import-clear" borderColor={theme.color.border.danger}>
                        Clear imported questions
                      </Tooltip>
                    </Flex>
                    <Flex
                      onClick={() =>
                        exportQuestionsFile(state.questionsList, state.scenario.scenarioId, false)
                      }
                      data-tip
                      data-for="import-export"
                    >
                      <ExportIcon />
                      <Tooltip id="import-export" borderColor={theme.color.border.active}>
                        Export CSV
                      </Tooltip>
                    </Flex>
                  </Flex>
                  <Button onClick={onSubmitQuestions} isLoading={submittingQuestions}>
                    SUBMIT QUESTIONS
                  </Button>
                </>
              ) : (
                <Flex
                  ml="auto"
                  onClick={() => setShowImportModal(true)}
                  data-tip
                  data-for="import-import"
                >
                  <ImportIcon />
                  <Tooltip id="import-import" borderColor={theme.color.border.active}>
                    Import CSV
                  </Tooltip>
                </Flex>
              )}
            </Flex>
            <ScenarioDisplaySelector onSelected={(v) => setDisplayMode(v)} selected={displayMode} />
          </Flex>
        </Flex>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeader ypadding="6px" xpadding="16px">
                Questions
              </TableHeader>
              <TableHeader justify="flex-end">
                {state.error && (
                  <Flex data-tip data-for="import-error-message" mx="5px">
                    <InfoIcon fill="#E06C75" />
                    <Tooltip id="import-error-message">
                      There are some errors:
                      <li>{state.error.message}</li>
                    </Tooltip>
                  </Flex>
                )}
              </TableHeader>
            </TableRow>
          </TableHead>
          {!state.questionsList.length && (
            <TableBody>
              <TableRow>
                <TableHeader colspan={2} xpadding="5%" ypadding="20px" noWrap>
                  {submittedQuestions ? SUBMITTED_MESSAGE : NO_DATA_IN_LIST_MESSAGE}
                </TableHeader>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <Flex mt="20px" justifyContent="center" width={1}>
          <ScenarioContext.Provider
            value={{
              state,
              dispatch,
            }}
          >
            {displayMode === DISPLAY_QUESTIONS_MODE.GRID ? (
              <QuestionsGrid
                isImportationView
                onQuestionCardClick={onQuestionCardClick}
                onQuestionCardClosed={onQuestionCardClose}
                focusedQuestionId={focusedQuestionId}
                questionNavigation={() => {}}
                removingQuestionId={removingQuestionId}
                onQuestionRemoved={onRemoveQuestion}
                eventId={routeMatch.params.eventId ? routeMatch.params.eventId : null}
              />
            ) : (
              <QuestionsList
                isImportationView
                onQuestionCardClick={onQuestionCardClick}
                onQuestionCardClosed={onQuestionCardClose}
                focusedQuestionId={focusedQuestionId}
                questionNavigation={() => {}}
                removingQuestionId={removingQuestionId}
                onQuestionRemoved={onRemoveQuestion}
                eventId={routeMatch.params.eventId ? routeMatch.params.eventId : null}
              />
            )}
          </ScenarioContext.Provider>
        </Flex>
        {showImportModal && (
          <CsvImportModal onImport={onImportCSV} onCancel={() => setShowImportModal(false)} />
        )}
      </>
    </Flex>
  );
}
