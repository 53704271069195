import React, { useEffect, useState, useCallback } from 'react';
import { Flex, Box } from 'reflexbox';
import debounce from 'lodash.debounce';
import styled, { useTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button, FormLabel, Paginator, SpyIcon, ListTable, Item, ListItem } from 'boss-ui';
import Api from '../../api';
import FormInput from '../FormInput';
import { useDynamoPaginator } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';
import { DEFAULT_PAGE_STEP, NUMBERS } from '../../libs/constants';

const DEFAULT_PAGE_SIZE = 5;
const invitedUsers = {};

const API = new Api();

const ListHeader = styled(Flex)`
  box-shadow: 0px 3px 6px #00000029;
  background-color: #2c2c2c;
  height: 130px;
  padding: 40px 45px 40px 45px;
  align-items: center;
`;
ListHeader.displayName = 'AllowListUser-ListHeader';

export default function AllowListUser({eventId}) {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(0);
  const [{ pages, currentPage }, paginate] = useDynamoPaginator();
  const [searchList, setSearchList] = useState([]);
  const SKELETON_STYLE = {
    COLOR: useTheme().color.skeleton.color,
    EFFECT: useTheme().color.skeleton.highlightColor,
  };

  const searchUser = async (q = '', page = NUMBERS.ZERO) => {
    setLoading(true);
    let lastEvaluatedKey;
    if (page > NUMBERS.ZERO) {
      lastEvaluatedKey =
        page > currentPage
          ? pages[currentPage].nextPagePointer
          : pages[currentPage].lastPagePointer;
    }
    try {
      const result = await API.get('events', `/admin/users`, {
        queryStringParameters: {
          pageSize: DEFAULT_PAGE_SIZE,
          q,
          ...(lastEvaluatedKey && {
            ...lastEvaluatedKey,
          }),
          eventId: eventId,
        },
      });
      setSearchList(result.users);
      paginate(result.pagination, page);
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  const cleanBox = () => {
    setSearch('');
    searchUser();
  };

  const inviteUser = async (userId) => {
    setLoadingUser(userId);
    try {
      await API.post('events', `/admin/events/${eventId}/allowlist`, {
        body: {
          allowlist: [{ userId }],
        },
      });
      invitedUsers[userId] = true;
    } catch (e) {
      onError(e);
    } finally {
      setLoadingUser(0);
    }
  };

  const delayedQuery = useCallback(
    debounce((q) => searchUser(q), 500),
    []
  );

  useEffect(() => {
    searchUser();
    return () => API.abortCurrentRequest();
  }, []);

  return (
    <Flex width="766px" flexDirection="column">
      <ListHeader>
        <Box width={3 / 4} mx="5px">
          <FormLabel>Find an user</FormLabel>
          <FormInput
            remove={search.length > NUMBERS.ZERO && cleanBox}
            search
            onChange={(e) => {
              setSearch(e.target.value);
              delayedQuery(e.target.value);
            }}
            value={search}
            placeholder="Search by email address or by profile Display name"
            styleBox={{ height: '50px' }}
            available
          />
        </Box>
      </ListHeader>
      <Flex height="400px" flexDirection="column">
        <ListTable>
          {loading ? (
            <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
              <ListItem style={{ display: 'flex' }}>
                <Item noBorder width="70px">
                  <SpyIcon />
                </Item>
                <Item width="100%">
                  <Skeleton width="100%" height="30px" />
                </Item>
              </ListItem>
            </SkeletonTheme>
          ) : (
            <>
              {searchList.map((item) => (
                <ListItem key={item.userId}>
                  <Item noBorder width="70px">
                    <SpyIcon />
                  </Item>
                  <Item>{item.displayName}</Item>
                  <Item style={{ textAlign: 'center' }}>
                    {loadingUser === item.userId ? (
                      <Button disabled>INVITE</Button>
                    ) : (
                      <Button
                        disabled={invitedUsers[item.userId]}
                        onClick={() => inviteUser(item.userId)}
                      >
                        {invitedUsers[item.userId] ? <>INVITED</> : <>INVITE</>}
                      </Button>
                    )}
                  </Item>
                </ListItem>
              ))}
              {search.length > 3 && searchList.length === NUMBERS.ZERO && !loading && (
                <ListItem>
                  <Item>No users found</Item>
                </ListItem>
              )}
            </>
          )}
        </ListTable>
        <Paginator
          isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
          onNext={() => searchUser(search, currentPage + DEFAULT_PAGE_STEP)}
          isPrev={currentPage > 0}
          onPrev={() => searchUser(search, currentPage - DEFAULT_PAGE_STEP)}
          paginating={loading}
        />
      </Flex>
    </Flex>
  );
}
