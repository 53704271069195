import React from 'react';
import get from 'lodash.get';
import { TabScreen } from 'boss-ui';
import QuestionPhrasing from './QuestionPhrasing';
import QuestionPhrasingCreate from './QuestionPhrasingCreate';
import { getIndexError, PATH_ERROR_TYPE } from '../utils';

const generatePhrasingTabsLabels = (phrasings = [], errorPhrasingId) =>
  phrasings.map((p, idx) => {
    return {
      label: `Phrasing #${idx + 1}`,
      ...(errorPhrasingId === idx && { fontColor: '#E06C75' }),
    };
  });

const NEW_PHRASING_TAB_LABEL = { label: 'Add a Phrasing' };

const generatePhrasingTabs = (
  phrasings = [],
  { error = {}, isImportationView = false, errorIdxPhrasing }
) =>
  phrasings.map((p, idx) => {
    const hasPhrasingError = errorIdxPhrasing === idx;

    return (
      <QuestionPhrasing
        phrasing={p}
        key={`Phrasing #${idx + 1}`}
        phrasingIdx={idx}
        error={hasPhrasingError && error}
        isImportationView={isImportationView}
      />
    );
  });

export default function PhrasingList({ phrasings = [], isImportationView = false, error = null }) {
  const errorPath = get(error, 'path');
  const errorIdxPhrasing = getIndexError(errorPath, PATH_ERROR_TYPE.PHRASINGS);
  const phrasingTabsLabels = generatePhrasingTabsLabels(phrasings, errorIdxPhrasing);
  phrasingTabsLabels.push(NEW_PHRASING_TAB_LABEL);

  const phrasingTabs = generatePhrasingTabs(phrasings, {
    error,
    errorIdxPhrasing,
    isImportationView,
  });
  phrasingTabs.push(<QuestionPhrasingCreate isImportationView={isImportationView} />);

  return <TabScreen tabs={phrasingTabsLabels} tabsContent={phrasingTabs} />;
}
