import React from 'react';
import { useTheme } from 'styled-components';
import { Flex } from 'reflexbox';
import { PublicIcon, PrivateIcon } from 'boss-ui';
import { TEAM_VISIBILITY } from '../../libs/constants';

export default function TeamMembersVisibility(props) {
  const { team } = props;
  const theme = useTheme();

  return (
    <Flex>
      {team.visibility === TEAM_VISIBILITY.PUBLIC && <PublicIcon inverted invertedColor={theme.color.team.visibility.public} />}
      {team.visibility === TEAM_VISIBILITY.PRIVATE && <PrivateIcon inverted />}      
    </Flex>
  );
}
