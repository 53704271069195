import React from 'react';
import styled from 'styled-components';
import { differenceInDays } from 'date-fns';
import { Flex } from 'reflexbox';
import { RegularText, Tooltip, InfoIcon, SpinnerIcon } from 'boss-ui';
import { getHourMinuteDifference } from '../../libs/dates-lib';

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.color.allocationMonitor.background};
  padding: 6px;
  height: 31px;
  justify-items: center;
`;

const LastPlanRunAt = ({ runAt }) => {
  const lastRunAtDate = new Date(runAt);
  const dayDifference = differenceInDays(new Date(), lastRunAtDate);
  if (dayDifference > 0) {
    return `last plan run ${dayDifference} ${dayDifference > 1 ? 'days ago' : 'day ago'}`;
  }
  const hourMinutesDifference = getHourMinuteDifference(new Date(), lastRunAtDate);
  if (hourMinutesDifference.hour > 0) {
    return `last plan run ${hourMinutesDifference.hour} ${
      hourMinutesDifference.hour > 1 ? 'hours ago' : 'hour ago'
    }`;
  }
  return runAt ? `last plan run ${hourMinutesDifference.minutes} minutes ago` : 'no plan has run';
};

export default function ResourceAllocationFooter({
  createdCount,
  lastPlanRunAt,
  loading = false,
}) {
  return (
    <Container justifyContent="space-between">
      <Flex width={1 / 2} justifyContent="space-between">
        <Flex alignItems="center">
          <RegularText mediumWeight fontSize="14px">
            Current: {createdCount || '--'}
          </RegularText>
          <Flex ml="2px">
            <InfoIcon data-tip data-for="current" size="16px" />
          </Flex>
          <Tooltip id="current">Current instances added to this spec.</Tooltip>
        </Flex>
      </Flex>
      <Flex alignItems="center">
        {loading ? (
          <SpinnerIcon size={20} />
        ) : (
          <RegularText fontSize="12px" marginLeft="3px">
            <LastPlanRunAt runAt={lastPlanRunAt} />
          </RegularText>
        )}
      </Flex>
    </Container>
  );
}
