import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';
import truncate from 'lodash.truncate';
import { StyledText, FormLabel, Tooltip, CancelIcon, PencilIcon, SpyIcon, InfoIcon, CheckIcon, UserSlashIcon } from 'boss-ui';
import { DISPLAY_NAME_REGEXP } from '../../libs/constants';
import FormInput from '../FormInput';

export default function UserNameBadge(props) {
  const [editionActive, activateEdition] = useState(false);
  const [saveHover, mouseHoverSave] = useState(false);
  const [cancelHover, mouseHoverCancel] = useState(false);
  const {
    name,
    editionAllowed,
    onNameChange,
    available,
    validating,
    onNameReset,
    onNameChanged,
    isBanned,
  } = props;

  const validName = available && name && name.match(DISPLAY_NAME_REGEXP);

  return (
    <Flex alignItems="center">
      {isBanned ? (
        <UserSlashIcon size="120px" />
      ) : (
        <SpyIcon size="120px" backgroundColor="#535353" />
      )}
      {editionActive ? (
        <Box ml="16px" style={{ position: 'relative' }} width={3/5}>
          <FormLabel>User Name</FormLabel>
          <FormInput
            id="name"
            autoFocus
            placeholder="Enter a display name"
            value={name}
            onChange={onNameChange}
            validate={name}
            validating={validating}
            available={available}
            pattern={DISPLAY_NAME_REGEXP}
            unavailableMessage="This name was already taken!"
            invalidPatternMessage="From 3 to 30 length, spaces, a-z, 0-9 and _ allowed"
            validMessage="Name is available"
          />
          <Flex
            data-tip
            data-for="name_info"
            style={{
              position: 'absolute',
              right: '-30px',
              top: '33px',
            }}
          >
            <InfoIcon />
          </Flex>
          <Tooltip id="name_info" place="right">
            You must choose a display name to continue. (3 characters at least, alphanumerics, spaces and
            underscores allowed)
          </Tooltip>
        </Box>
      ) : (
        <Flex ml="16px">
          <StyledText size="40px" light>
            {truncate(name, { length: 22, omission: '...' })}
          </StyledText>
        </Flex>
      )}
      {editionAllowed && (
        <Flex ml="auto">
          {editionActive ? (
            <Flex>
              <Flex
                onMouseLeave={() => mouseHoverSave(false)}
                onMouseEnter={() => mouseHoverSave(true)}
                onClick={() => {
                  if (validName && !validating) {
                    onNameChanged();
                    activateEdition(false);
                  }
                }}
              >
                <CheckIcon hover={saveHover} disabled={!validName} />
              </Flex>
              <Flex
                ml="10px"
                onMouseLeave={() => mouseHoverCancel(false)}
                onMouseEnter={() => mouseHoverCancel(true)}
                onClick={() => {
                  onNameReset();
                  activateEdition(false);
                }}
              >
                <CancelIcon hover={cancelHover} />
              </Flex>
            </Flex>
          ) : (
            <Flex onClick={() => activateEdition(true)}>
              <PencilIcon />
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
}
