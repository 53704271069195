import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Flex } from 'reflexbox';
import InvitesLinkList from '../../components/Invites/InvitesLinkList';
import InvitesDirectList from '../../components/Invites/InvitesDirectList';
import { useAppContext, useEventContext } from '../../libs/context-lib';
import { APP_ACTIONS } from '../../libs/reducerAction-lib';

export default function Invites() {
  const appDispatch = useAppContext().dispatch;
  const { state } = useEventContext();  
  const route = useRouteMatch('/events/:eventId');
  const eventId = route ? route.params.eventId : '';

  useEffect(() => {
    appDispatch({
      type: APP_ACTIONS.SET_ROUTES,
      data: [
        { path: `/events`, name: 'Events' },
        { path: `/events/${eventId}`, name: state.event.name },
        { path: `/events/${eventId}/invites`, name: 'Invites' },
      ],
    });
  }, [state.event, eventId, appDispatch]);

  return (
    <Switch>
      <Route path="/events/:eventId/invites" exact>
        <Redirect to={`/events/${eventId}/invites/direct`} />
      </Route>
      <Route path="/events/:eventId/invites/direct" exact>
        <Flex width={1} justifyContent="center">
          <Flex mb="5%" flexDirection="column" mt="60px">
            <InvitesDirectList />
          </Flex>
        </Flex>
      </Route>
      <Route path="/events/:eventId/invites/link" exact>
        <Flex width={1} justifyContent="center">
          <Flex mb="5%" flexDirection="column" mt="60px">
            <InvitesLinkList />
          </Flex>
        </Flex>
      </Route>
    </Switch>
  );
}
