import { useReducer } from 'react';
import { SCENARIO_QUESTION_ACTIONS, EVENT_ACTION, APP_ACTIONS } from './reducerAction-lib';
import { updateQuestionBy } from './question-lib';
import get from 'lodash.get';
import { getErrorQuestionLine } from '../components/Questions/utils';
import { getAppType } from './utils-lib';

const appDefaultState = {
  user: null,
  navTitle: null,
  appType: getAppType(),
  routes: [],
};

const scenarioQuestionDefaultState = {
  questionsList: [],
  scenario: {},
  openedQuestion: null,
  error: null,
};

const eventDefaultState = {
  event: {},
  team: {},
  participant: {},
};

const removeQuestionFn = () => null;

function appReducer(state, action) {
  switch (action.type) {
    case APP_ACTIONS.SET_COGNITO_USER:
      return { ...state, user: action.data };
    case APP_ACTIONS.SET_DYNAMO_USER:
      return { ...state, user: { ...state.user, role: action.data.role, dynamoUser: action.data } };
    case APP_ACTIONS.UPDATE_DYNAMO_USER:
      return {
        ...state,
        user: { ...state.user, role: action.data.role, dynamoUser: { ...state.user.dynamoUser, ...action.data } },
      };
    case APP_ACTIONS.LOGOUT_USER:
      return {
        ...state,
        user: null,
      };
    case APP_ACTIONS.SET_ROUTES:
      return { ...state, routes: action.data };
    default:
      throw new Error();
  }
}

function scenarioQuestionReducer(state, action) {
  switch (action.type) {
    case SCENARIO_QUESTION_ACTIONS.SET_SCENARIO: {
      return { ...state, scenario: action.data };
    }
    case SCENARIO_QUESTION_ACTIONS.UPDATE_SCENARIO: {
      return { ...state, scenario: { ...state.scenario, ...action.data } };
    }
    case SCENARIO_QUESTION_ACTIONS.SET_OPENED_QUESTION: {
      return { ...state, openedQuestion: action.data };
    }
    case SCENARIO_QUESTION_ACTIONS.UPDATE_OPENED_QUESTION: {
      return { ...state, openedQuestion: { ...state.openedQuestion, ...action.data } };
    }
    case SCENARIO_QUESTION_ACTIONS.SET_QUESTIONS_LIST: {
      return { ...state, questionsList: action.data };
    }
    case SCENARIO_QUESTION_ACTIONS.UPDATE_QUESTIONS_LIST: {
      return {
        ...state,
        questionsList: updateQuestionBy(state.questionsList, action.data, action.data.questionId),
      };
    }
    case SCENARIO_QUESTION_ACTIONS.REMOVE_QUESTION_QUESTIONS_LIST: {
      return {
        ...state,
        questionsList: updateQuestionBy(
          state.questionsList,
          null,
          action.data.questionId,
          removeQuestionFn
        ),
      };
    }
    case SCENARIO_QUESTION_ACTIONS.SET_QUESTION_IMPORTATION_ERROR: {
      const errorPath = get(action.data, 'response.data.errors[0].details.instancePath', '');
      return {
        ...state,
        error: {
          questionLine: getErrorQuestionLine(errorPath),
          path: errorPath,
          message: get(action.data, 'response.data.message', ''),
        },
      };
    }
    case SCENARIO_QUESTION_ACTIONS.CLEAR_QUESTION_IMPORTATION_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      throw new Error();
  }
}

function eventReducer(state, action) {
  switch (action.type) {
    case EVENT_ACTION.SET_EVENT: {
      return { ...state, event: action.data };
    }
    case EVENT_ACTION.SET_TEAM: {
      return { ...state, team: action.data };
    }
    case EVENT_ACTION.SET_PARTICIPANT: {
      return { ...state, participant: action.data };
    }    
    default:
      throw new Error();
  }
}

export function useAppReducer() {
  return useReducer(appReducer, appDefaultState);
}

export function useScenarioQuestionReducer() {
  return useReducer(scenarioQuestionReducer, scenarioQuestionDefaultState);
}

export function useEventReducer() {
  return useReducer(eventReducer, eventDefaultState);
}
