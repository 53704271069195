import { Button, Form, RegularText } from 'boss-ui';
import { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Flex, Box } from 'reflexbox';
import Api from '../../api';
import { EVENT_DELIVERY, NUMBERS } from '../../libs/constants';
import { useEventContext } from '../../libs/context-lib';
import { exportFile } from '../../libs/csv-lib';
import { onError } from '../../libs/error-lib';

const API = new Api();
const SCORING_CSV_HEADERS = ['username', 'email', 'total', 'base', 'bonus', 'penalty'];
const TEAMS_CSV_HEADERS = ['username', 'email', 'team'];
const NO_USERS_IN_THE_EVENT_MESSAGE = 'There are no users registered in this event';
const NO_USERS_WITH_SCORE = 'There are no users with score yet';

const formatUserScoringData = ({ user, scenarios = [], ...score }) => {
  const scenariosFields = scenarios
    .map((scenario) => [scenario.total, scenario.base, scenario.bonus, scenario.penalty])
    .flat();
  return {
    fields: [user.username, user.email, score.total, score.base, score.bonus, score.penalty].concat(
      scenariosFields
    ),
  };
};

const getScoringHeaders = ([{ scenarios = [] }]) => {
  const scenariosHeaders = scenarios
    .map((scenario) => [
      `${scenario.title} - total`,
      `${scenario.title} - base`,
      `${scenario.title} - bonus`,
      `${scenario.title} - penalty`,
    ])
    .flat();
  return SCORING_CSV_HEADERS.concat(scenariosHeaders);
};

const formatUsersTeamData = (participant) => ({
  fields: [participant.DisplayUsername, participant.Email, participant.Team],
});

const Exports = ({ loading }) => {
  const { state } = useEventContext();
  const [isExporting, setIsExporting] = useState(false);
  const route = useRouteMatch('/events/:eventId');
  const eventId = route ? route.params.eventId : '';

  const onExportScoringCsvClick = useCallback(async () => {
    setIsExporting(true);
    let usersData = [];
    try {
      let response = await API.get('events', `/admin/events/${eventId}/users-dashboard`, {
        queryStringParameters: {
          pageSize: NUMBERS.ONE_HUNDRED,
        },
      });
      if (!response.users || response.users.length === 0) {
        onError(NO_USERS_WITH_SCORE);
        return;
      }
      usersData = usersData.concat(response.users.map(formatUserScoringData));
      while (response.pagination.more) {
        response = await API.get('events', `/admin/events/${eventId}/users-dashboard`, {
          queryStringParameters: {
            pageSize: NUMBERS.ONE_HUNDRED,
            ...response.pagination.LastEvaluatedKey,
          },
        });
        usersData = usersData.concat(response.users.map(formatUserScoringData));
      }
      const headers = getScoringHeaders(response.users);
      exportFile({ header: headers, data: usersData }, `${state.event.name}-scoring.csv`);
    } catch (e) {
      onError(e);
    } finally {
      setIsExporting(false);
    }
  }, [eventId, state.event.name]);

  const onExportTeamsCsvClick = useCallback(async () => {
    setIsExporting(true);
    try {
      const response = await API.get('events', `/events/${eventId}/export`);
      if (!response.participants || response.participants.length === 0) {
        onError(NO_USERS_IN_THE_EVENT_MESSAGE);
        return;
      }
      exportFile(
        { header: TEAMS_CSV_HEADERS, data: response.participants.map(formatUsersTeamData) },
        `${state.event.name}-teams.csv`
      );
    } catch (e) {
      onError(e);
    } finally {
      setIsExporting(false);
    }
  }, [eventId, state.event.name]);

  return (
    <Flex justifyContent="center" align="center" mb="5%" mt="60px">
      <Form noBorder refreshing={loading}>
        <Box px="60px" py="40px" width="924px">
          <RegularText mediumWeight>CSV exportation</RegularText>
          <Flex mt="20px" py="8px">
            <Box width="220px">
              <Button onClick={onExportScoringCsvClick} isLoading={isExporting}>
                Users Score CSV
              </Button>
            </Box>
            <Flex ml="22px">
              <RegularText fontSize="11px">
                A list of users with their event score detailed
              </RegularText>
            </Flex>
          </Flex>
          <Flex py="8px">
            <Box width="220px">
              <Button
                isLoading={isExporting}
                disabled={state.event.delivery === EVENT_DELIVERY.ON_DEMAND}
                onClick={onExportTeamsCsvClick}
              >
                Users Team CSV
              </Button>
            </Box>
            <Flex ml="22px">
              <RegularText fontSize="11px">A list of users with their event team name</RegularText>
            </Flex>
          </Flex>
        </Box>
      </Form>
    </Flex>
  );
};

export default Exports;
