import { useEffect, useState } from 'react';
import Api from '../../api';

const API = new Api();

export const useGetSimpleBinPackFeatureFlag = () => {
  // TODO: The REST API for feature flags is currently hardcoded to return the feature flag for ENABLE_SIMPLE_BIN_PACK
  //  in the future, we should make the endpoints more generic to allow for any feature flag to be retrieved
  const [loading, setLoading] = useState(true);
  const [flagConfig, setFlagConfig] = useState(null);
  useEffect(() => {
    async function fetchData() {
      const result = await API.get('events', `/admin/feature-flag`);
      console.log(result);
      setFlagConfig(result);
      setLoading(false);
    }

    fetchData().then();
  }, []);
  return { loading, flagConfig };
};

export const useListAllEvents = () => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    async function fetchAllEvents() {
      let lastEvaluatedKey = {};
      const retrievedEvents = [];
      let paginationHasMore = true;
      while (paginationHasMore) {
        const result = await API.get('events', `/admin/events`, {
          queryStringParameters: {
            pageSize: 100,
            ...lastEvaluatedKey
          }
        });
        retrievedEvents.push(...result.events);
        paginationHasMore = result?.pagination?.more || false;
        lastEvaluatedKey = result.pagination.LastEvaluatedKey;
      }
      return retrievedEvents;
    }

    fetchAllEvents().then(eventsFromApi => {
      setEvents(eventsFromApi);
      setLoading(false);
    });
  }, []);
  return { loading, events };
};

// TODO: if we expose more feature flags in the future, we should make this function more generic
export const submitFeatureFlagChanges = async (payload) => {
  return await API.post('events', `/admin/feature-flag`, {
    body: payload
  });
};