import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { Breadcrumb } from 'boss-ui';
import { Flex } from 'reflexbox';
import SearchParticipants from '../../components/Events/SearchParticipants';
import ParticipantDetails from '../ParticipantDetails';
import { useEventContext } from '../../libs/context-lib';
import { useBreadcrumb } from '../../libs/hooks-lib';
import { MainContainer, ParticipantDetailsContainer, SecondaryContainer } from './styles';

export default function Participants() {
  const { state } = useEventContext();
  const [breadcrumbs, setBreadcrumbs] = useBreadcrumb();
  const location = useLocation();
  const match = useRouteMatch(location.pathname);
  const routeParticipant = useRouteMatch('/events/:eventId/participants/:userId');
  const eventId = routeParticipant ? routeParticipant.params.eventId : '';

  useEffect(() => {
    if (routeParticipant?.params?.userId) {
      setBreadcrumbs([
        {
          path: `/events/${eventId}/participants`,
          name: 'Participants',
        },
        {
          path: `/events/${eventId}/participants/${state.participant.userId}`,
          name: state.participant.name,
        }
      ], match);
    }
  }, [location, state.participant]);

  return (
    <Switch>
      <Route path="/events/:eventId/participants" exact>
        <MainContainer>
          <SecondaryContainer>
            <SearchParticipants />
          </SecondaryContainer>
        </MainContainer>
      </Route>
      <Route path="/events/:eventId/participants/:userId" exact>
        <MainContainer>
          <ParticipantDetailsContainer>
            <Flex mb="20px">
              <Breadcrumb crumbs={breadcrumbs} />
            </Flex>
            <ParticipantDetails />
          </ParticipantDetailsContainer>
        </MainContainer>
      </Route>
    </Switch>
  );
}
