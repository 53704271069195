import React, { useEffect, useState } from 'react';
import { Flex } from 'reflexbox';
import omit from 'lodash.omit';
import { useTheme } from 'styled-components';
import Api from '../../api';
import { ScenarioContext, useAppContext } from '../../libs/context-lib';
import { DISPLAY_QUESTIONS_MODE, NO_DATA_IN_LIST_MESSAGE } from '../../libs/constants';
import { ScenarioDisplaySelector, QuestionsGrid, QuestionsList } from '../../components';
import {
  QuestionCardSkeleton,
  QuestionInlineSkeleton,
  ExportIcon,
  Table,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  TableBody,
} from 'boss-ui';
import { exportQuestionsFile } from '../../components/Questions/utils';
import { onError } from '../../libs/error-lib';
import { useRouteMatch } from 'react-router-dom';
import { useScenarioQuestionReducer } from '../../libs/reducer-lib';
import { SCENARIO_QUESTION_ACTIONS, APP_ACTIONS } from '../../libs/reducerAction-lib';

const API = new Api();

export default function ScenarioQuestionsEdition() {
  const appDispatch = useAppContext().dispatch;
  const [state, dispatch] = useScenarioQuestionReducer();
  const theme = useTheme();
  const [focusedQuestionId, setFocusedQuestionId] = useState(null);
  const [removingQuestionId, setRemovingQuestionId] = useState(null);
  const [displayMode, setDisplayMode] = useState(DISPLAY_QUESTIONS_MODE.LIST);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const routeMatchEventScenario = useRouteMatch('/events/:eventId/scenarios/:scenarioId');
  const routeMatchScenarios = useRouteMatch('/scenarios/:scenarioId');
  const routeMatch = routeMatchScenarios ? routeMatchScenarios : routeMatchEventScenario;

  useEffect(() => {
    const getScenarioQuestionsById = async (scenarioId) => {
      setLoadingQuestions(true);
      try {
        let url = `/admin/scenarios/${scenarioId}`;
        if (routeMatchEventScenario) {
          url = `/admin/events/${routeMatchEventScenario.params.eventId}/scenarios/${scenarioId}`;
        }
        const rq = await API.get('events', url);
        dispatch({
          type: SCENARIO_QUESTION_ACTIONS.SET_QUESTIONS_LIST,
          data: rq.scenario.questions,
        });
        dispatch({
          type: SCENARIO_QUESTION_ACTIONS.UPDATE_SCENARIO,
          data: omit(rq.scenario, ['questions', 'requiredResources']),
        });
        if (routeMatchScenarios) {
          appDispatch({
            type: APP_ACTIONS.SET_ROUTES,
            data: [
              {
                path: `/scenarios`,
                name: 'Scenarios',
              },
              {
                path: `/scenarios/${routeMatch.params.scenarioId}`,
                name: rq.scenario.title,
              },
            ],
          });
        }
        setLoadingQuestions(false);
      } catch (e) {
        onError(e);
      }
    };

    getScenarioQuestionsById(routeMatch.params.scenarioId);
    return () => API.abortCurrentRequest();
  }, [routeMatch.params.scenarioId, dispatch, appDispatch]);

  const onQuestionCardClick = (questionClicked) => {
    dispatch({
      type: SCENARIO_QUESTION_ACTIONS.SET_OPENED_QUESTION,
      data: questionClicked,
    });
    setFocusedQuestionId(questionClicked.question_id);
  };

  const onQuestionCardClose = () => {
    dispatch({ type: SCENARIO_QUESTION_ACTIONS.SET_OPENED_QUESTION, data: null });
    setFocusedQuestionId(null);
  };

  const onRemoveQuestion = async (questionId) => {
    setRemovingQuestionId(questionId);
    try {
      let url = `/admin/scenarios/${state.scenario.scenarioId}/questions/${questionId}/`;
      if (routeMatchEventScenario) {
        url = `/admin/events/${routeMatchEventScenario.params.eventId}/scenarios/${state.scenario.scenarioId}/questions/${questionId}/`;
      }
      await API.del('events', url);
      dispatch({
        type: SCENARIO_QUESTION_ACTIONS.REMOVE_QUESTION_QUESTIONS_LIST,
        data: { questionId },
      });
    } catch (e) {
      onError(e);
    } finally {
      setRemovingQuestionId(false);
    }
  };

  return (
    <Flex
      width="-webkit-fill-available"
      minHeight="689px"
      backgroundColor="#1d1d1d"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      px="60px"
      py="40px"
    >
      <Flex alignItems="center" width={1} mb="12px">
        <Flex ml="auto" flexDirection="column">
          <Flex
            ml="auto"
            onClick={() => exportQuestionsFile(state.questionsList, state.scenario.title, false)}
            mb="28px"
            data-tip
            data-for="import-export"
          >
            <ExportIcon />
            <Tooltip id="import-export" borderColor={theme.color.border.active}>
              Export CSV
            </Tooltip>
          </Flex>
          <ScenarioDisplaySelector onSelected={(v) => setDisplayMode(v)} selected={displayMode} />
        </Flex>
      </Flex>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader ypadding="6px" xpadding="16px">
              Questions
            </TableHeader>
          </TableRow>
        </TableHead>
        {!loadingQuestions && !state.questionsList.length && (
          <TableBody>
            <TableRow>
              <TableHeader colspan={2} xpadding="5%" ypadding="20px" noWrap>
                {NO_DATA_IN_LIST_MESSAGE}
              </TableHeader>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <Flex mt="20px" justifyContent="center" width={1}>
        <ScenarioContext.Provider
          value={{
            state,
            dispatch,
          }}
        >
          {loadingQuestions ? (
            displayMode === DISPLAY_QUESTIONS_MODE.GRID ? (
              <QuestionCardSkeleton />
            ) : (
              <QuestionInlineSkeleton />
            )
          ) : displayMode === DISPLAY_QUESTIONS_MODE.GRID ? (
            <QuestionsGrid
              questions={state.questionsList}
              onQuestionCardClick={onQuestionCardClick}
              onQuestionCardClosed={onQuestionCardClose}
              focusedQuestionId={focusedQuestionId}
              questionNavigation={() => {}}
              removingQuestionId={removingQuestionId}
              onQuestionRemoved={onRemoveQuestion}
              eventId={routeMatch.params.eventId ? routeMatch.params.eventId : null}
            />
          ) : (
            <QuestionsList
              questions={state.questionsList}
              onQuestionCardClick={onQuestionCardClick}
              onQuestionCardClosed={onQuestionCardClose}
              focusedQuestionId={focusedQuestionId}
              questionNavigation={() => {}}
              removingQuestionId={removingQuestionId}
              onQuestionRemoved={onRemoveQuestion}
              eventId={routeMatch.params.eventId ? routeMatch.params.eventId : null}
            />
          )}
        </ScenarioContext.Provider>
      </Flex>
    </Flex>
  );
}
